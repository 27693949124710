import React, { useState, useEffect } from 'react';
import { FaUser, FaArrowLeft, FaEllipsisV, FaFilter, FaPlus } from 'react-icons/fa';
import { BASE_URL } from 'utils/constant';
import DeliveryCard from './DeliveryCard';

const VendorProfile = ({ vendor, onBack }) => {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    fetchVendorDeliveries();
  }, []);

  const fetchVendorDeliveries = async () => {
    try {
      const accessToken = localStorage.getItem('access');
      const site = localStorage.getItem('site');
      
      const response = await fetch(`${BASE_URL}/inventory/list-vendor-deliveries/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken.trim()}`,
        },
        body: JSON.stringify({
          sites: site,
          vendor: vendor.id,
        }),
      });

      if (!response.ok) throw new Error('Failed to fetch deliveries');

      const result = await response.json();
      if (result.status && Array.isArray(result.data)) {
        setDeliveries(result.data);
      }
    } catch (error) {
      console.error('Error fetching vendor deliveries:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculatePayments = () => {
    return deliveries.reduce((acc, delivery) => {
      acc.totalPayment += delivery.paid_amount || 0;
      acc.pendingPayment += (delivery.total_price - delivery.paid_amount) || 0;
      acc.refundedAmount += delivery.refunded_amount || 0;
      return acc;
    }, {
      totalPayment: 0,
      pendingPayment: 0,
      refundedAmount: 0,
    });
  };

  const payments = calculatePayments();

  const paginatedDeliveries = deliveries.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <div className="bg-white p-4 flex items-center justify-between shadow-sm">
        <div className="flex items-center gap-2">
          <button onClick={onBack} className="p-2 hover:bg-gray-100 rounded-full">
            <FaArrowLeft size={20} />
          </button>
          <h1 className="text-lg font-semibold">Vendor/Shop's Profile</h1>
        </div>
        <div className="flex items-center gap-2">
          <button className="p-2 hover:bg-gray-100 rounded-full">
            <FaEllipsisV size={20} />
          </button>
        </div>
      </div>

      {/* Vendor Info Card */}
      <div className="m-4 bg-white rounded-lg shadow-sm">
        <div className="p-4 border-b">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-purple-100 rounded-full">
              <FaUser size={24} className="text-purple-700" />
            </div>
            <div>
              <h2 className="font-semibold">{vendor.name}</h2>
              <span className="text-sm text-gray-600 bg-gray-100 px-2 py-0.5 rounded-full">
                {vendor.vendor_type}
              </span>
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              <span className="font-medium">Contact No: </span>
              +91-{vendor.contact_no}
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Address: </span>
              {vendor.address || 'no data'}
            </p>
          </div>
        </div>

        {/* Payment Summary */}
        <div className="p-4 grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-600">Total Payment</p>
            <p className="font-semibold">₹{payments.totalPayment.toFixed(2)}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Pending Payment</p>
            <p className="font-semibold text-red-500">₹{payments.pendingPayment.toFixed(2)}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Advance Payment</p>
            <p className="font-semibold">₹0</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Refunded Amount</p>
            <p className="font-semibold">₹{payments.refundedAmount.toFixed(2)}</p>
          </div>
        </div>
      </div>

      {/* Deliveries Section */}
      <div className="m-4">
        <div className="flex items-center justify-between mb-4">
          <p className="font-medium">{deliveries.length} Vendors</p>
          <div className="flex items-center gap-2">
            <button className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-md">
              <span>Sorted by A-Z</span>
              <FaFilter size={16} />
            </button>
            <button className="flex items-center gap-2 px-4 py-1.5 bg-purple-700 text-white rounded-md">
              <FaPlus size={16} />
              <span>Purchase</span>
            </button>
          </div>
        </div>

        {/* Delivery Cards */}
        <div className="space-y-4">
          {loading ? (
            <p>Loading deliveries...</p>
          ) : (
            paginatedDeliveries.map((delivery) => (
              <DeliveryCard
                key={delivery.id}
                delivery={delivery}
                onClick={() => console.log('Delivery clicked:', delivery)}
              />
            ))
          )}
        </div>

        {/* Pagination */}
        <div className="flex justify-center gap-2 mt-4">
          {Array.from({ length: Math.ceil(deliveries.length / itemsPerPage) }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === index + 1
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100'
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;