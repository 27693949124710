import React, { useState } from "react";
import { X, Calendar, Plus, Upload } from "lucide-react";
import { BASE_URL } from "utils/constant";

const StockUpdateForm = ({
  onClose,
  materialId,
  currentStock,
  onStockUpdate,
  materialDetails = {
    subCategory: "Cement Grade 50",
    specification: "Ambuja Cement",
  },
}) => {
  const [formData, setFormData] = useState({
    update_date: new Date().toISOString().split("T")[0],
    entered_quantity: "",
    comment: "",
    material: materialId,
  });

  const [stockAction, setStockAction] = useState("added"); // 'added' or 'used'

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = localStorage.getItem("access");
      const finalQuantity =
        stockAction === "added"
          ? Number(formData.entered_quantity)
          : -Number(formData.entered_quantity);

      const response = await fetch(`${BASE_URL}/inventory/create-stock/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken.trim()}`,
        },
        body: JSON.stringify({
          ...formData,
          entered_quantity: finalQuantity,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        onStockUpdate({
          ...data,
          entered_quantity: finalQuantity,
          created_on: new Date().toISOString(),
        });
        onClose();
      } else {
        console.error("Failed to update stock");
      }
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  const validateQuantity = (quantity) => {
    if (stockAction === "used" && Number(quantity) > currentStock) {
      return false;
    }
    return true;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return d.toLocaleDateString("en-US", options);
  };

  return (
    <div className="bg-gray-100 shadow-lg mt-4 rounded-lg w-[65%] h-[80vh] mx-auto overflow-y-auto">
      <div className="relative mb-2">
        <div className="h-10 bg-white flex items-center mt-3 justify-center relative">
          <h2 className="text-lg font-semibold">Stock Update</h2>
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <X size={18} />
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="px-6 pb-4 space-y-4">
        <div>
          <label className="block text-sm text-gray-600 mb-1">Cement</label>
          <div className="bg-gray-50 p-3 rounded-md space-y-2">
            <div className="grid grid-cols-2 gap-2">
              <div className="text-sm text-gray-600">
                <span className="font-medium">Sub Category:</span>{" "}
                {materialDetails.subCategory}
              </div>
              <div className="text-sm text-gray-600">
                <span className="font-medium">Specification:</span>{" "}
                {materialDetails.specification}
              </div>
            </div>
            <div className="text-sm text-gray-600">
              <span className="font-medium">In-Stock Amount:</span>{" "}
              {currentStock} Bags
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">
            Update Date
          </label>
          <div className="bg-gray-50 p-3 rounded-md flex justify-between items-center">
            <div>
              <div className="text-sm font-medium">
                {formatDate(formData.update_date)}
              </div>
            </div>
            <button
              type="button"
              className="flex items-center text-gray-600 text-sm"
            >
              Change
              <Calendar size={16} className="ml-1" />
            </button>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex space-x-2">
            <button
              type="button"
              className={`inline-flex items-center px-3 py-1 rounded-md text-sm ${
                stockAction === "added"
                  ? "bg-green-500 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              onClick={() => setStockAction("added")}
            >
              Added
            </button>
            <button
              type="button"
              className={`inline-flex items-center px-3 py-1 rounded-md text-sm ${
                stockAction === "used"
                  ? "bg-red-500 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              onClick={() => setStockAction("used")}
            >
              Used
            </button>
          </div>
          <div className="text-xs text-gray-500">
            {stockAction === "added"
              ? "Add stock to inventory"
              : "Remove stock from inventory"}
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center">
                <Plus size={16} className="text-gray-400" />
              </div>
              <input
                type="number"
                name="entered_quantity"
                value={formData.entered_quantity}
                onChange={(e) => {
                  if (validateQuantity(e.target.value)) {
                    handleInputChange(e);
                  }
                }}
                placeholder="Enter Amount"
                className={`w-full pl-8 p-2 bg-gray-50 rounded-md ${
                  stockAction === "used" &&
                  Number(formData.entered_quantity) > currentStock
                    ? "border-red-500"
                    : ""
                }`}
              />
            </div>
            {stockAction === "used" &&
              Number(formData.entered_quantity) > currentStock && (
                <p className="text-red-500 text-xs mt-1">
                  Cannot use more than available stock
                </p>
              )}
          </div>
          <div>
            <input
              type="text"
              value="Bags"
              disabled
              className="w-full p-2 bg-gray-50 rounded-md"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">
            Remarks/ Comments (Optional)
          </label>
          <textarea
            name="comment"
            value={formData.comment}
            onChange={handleInputChange}
            placeholder="Type"
            rows="2"
            className="w-full p-2 bg-gray-50 rounded-md resize-none"
          />
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">
            Photos (Optional)
          </label>
          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              className="flex items-center justify-center p-2 bg-gray-50 rounded-md text-purple-600 space-x-2"
            >
              <Upload size={16} />
              <span className="text-sm">Take Photos</span>
            </button>
            <button
              type="button"
              className="flex items-center justify-center p-2 bg-gray-50 rounded-md text-purple-600 space-x-2"
            >
              <Upload size={16} />
              <span className="text-sm">Upload Photos</span>
            </button>
          </div>
        </div>

        <button
          type="submit"
          disabled={
            !formData.entered_quantity ||
            (stockAction === "used" &&
              Number(formData.entered_quantity) > currentStock)
          }
          className="w-full bg-purple-700 text-white py-2 rounded-md hover:bg-purple-800 transition duration-300 flex items-center justify-center mt-4 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default StockUpdateForm;
