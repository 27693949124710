import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import base_background from "../assets/Base (1).png";
import main_logo from "../assets/SM_Full_White-100 1 (1).png";
import arrow_forward from "../assets/arrow_forward.png";
import arrow from "../assets/Vector (1).png";
import passwordIcon from "../assets/visibility_lock (1).png";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";
import { useAlert } from "react-alert";
import { BASE_URL } from "../utils/constant";
export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    password: yup
      .string()
      .min(8, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Passwords must match"),
  });

  async function signUp(email, password) {
    // setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email.toLowerCase(),
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${BASE_URL}/auth/signup/`, requestOptions);
      const result = await response.json();
      // console.warn(result);

      if (result?.status) {
        navigate("/otp-screen");
      } else {
        if (result?.message?.includes("email already exists")) {
        //   Alert.alert("Account exists!!", result.message, [
        //     { text: "Cancel", onPress: () => console.log("Cancel Pressed") },
        //     {
        //       text: "Go to Login",
        //       onPress: () =>
        //         navigation.navigate("LoginScreen", {
        //           email: email.toLowerCase(),
        //         }),
        //     },
        //   ]);
            alert.error("Account exists!!");
        } else {
        //   Alert.alert("Error", result.message, [
        //     { text: "OK", onPress: () => console.log("OK Pressed") },
        //   ]);
           alert.error("Error",result.message);
        }
      }
    } catch (error) {
    //   Toast.error(error?.message);
         alert.error(error?.message);
    } finally {
    //   setLoading(false);
    console.log("sign up");
    }
  }
  const handleSignup = (email, password) => {
    console.log("Signup");
    console.log(email);
    console.log(password);
    
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
    //   handleSignup(values.email, values.password);
    signUp(values.email,values.password);
    },
  });
  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={base_background}
          alt=""
          style={{
            height: "140%",
            width: "140%",
            objectFit: "cover",
            position: "relative",
            top: "-50px",
            left: "-50px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={arrow}
          alt=""
          style={{
            height: "115%",
            width: "115%",
            top: "0px",
            left: "-70px",
            objectFit: "contain",
            position: "relative",
            opacity: "0.5",
          }}
        />
      </div>
      <div className="flex flex-col align-middle items-center justify-center w-[50%]">
        <span className="py-5 font-extrabold text-neutral-900 text-2xl">
          <img src={main_logo} alt="" width="90%" />
        </span>
        <span
          className="font-extrabold text-neutral-900 font-medium mt-[-2]"
          style={{
            fontSize: "28px",
            color: "#ffffff",
            fontWeight: 400,
            opacity: 0.8,
            letterSpacing: "0.1em",
            textAlign: "center",
          }}
        >
          Build Smarter,Faster,Better
        </span>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          top: "70px",
          /* margin-top: auto; */
          right: "50px",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col align-middle justify-between px-10  pb-5"
          style={{ width: "80%" }}
        >
          <div className="flex flex-col">
            <span
              className="py-5 font-extrabold text-purple-800 text-2xl"
              style={{
                color: "#3B0069",
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Create an Account
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: 200,
                opacity: 0.8,
                marginTop: "-12px",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-purple-800"
                style={{ textDecoration: "underline" }}
              >
                Sign in
              </Link>
            </span>
            <span
              className="py-2 "
              style={{
                fontSize: "24px",
                color: "#4B5563",
                fontWeight: 700,
                marginTop: "10px",
              }}
            >
              Sign Up with Email
            </span>

            <span className="pt-3 text-neutral-800 font-medium">Email ID</span>
            <input
              type="email"
              name="email"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your email here"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              style={{ width: "80%" }}
            />
            {formik.touched.email && formik.errors.email && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.email}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">Password</span>
            <div
              style={{
                position: "relative",
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                className="bg-gray-200 rounded p-2 px-8"
                placeholder="Enter password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                style={{ width: "100%" }}
              />
              <img
                src={passwordIcon}
                onClick={() => setPasswordVisible(!passwordVisible)}
                alt="show password"
                style={{
                  position: "absolute",
                  right: 18,
                  top: 14,
                  cursor: "pointer",
                }}
                className="w-4 h-3"
              />
            </div>

            {formik.touched.password && formik.errors.password && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.password}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">
              Confirm Password
            </span>
            <div
              style={{
                position: "relative",
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                type={passwordVisible ? "text" : "password"}
                name="passwordConfirmation"
                className="bg-gray-200 rounded p-2 px-8"
                placeholder="Enter password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                style={{ width: "100%" }}
              />
              <img
                src={passwordIcon}
                onClick={() => setPasswordVisible(!passwordVisible)}
                alt="show password"
                style={{
                  position: "absolute",
                  right: 18,
                  top: 14,
                  cursor: "pointer",
                }}
                className="w-4 h-3"
              />
            </div>

            {formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation && (
                <span style={{ fontSize: 10, color: "red" }}>
                  {formik.errors.passwordConfirmation}
                </span>
              )}
          </div>

          <div className="w-full mt-14" style={{ width: "80%" }}>
            <button
              type="submit"
              className={`py-3 px-8 rounded-lg w-full justify-center items-center flex flex-row gap-4 text-center ${
                formik.isValid ? "bg-purple-950" : "bg-gray-400"
              }`}
              disabled={!formik.isValid}
              onClick={() => {
                dispatch(authActions.login({ email: formik.values.email, password: formik.values.password }));
                console.log("Logged in");
            
              }}
            >
              <span className="font-bold text-white text-lg">Get OTP</span>

              {/* Replace the icon component here */}

              <img
                src={arrow_forward}
                alt=""
                className="w-6 h-6"
                style={{ background: "#58267F", borderRadius: "50%" }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
