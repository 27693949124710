import React from 'react';
import { X } from 'lucide-react';
import { BASE_URL } from 'utils/constant';

const CreateSubtaskModal = ({ isOpen, onClose, taskId, onSuccess }) => {
  const [formData, setFormData] = React.useState({
    subtask_name: '',
    subtask_details: '',
    assign_to: '',
    start_date: '',
    end_date: ''
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const accessToken = localStorage.getItem('access');
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          task_id: taskId,
          ...formData
        }),
      };

      const response = await fetch(`${BASE_URL}/task/create-subtasks/`, requestOptions);
      const data = await response.json();

      if (data.status) {
        // Reset form and close modal
        setFormData({
          subtask_name: '',
          subtask_details: '',
          assign_to: '',
          start_date: '',
          end_date: ''
        });
        onSuccess?.(); // Callback to refresh subtasks list
        onClose();
      } else {
        setError(data.message || 'Failed to create subtask');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while creating the subtask');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Create Subtask</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Subtask Name
            </label>
            <input
              type="text"
              placeholder="Enter subtask name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-600"
              value={formData.subtask_name}
              onChange={(e) =>
                setFormData({ ...formData, subtask_name: e.target.value })
              }
              required
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Subtask Details
            </label>
            <textarea
              placeholder="Enter subtask details"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-600"
              value={formData.subtask_details}
              onChange={(e) =>
                setFormData({ ...formData, subtask_details: e.target.value })
              }
              required
              rows={3}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Assign To
            </label>
            <input
              type="text"
              placeholder="Enter assignee name"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-600"
              value={formData.assign_to}
              onChange={(e) =>
                setFormData({ ...formData, assign_to: e.target.value })
              }
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-600"
                value={formData.start_date}
                onChange={(e) =>
                  setFormData({ ...formData, start_date: e.target.value })
                }
                required
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-600"
                value={formData.end_date}
                onChange={(e) =>
                  setFormData({ ...formData, end_date: e.target.value })
                }
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-[#3B0069] text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 flex items-center justify-center gap-2"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating...' : 'Create'}
            {!isSubmitting && (
              <svg
                className="w-5 h-5"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateSubtaskModal;