import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import SiteCommunications from "./SiteCommunications";
import { BASE_URL } from "utils/constant";
const Home = () => {
  const [cashFlowData, setCashFlowData] = useState([
    { name: "Jan", Amount: 400000 },
    { name: "Feb", Amount: 300000 },
    { name: "Mar", Amount: 500000 },
    { name: "Apr", Amount: 400000 },
    { name: "May", Amount: 600000 },
    { name: "Jun", Amount: 400000 },
  ]);

  const [labourData, setLabourData] = useState([
    { name: "Sat", Workers: 250, Contractual: 450 },
    { name: "Mon", Workers: 150, Contractual: 350 },
    { name: "Tue", Workers: 300, Contractual: 400 },
    { name: "Wed", Workers: 200, Contractual: 500 },
    { name: "Thu", Workers: 250, Contractual: 150 },
    { name: "Fri", Workers: 280, Contractual: 400 },
  ]);

  const [materialsData, setMaterialsData] = useState([
    { name: "Bricks", value: 50000, unit: "pc" },
    { name: "Cements", value: 150, unit: "Bags" },
    { name: "Paints", value: 70, unit: "Liter" },
    { name: "Blocks", value: 20000, unit: "pc" },
  ]);
  const [chartData, setChartData] = useState([]);

  const MAX_ITEMS = 3; // Max number of items to display individually in the chart
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#0088fe",
    "#ffbb28",
  ];
  // Transform materialsData for the chart

  // now call apis to set the data
  useEffect(() => {
    const fetchCashFlowData = async () => {
      try {
        const accessToken = localStorage.getItem("access");
        const response = await fetch(
          `${BASE_URL}/finance/get-all-transactions-of-a-site/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ sites: localStorage.getItem("site") }),
          }
        );
        const data = await response.json();

        if (data.status) {
          // Group transactions by month and calculate total amount
          const groupedData = data.data.reduce((acc, transaction) => {
            const month = new Date(
              transaction.transaction_date || transaction.date
            ).toLocaleString("default", { month: "short" }); // Get month abbreviation
            if (!acc[month]) {
              acc[month] = { name: month, Amount: 0 };
            }
            acc[month].Amount +=
              transaction.amount || transaction.total_price || 0;
            return acc;
          }, {});

          // Convert grouped data to an array and sort by month
          const sortedData = Object.values(groupedData).sort(
            (a, b) =>
              new Date(`1 ${a.name} 2024`) - new Date(`1 ${b.name} 2024`) // Sort by month
          );

          setCashFlowData(sortedData);
        }
      } catch (error) {
        console.error("Error fetching cash flow data:", error);
      }
    };

    const fetchLabourData = async () => {
      try {
        const accessToken = localStorage.getItem("access");
        const site = localStorage.getItem("site");
        const today = new Date();
        const lastSevenDays = Array.from({ length: 7 }, (_, i) => {
          const date = new Date();
          date.setDate(today.getDate() - i);
          return date.toISOString().split("T")[0];
        });

        const formattedData = [];

        for (const date of lastSevenDays) {
          const response = await fetch(
            `${BASE_URL}/worker/all-workers-attendance-history/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                sites: site,
                worker_type: "Worker", // First request for Workers
                attendance: "Present",
                attendance_date: date,
              }),
            }
          );

          const workerResponse = await response.json();

          const crewResponse = await fetch(
            `${BASE_URL}/worker/all-workers-attendance-history/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify({
                sites: site,
                worker_type: "Crew", // Second request for Crew
                attendance: "Present",
                attendance_date: date,
              }),
            }
          ).then((res) => res.json());

          if (workerResponse.status && crewResponse.status) {
            const dayName = new Date(date).toLocaleString("en-US", {
              weekday: "short",
            });
            formattedData.push({
              name: dayName,
              Workers: workerResponse.total_present || 0,
              Contractual: crewResponse.total_present || 0,
            });
          }
        }

        setLabourData(formattedData.reverse()); // Reverse to show data from oldest to newest
      } catch (error) {
        console.error("Error fetching labour data:", error);
      }
    };

    const fetchMaterialsData = async () => {
      try {
        const accessToken = localStorage.getItem("access");
        const response = await fetch(
          `${BASE_URL}/inventory/get-materials-of-a-site/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ sites: localStorage.getItem("site") }),
          }
        );

        const result = await response.json();

        if (result.status) {
          // Transform the data to match the desired format
          const transformedData = result.data.map((material) => ({
            name: material.material_name,
            value: material.current_stock,
            unit: material.uom.split("(")[0].trim(), // Extract the unit from "uom"
          }));
          const Data = transformedData
            .sort((a, b) => b.value - a.value) // Sort by value (descending)
            .slice(0, MAX_ITEMS); // Get the top N items

          // Calculate the "Others" category
          const otherItems = transformedData.slice(MAX_ITEMS);
          if (otherItems.length > 0) {
            const othersValue = otherItems.reduce(
              (sum, item) => sum + item.value,
              0
            );
            Data.push({ name: "Others", value: othersValue, unit: "" });
          }
          setChartData(Data);
        }
      } catch (error) {
        console.error("Error fetching materials data:", error);
      }
    };

    fetchCashFlowData();
    fetchLabourData();
    fetchMaterialsData();
  }, []);

  return (
    <div className="flex h-full">
      {/* Main Content */}
      <div className="flex-1 overflow-auto p-8 bg-gray-50 scrollbar-hide">
        <style jsx global>{`
          .scrollbar-hide {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }
        `}</style>
        <div className="max-w-6xl">
          <div className="flex gap-8">
            {/* Left Section */}
            <div className="w-[60%]">
              {/* Cash Flow Chart */}
              <div className="bg-white rounded-2xl shadow-sm p-4 mb-8">
                <h2 className="text-xl font-semibold text-gray-700 mb-6">
                  Cash flow Stats
                </h2>
                <div className="h-48">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={cashFlowData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                      <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: "#6B7280" }}
                      />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: "#6B7280" }}
                      />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="Amount"
                        stroke="#F97316"
                        strokeWidth={2}
                        dot={{ fill: "#F97316", strokeWidth: 2 }}
                        activeDot={{ r: 6 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Labour Attendance Chart */}
              <div className="bg-white rounded-2xl shadow-sm px-4 py-2">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="text-xl font-semibold text-gray-700">
                    Labour Attendance
                  </h2>
                  <button className="px-4 py-2 text-indigo-600 bg-indigo-50 rounded-lg text-sm hover:bg-indigo-100">
                    View Details
                  </button>
                </div>
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={labourData} barGap={8}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                      <XAxis
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: "#6B7280" }}
                      />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: "#6B7280" }}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar
                        name="Workers"
                        dataKey="Workers"
                        fill="#06B6D4"
                        radius={[4, 4, 0, 0]}
                      />
                      <Bar
                        name="Contractual"
                        dataKey="Contractual"
                        fill="#4052B6"
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="w-[40%]">
              {/* Stats Cards */}
              <div className="flex flex-col gap-2 mb-8">
                {[
                  {
                    title: "Total expenses",
                    icon: "💰",
                    color: "bg-pink-100",
                    value: "₹ 1000",
                  },
                  {
                    title: "Total received",
                    icon: "💸",
                    color: "bg-blue-100",
                    value: "₹ 1000",
                  },
                  {
                    title: "Total dues",
                    icon: "📄",
                    color: "bg-orange-100",
                    value: "₹ 1000",
                  },
                  {
                    title: "Remaining balance",
                    icon: "💵",
                    color: "bg-cyan-100",
                    value: "₹ 1000",
                  },
                ].map((stat, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-2xl shadow-sm p-2 flex items-center space-x-4"
                  >
                    <div
                      className={`w-12 h-12 rounded-xl ${stat.color} flex items-center justify-center`}
                    >
                      <span className="text-2xl">{stat.icon}</span>
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm">{stat.title}</p>
                      <p className="text-xl font-semibold">{stat.value}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Materials Chart */}
              <div className="bg-white rounded-2xl shadow-sm px-4 py-2 mt-[48px]">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold text-gray-700">
                    Materials
                  </h2>
                  <button className="px-2 py-2 text-indigo-600 bg-indigo-50 rounded-lg text-sm hover:bg-indigo-100">
                    View Details
                  </button>
                </div>
                <div className="flex flex-col items-center justify-start">
                  <div className="h-48 w-full">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={chartData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          innerRadius={50}
                          outerRadius={70}
                          startAngle={90}
                          endAngle={-270}
                        >
                          {chartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                 </div>
                  <div className="grid grid-cols-2 gap-x-8 gap-y-1">
                    {chartData.map((item, index) => (
                      <div key={index} className="flex items-center">
                        <div
                          className="w-3 h-3 rounded-full mr-2"
                          style={{
                            backgroundColor: COLORS[index % COLORS.length],
                          }}
                        />
                        <div className="text-sm">
                          <span className="text-gray-600">{item.name}</span>
                          <p className="font-semibold">
                            {item.value} {item.unit}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tasks List */}
          <div className="bg-white rounded-2xl shadow-sm p-6 mt-8">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-700">
                Ongoing Tasks
              </h2>
              <button className="px-4 py-2 text-indigo-600 bg-indigo-50 rounded-lg text-sm hover:bg-indigo-100">
                View Details
              </button>
            </div>
            <div className="space-y-4">
              {[1, 2, 3].map((_, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-4 border-b border-gray-100 last:border-0"
                >
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 bg-indigo-100 rounded-xl flex items-center justify-center">
                      <span className="text-indigo-600">📋</span>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-800">Task Name</h4>
                      <p className="text-sm text-gray-400">Details details</p>
                    </div>
                  </div>
                  <div className="text-sm text-gray-400">Details details</div>
                  <button className="px-2 py-1 text-indigo-600 bg-indigo-50 rounded-lg text-sm hover:bg-indigo-100">
                    View Details
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="w-[32%] flex-none overflow-hidden">
        <SiteCommunications />
      </div>
    </div>
  );
};

export default Home;
