import React, { useState, useEffect } from "react";
import Header from "./Header";
import RightSidebar from "./RightSidebar";
import LeftSidebar from "./LeftSidebar";
import MainContent from "./MainContent";
import { useNavigate } from "react-router-dom";
import NewSiteForm from "./NewSiteForm";
import AddNewMember from "./AddNewMember";
import { BASE_URL } from "../utils/constant";
import { X } from "lucide-react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 mt-10">
      <div
        className="absolute inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      />
      <div className="relative w-full mx-4" style={{
        overflowY: "auto",
      }}>
        {children}
      </div>
    </div>
  );
};

function Dashboard() {
  const [filteredSites, setFilteredSites] = useState([]);
  const [showNewSiteForm, setShowNewSiteForm] = useState(false);
  const [showInvitationsForm, setShowInvitationsForm] = useState(false);
  const [organization, setOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  async function getOwnerOrganization() {
    try {
      setIsLoading(true);
      setError(null);
      const accessToken = localStorage.getItem("access");
      
      if (!accessToken) {
        navigate("/login");
        return;
      }

      const response = await fetch(
        `${BASE_URL}/org/list-users-created-organizations/`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          navigate("/login");
          return;
        }
        throw new Error('Failed to fetch organization data');
      }

      const data = await response.json();
      
      if (data?.status) {
        if (data?.data.length === 0) {
          navigate("/add-organization");
          return;
        }
        setOrganization(data?.data[0]);
        localStorage.setItem("organization", JSON.stringify(data?.data[0]));
      } else {
        throw new Error(data?.message || 'Failed to load organization data');
      }
    } catch (error) {
      setError(error.message);
      console.error('Dashboard error:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("isOwner") === "true") {
      getOwnerOrganization();
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleFilterApply = (newFilteredSites) => {
    setFilteredSites(newFilteredSites);
  };

  const handleNewProjectClick = () => {
    setShowNewSiteForm(true);
  };

  const handleInvitationsClick = () => {
    setShowInvitationsForm(true);
  };

  const handleCloseNewSiteForm = () => {
    setShowNewSiteForm(false);
  };

  const handleCloseInvitationsForm = () => {
    setShowInvitationsForm(false);
  };

  const onOrganizationClick = (org) => {
    localStorage.setItem("organization", JSON.stringify(org));
    setOrganization(org);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-600 text-center">
          <p className="text-xl font-semibold">Error loading dashboard</p>
          <p className="mt-2">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="mt-4 px-4 py-2 bg-purple-900 text-white rounded hover:bg-purple-800"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-[#f9fafb]">
      <Header organization_name={organization?.organization_name} />
      <div className="flex">
        <div className="fixed left-0 top-16 bottom-0 w-[200px] overflow-y-auto bg-white shadow-md">
          <LeftSidebar
            onFilterApply={handleFilterApply}
            onNewProjectClick={handleNewProjectClick}
            onInvitationsClick={handleInvitationsClick}
            organizationId={organization?.id}
            showInvitationsForm={showInvitationsForm}
          />
        </div>
        <div className="flex-1 ml-[200px] mr-[32%] overflow-y-auto bg-[#f9fafb]">
          <MainContent
            filteredSites={filteredSites}
            onOrganizationClick={onOrganizationClick}
            organizationId={organization?.id}
          />
        </div>
        <div className="fixed right-0 top-16 bottom-0 w-[32%] overflow-y-auto bg-[#f9fafb] px-4 py-12">
          <RightSidebar />
        </div>

        <Modal isOpen={showNewSiteForm} onClose={handleCloseNewSiteForm}>
          <NewSiteForm onClose={handleCloseNewSiteForm} />
        </Modal>

        <Modal isOpen={showInvitationsForm} onClose={handleCloseInvitationsForm}>
          <AddNewMember
            onClose={handleCloseInvitationsForm}
            organizationId={organization?.id}
          />
        </Modal>
      </div>
    </div>
  );
}

export default Dashboard;
