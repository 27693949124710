import React, { useEffect, useState } from "react";
import { FaPlus, FaCircle } from "react-icons/fa";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import CreateTaskForm from "./CreateTaskForm";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "utils/constant";
import { FaScrewdriverWrench } from "react-icons/fa6";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);

const TaskHome = ({handleTaskClick}) => {
  const [currentTab, setCurrentTab] = useState("Ongoing");
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateTaskForm, setShowCreateTaskForm] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  async function getTasks() {
    try {
      setLoading(true);
      const siteId = localStorage.getItem("site");
      const accessToken = localStorage.getItem("access");
      const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
        body: JSON.stringify({
          sites: siteId,
        }),
        redirect: "follow",
      };

      if (accessToken) {
        const response = await fetch(
          `${BASE_URL}/task/get-all-tasks/`,
          requestOptions
        );
        const data = await response.json();

        if (data.status) {
          setTasks(data.data);
        } else {
          setError(data.message || "Failed to fetch tasks.");
        }
      } else {
        setError("Access token is missing.");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching tasks.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTasks();
  }, []);

  const handleViewDetails = (value) => {
    localStorage.setItem("task", value);
    handleTaskClick(value);
  };

  const tasksPerPage = 4;

  const handleAddTaskClick = () => {
    setShowCreateTaskForm(true);
  };
  
  const handleCloseCreateTaskForm = () => {
    setShowCreateTaskForm(false);
    getTasks();
  };

  const priorityMap = {
    1: "High",
    2: "Medium",
    3: "Low",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const filteredTasks = tasks.filter((task) => task.task_status === currentTab);
  const paginatedTasks = filteredTasks.slice(
    (currentPage - 1) * tasksPerPage,
    currentPage * tasksPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(filteredTasks.length / tasksPerPage)) {
      setCurrentPage(page);
    }
  };

  const taskFlowData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Ongoing",
        data: [65, 75, 85, 80, 90, 95, 88],
        borderColor: "#22C55E",
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        label: "Delayed",
        data: [55, 60, 70, 65, 75, 80, 72],
        borderColor: "#EF4444",
        borderDash: [5, 5],
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  };

  const taskFlowOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "start",
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 8,
          padding: 20,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#E5E7EB",
        },
        ticks: {
          stepSize: 10,
          font: {
            size: 11,
          },
        },
      },
    },
  };

  const taskSummary = {
    labels: ["Completed", "Ongoing", "Delayed"],
    datasets: [
      {
        data: [10, 5, 3],
        backgroundColor: ["#312E81", "#4F46E5", "#6366F1"],
        borderWidth: 0,
      },
    ],
  };

  const taskSummaryOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "70%",
  };

  return (
    <div className="bg-gray-100 w-full min-h-screen p-8">
      {loading && (
        <div className="flex justify-center items-center mb-4">
          <span className="text-gray-500">Loading tasks...</span>
        </div>
      )}

      {/* Charts Section */}
      <div className="flex justify-between mb-4">
        <div className="w-[48%]">
          <h2 className="text-indigo-900 text-lg font-semibold mb-4">Task Flow Stats</h2>
          <div className="bg-white p-4 rounded-lg shadow h-[250px]">
            <Line data={taskFlowData} options={taskFlowOptions} />
          </div>
        </div>
        <div className="w-[48%]">
          <h2 className="text-indigo-900 text-lg font-semibold mb-4">Task Summary</h2>
          <div className="bg-white p-4 rounded-lg shadow h-[250px]">
            <div className="flex items-center justify-between h-full">
              <div className="relative w-[150px] h-[150px]">
                <Doughnut data={taskSummary} options={taskSummaryOptions} />
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-center">
                    <div className="text-xs text-gray-500">Total Task</div>
                    <div className="text-lg font-semibold">18</div>
                  </div>
                </div>
              </div>
              <ul className="flex flex-col space-y-4 ml-8">
                <li className="flex items-center space-x-2">
                  <span className="w-3 h-3 bg-indigo-900 rounded-full"></span>
                  <span className="text-sm">Completed: 10</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-3 h-3 bg-indigo-600 rounded-full"></span>
                  <span className="text-sm">Ongoing: 5</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-3 h-3 bg-indigo-500 rounded-full"></span>
                  <span className="text-sm">Delayed: 3</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Rest of the component remains the same */}
      {/* Tasks List Section */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">All Tasks</h2>
        <div className="flex justify-between items-center mb-6">
          <div className="flex">
            {["Ongoing", "Completed", "Delayed"].map((tab) => (
              <button
                key={tab}
                className={`mr-8 pb-2 ${
                  currentTab === tab
                    ? "border-b-2 border-purple-600 text-purple-600"
                    : "text-gray-500"
                }`}
                onClick={() => {
                  setCurrentTab(tab);
                  setCurrentPage(1);
                }}
              >
                {tab}
              </button>
            ))}
          </div>
          <button
            className="bg-[#3B0069] text-white px-4 py-2 rounded-lg flex items-center"
            onClick={handleAddTaskClick}
          >
            <FaPlus className="mr-2" /> Add Task
          </button>
        </div>
      </div>

      {/* Tasks List */}
      {paginatedTasks.length > 0
        ? paginatedTasks.map((task) => (
            <div
              key={task.id}
              className="bg-white px-4 py-2 rounded-lg shadow mb-4 flex justify-between items-center"
            >
              <div className="flex items-center justify-between w-full">
                <div className="w-12 h-12 rounded-xl bg-[#E7EDFF] flex items-center justify-center mr-4">
                  <FaScrewdriverWrench fill="#396AFF" width="50px" />
                </div>
                <span className="font-medium">{task.task_name}</span>

                <div className="flex -space-x-2 mr-4">
                  {task.task_members.slice(0, 3).map((member) => (
                    <div
                      key={member.member_id}
                      className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white flex items-center justify-center text-xs font-medium text-gray-700"
                      title={`${member.first_name} ${member.last_name}`}
                    >
                      {member.first_name.charAt(0)}
                      {member.last_name.charAt(0)}
                    </div>
                  ))}
                  {task.task_members.length > 3 && (
                    <div
                      className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white flex items-center justify-center text-xs font-medium text-gray-700"
                      title={`${task.task_members.length - 3} more`}
                    >
                      +{task.task_members.length - 3}
                    </div>
                  )}
                </div>
                <span className="mr-4">{formatDate(task.end_date)}</span>
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-4 ${
                    task.priority === 1
                      ? "bg-red-100 text-red-800"
                      : task.priority === 2
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-green-100 text-green-800"
                  }`}
                >
                  <FaCircle className="mr-1 text-xs" />{" "}
                  {priorityMap[task.priority]}
                </span>
                <button
                  onClick={() => handleViewDetails(task)}
                  className="text-[#718EBF] rounded-2xl border-2 px-4 py-2 border-[#718EBF]"
                >
                  View Details
                </button>
              </div>
            </div>
          ))
        : !loading && (
            <div className="flex justify-center items-center">
              <span className="text-gray-500">No tasks found.</span>
            </div>
          )}

      {/* Pagination */}
      {filteredTasks.length > tasksPerPage && (
        <div className="flex justify-end mt-6">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="mx-1 px-3 py-1 rounded bg-gray-200"
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from(
            { length: Math.ceil(filteredTasks.length / tasksPerPage) },
            (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === i + 1
                    ? "bg-[#3B0069] text-white"
                    : "bg-gray-200"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="mx-1 px-3 py-1 rounded bg-gray-200"
            disabled={
              currentPage === Math.ceil(filteredTasks.length / tasksPerPage)
            }
          >
            Next
          </button>
        </div>
      )}

      {/* Create Task Modal */}
      {showCreateTaskForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CreateTaskForm onClose={handleCloseCreateTaskForm} />
        </div>
      )}
    </div>
  );
};

export default TaskHome;
