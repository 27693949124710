const Button = ({
  children,
  onClick,
  disabled,
  variant = "primary",
  className = "",
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded-md font-semibold text-sm ${
      variant === "primary"
        ? "bg-[#3B0069] text-white hover:bg-purple-700"
        : "bg-white text-gray-700 border border-gray-300 hover:bg-gray-50"
    } ${disabled ? "opacity-50 cursor-not-allowed" : ""} ${className}`}
  >
    {children}
  </button>
);

export default Button;
