import React, { useState } from "react";
import { Camera, Upload, Calendar, X } from "lucide-react";

const ReceivedForm = ({ onClose }) => {
  const [purpose, setPurpose] = useState("");
  const [paymentDate, setPaymentDate] = useState("now");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [remarks, setRemarks] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
  };

  return (
    <div className="flex p-4  min-h-screen w-full bg-gray-100 ">
      <div className=" w-full p-6 bg-white rounded-lg shadow-lg ">
        <div className="flex justify-between items-center mb-6">
          <button
            className="text-gray-600"
            onClick={() => {
              onClose();
            }}
          >
            <X size={24} />
          </button>
          <h2 className="text-xl font-semibold">Received</h2>
          <button className="text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Left Column */}
            <div className="space-y-4">
              {/* Purpose of Transaction */}
              <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Purpose of Transaction
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-md"
                    value={purpose}
                    onChange={(e) => setPurpose(e.target.value)}
                    placeholder="Type"
                  />
                </div>
              </div>

              {/* Payment Received on */}
              <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Payment Received on
                  </label>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className={`px-3 py-1 rounded-full text-sm ${
                        paymentDate === "now"
                          ? "bg-[#3B0069] text-white"
                          : "bg-gray-100"
                      }`}
                      onClick={() => setPaymentDate("now")}
                    >
                      Now
                    </button>
                    <button
                      type="button"
                      className={`px-3 py-1 rounded-full text-sm ${
                        paymentDate === "pick"
                          ? "bg-[#3B0069] text-white"
                          : "bg-gray-100"
                      }`}
                      onClick={() => setPaymentDate("pick")}
                    >
                      <span className="flex items-center">
                        Pick a older date
                        <Calendar className="ml-1 h-4 w-4" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              {/* Received Amount and Payment Method */}
              <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Received Amount
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-md"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="₹ Amount"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Payment Method
                  </label>
                  <div className="flex gap-2">
                    {["Cash", "UPI/Bank Transfer", "Cheque"].map((method) => (
                      <button
                        key={method}
                        type="button"
                        className={`px-3 py-1 rounded-full text-sm ${
                          paymentMethod === method.toLowerCase()
                            ? "bg-[#3B0069] text-white"
                            : "bg-gray-100"
                        }`}
                        onClick={() => setPaymentMethod(method.toLowerCase())}
                      >
                        {method}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="bg-gray-50 p-4 rounded-lg space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Remarks/ Comments (Optional)
                </label>
                <input
                  type="text"
                  className="w-full p-2 border rounded-md"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Type"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Payment Slip (Optional)
                </label>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className="flex-1 flex items-center justify-center gap-2 p-2 bg-purple-100 rounded-md text-purple-600"
                  >
                    <Camera size={20} />
                    Take Photos
                  </button>
                  <button
                    type="button"
                    className="flex-1 flex items-center justify-center gap-2 p-2 bg-purple-100 rounded-md text-purple-600"
                  >
                    <Upload size={20} />
                    Upload Photos
                  </button>
                </div>
                <div className="grid grid-cols-4 gap-2 mt-2">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="aspect-square bg-gray-100 rounded-md flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-[#3B0069] text-white py-2 rounded-md hover:bg-purple-700 transition duration-300"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReceivedForm;
