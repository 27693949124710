import React, { useState } from "react";
import { Users, User, ArrowLeft } from "lucide-react";
import NewWorkerForm from "./NewWorker";

const AddNewOptions = ({ onClose }) => {
  const [addNewContractor, setAddNewContractor] = useState(false);

  const handleNewClick = () => {
    setAddNewContractor(true);
  };
  const handleCloseClick = () => {
    setAddNewContractor(false);
  };
  if (addNewContractor) {
    return <NewWorkerForm onClose={handleCloseClick} />;
  }

  return (
    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center p-4">
      <div className="max-w-sm w-full bg-white rounded-lg shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-4 border-b">
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition duration-200 ease-in-out"
          >
            <ArrowLeft className="text-gray-600" size={20} />
          </button>
          <h2 className="text-lg font-semibold">Add New</h2>
          <div className="w-8"></div> {/* Spacer for alignment */}
        </div>
        <div className="p-4 space-y-3">
          <button className="w-full bg-purple-50 text-purple-700 p-3 rounded-lg flex items-center justify-between hover:bg-purple-100 transition duration-300">
            <div className="flex-1 text-left">
              <p onClick={handleNewClick} className="font-semibold text-sm">
                Add New Contractor
              </p>
              <p className="text-xs text-gray-500 mt-0.5">
                Crew are group of workers working under a contract/contractor
              </p>
            </div>
            <div className="bg-purple-100 p-1.5 rounded-full">
              <Users className="h-4 w-4" />
            </div>
          </button>
          <button className="w-full bg-purple-50 text-purple-700 p-3 rounded-lg flex items-center justify-between hover:bg-purple-100 transition duration-300">
            <div className="flex-1 text-left">
              <p className="font-semibold text-sm">Add New Worker</p>
              <p className="text-xs text-gray-500 mt-0.5">
                Crew are group of workers working under a contract/contractor
              </p>
            </div>
            <div className="bg-purple-100 p-1.5 rounded-full">
              <User className="h-4 w-4" />
            </div>
          </button>
          <div className="w-full">
            <p className="text-xs text-gray-500 mb-1">Coming Soon</p>
            <button className="w-full bg-gray-50 text-gray-400 p-3 rounded-lg flex items-center justify-between cursor-not-allowed">
              <div className="flex-1 text-left">
                <p className="font-semibold text-sm">Add New Employee</p>
                <p className="text-xs text-gray-400 mt-0.5">
                  Crew are group of workers working under a contract/contractor
                </p>
              </div>
              <div className="bg-gray-100 p-1.5 rounded-full">
                <User className="h-4 w-4" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewOptions;
