import React, { useState } from "react";
import { ChevronDown, Check, ArrowLeft } from "lucide-react";

const YourAddressForm = ({ onClose }) => {
  const [paymentType, setPaymentType] = useState("Daily Wage");

  return (
    <div className="bg-white p-6 max-w-2xl mx-auto">
      <h1>hello</h1>
      <h2>hello</h2>
      <ArrowLeft className="text-gray-600" size={24} onClick={onClose} />
      <h1 className="text-2xl font-semibold mb-6">Your Address</h1>
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">General Details</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Contractor's Name
          </label>
          <input
            type="text"
            placeholder="Type"
            className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Field of Work
          </label>
          <div className="relative">
            <select className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md appearance-none">
              <option>Select Field of Work</option>
              {/* Add more options here */}
            </select>
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Worker's Contact number
          </label>
          <div className="flex">
            <div className="bg-gray-100 border border-gray-300 rounded-l-md p-2 text-sm text-gray-500">
              +91
            </div>
            <input
              type="tel"
              placeholder="98700 00000"
              className="flex-grow p-2 bg-gray-50 border border-gray-300 rounded-r-md"
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Address (Optional)
          </label>
          <input
            type="text"
            placeholder="Type"
            className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">Payment Details</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Payment Type
          </label>
          <div className="flex space-x-4">
            <button
              className={`px-4 py-2 rounded-md ${
                paymentType === "Contract"
                  ? "bg-purple-100 text-purple-700"
                  : "bg-gray-100 text-gray-700"
              }`}
              onClick={() => setPaymentType("Contract")}
            >
              Contract
            </button>
            <button
              className={`px-4 py-2 rounded-md ${
                paymentType === "Daily Wage"
                  ? "bg-purple-100 text-purple-700"
                  : "bg-gray-100 text-gray-700"
              }`}
              onClick={() => setPaymentType("Daily Wage")}
            >
              Daily Wage
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Amount
          </label>
          <div className="relative">
            <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500">
              ₹
            </span>
            <input
              type="number"
              placeholder="Amount"
              className="w-full p-2 pl-6 bg-gray-50 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>

      <button className="w-full bg-purple-700 text-white py-3 rounded-md flex items-center justify-center">
        Update <Check className="ml-2 w-4 h-4" />
      </button>
    </div>
  );
};

export default YourAddressForm;
