import React, { useState } from "react";
import { BASE_URL } from "utils/constant";

const DeliveryPaymentFlow = ({ onClose, delivery }) => {
  const [currentStep, setCurrentStep] = useState("delivery");
  const [deliveryData, setDeliveryData] = useState(delivery);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNext = () => {
    setCurrentStep("payment");
  };

  const handleBack = () => {
    setCurrentStep("delivery");
  };

  return (
    <div className="relative overflow-hidden w-full max-w-2xl mx-auto">
      <div
        className="flex transition-transform duration-300 ease-in-out"
        style={{
          transform:
            currentStep === "payment" ? "translateX(-100%)" : "translateX(0)",
        }}
      >
        <div className="w-full flex-shrink-0">
          <MaterialDeliveryCheck
            onComplete={handleNext}
            onClose={onClose}
            deliveryData={deliveryData}
            setDeliveryData={setDeliveryData}
          />
        </div>
        <div className="w-full flex-shrink-0">
          <PaymentDetails
            onClose={onClose}
            onBack={handleBack}
            deliveryData={deliveryData}
            setDeliveryData={setDeliveryData}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </div>
      </div>
    </div>
  );
};
const MaterialDeliveryCheck = ({
  onComplete,
  onClose,
  deliveryData,
  setDeliveryData,
}) => {
  const updateMaterialCheck = async (materialId, checkData) => {
    try {
      const accessToken = localStorage.getItem("access");
      const response = await fetch(
        `${BASE_URL}/inventory/update-materials-of-delivery/${materialId}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(checkData),
        }
      );

      if (!response.ok) throw new Error("Failed to update material check");

      // Update local state after successful API call
      const updatedMaterials = deliveryData.all_materials.map((material) => {
        if (material.id === materialId) {
          return { ...material, ...checkData };
        }
        return material;
      });

      setDeliveryData({
        ...deliveryData,
        all_materials: updatedMaterials,
      });
    } catch (error) {
      console.error("Error updating material check:", error);
    }
  };

  const CheckSection = ({ title, rightContent, children }) => (
    <div className="mb-2">
      <div className="flex justify-between items-center mb-0.5">
        <span className="text-gray-600 text-sm">{title}</span>
        {rightContent && (
          <div className="text-right">
            <div className="font-medium text-sm">{rightContent.main}</div>
            <div className="text-gray-500 text-xs">{rightContent.sub}</div>
          </div>
        )}
      </div>
      {children}
    </div>
  );

  const RadioOption = ({ label, name, value, checked, onChange }) => (
    <label className="flex items-center mb-0.5">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="text-purple-600"
      />
      <span className="ml-2 text-sm">{label}</span>
    </label>
  );

  return (
    <div className="bg-white rounded-lg w-full">
      <div className="p-3">
        <div className="flex justify-between items-center mb-2">
          <button className="text-xl text-gray-600" onClick={onClose}>
            ×
          </button>
          <h1 className="text-base font-medium">Material Delivery Check</h1>
          <button className="text-xl text-gray-600">⋮</button>
        </div>

        <div className="w-full bg-purple-100 h-1 mb-3 rounded-full">
          <div className="w-1/2 bg-purple-700 h-1 rounded-full" />
        </div>

        <div className="grid grid-cols-1 gap-6">
          {deliveryData.all_materials.map((material) => (
            <div key={material.id}>
              <div className="text-sm text-gray-600 mb-1.5">
                {material.material_name} ({material.material_subcategory})
              </div>

              <CheckSection
                title="Specification Check"
                rightContent={{
                  main: `${material.unit_price} per ${material.unit}`,
                  sub: material.material_subcategory,
                }}
              >
                <RadioOption
                  label="Matched"
                  name={`spec-${material.id}`}
                  value="Matched"
                  checked={material.specification_check === "Matched"}
                  onChange={() =>
                    updateMaterialCheck(material.id, {
                      specification_check: "Matched",
                      specification_check_comment: "",
                    })
                  }
                />
                <div>
                  <RadioOption
                    label="Fault"
                    name={`spec-${material.id}`}
                    value="Fault"
                    checked={material.specification_check === "Fault"}
                    onChange={() =>
                      updateMaterialCheck(material.id, {
                        specification_check: "Fault",
                      })
                    }
                  />
                  {material.specification_check === "Fault" && (
                    <input
                      type="text"
                      placeholder="Specify fault"
                      value={material.specification_check_comment || ""}
                      onChange={(e) =>
                        updateMaterialCheck(material.id, {
                          specification_check: "Fault",
                          specification_check_comment: e.target.value,
                        })
                      }
                      className="w-full p-1 text-sm border border-gray-200 rounded bg-gray-50"
                    />
                  )}
                </div>
              </CheckSection>

              <CheckSection
                title="Quantity Check"
                rightContent={{
                  main: `${material.quantity} ${material.unit}`,
                  sub: "Expected",
                }}
              >
                <RadioOption
                  label="Matched"
                  name={`quantity-${material.id}`}
                  value="Matched"
                  checked={material.quantity_check === "Matched"}
                  onChange={() =>
                    updateMaterialCheck(material.id, {
                      quantity_check: "Matched",
                      false_quantity: null,
                    })
                  }
                />
                <div>
                  <RadioOption
                    label="Fault"
                    name={`quantity-${material.id}`}
                    value="Fault"
                    checked={material.quantity_check === "Fault"}
                    onChange={() =>
                      updateMaterialCheck(material.id, {
                        quantity_check: "Fault",
                      })
                    }
                  />
                  {material.quantity_check === "Fault" && (
                    <input
                      type="number"
                      placeholder="Enter received quantity"
                      value={material.false_quantity || ""}
                      onChange={(e) =>
                        updateMaterialCheck(material.id, {
                          quantity_check: "Fault",
                          false_quantity: e.target.value,
                        })
                      }
                      className="w-full p-1 text-sm border border-gray-200 rounded bg-gray-50"
                    />
                  )}
                </div>
              </CheckSection>

              <CheckSection title="Quality Check">
                <RadioOption
                  label="Matched"
                  name={`quality-${material.id}`}
                  value="Matched"
                  checked={material.quality_check === "Matched"}
                  onChange={() =>
                    updateMaterialCheck(material.id, {
                      quality_check: "Matched",
                      false_quality: null,
                    })
                  }
                />
                <RadioOption
                  label="Fault"
                  name={`quality-${material.id}`}
                  value="Fault"
                  checked={material.quality_check === "Fault"}
                  onChange={() =>
                    updateMaterialCheck(material.id, {
                      quality_check: "Fault",
                    })
                  }
                />
              </CheckSection>
            </div>
          ))}
        </div>

        <div className="mt-3 flex justify-center">
          <button
            onClick={onComplete}
            className="bg-purple-700 text-white px-8 py-1.5 rounded-lg flex items-center text-sm"
          >
            Next
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const PaymentDetails = ({
  onClose,
  onBack,
  deliveryData,
  setDeliveryData,
  isSubmitting,
  setIsSubmitting,
}) => {
  const [newAmount, setNewAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(
    deliveryData.payment_method
  );
  const [error, setError] = useState("");

  const paymentMethods = ["Cash", "UPI/Bank Transfer", "Cheque"];

  const handleMarkAsReceived = async () => {
    try {
      setIsSubmitting(true);
      setError("");

      const accessToken = localStorage.getItem("access");
      const response = await fetch(`${BASE_URL}/inventory/mark-as-delivered/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          delivery_id: deliveryData.id,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to mark as received");
      }

      // Close the modal after successful submission
      onClose();
    } catch (error) {
      setError(error.message || "Failed to mark as received");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg w-full">
      <div className="p-3">
        <div className="flex justify-between items-center mb-2">
          <button className="text-xl text-gray-600" onClick={onBack}>
            ←
          </button>
          <h1 className="text-base font-medium">Payment Details</h1>
          <button className="text-xl text-gray-600">⋮</button>
        </div>

        <div className="w-full bg-purple-100 h-1 mb-3 rounded-full">
          <div className="w-full bg-purple-700 h-1 rounded-full" />
        </div>

        {error && (
          <div className="mb-3 p-2 bg-red-100 text-red-700 rounded-lg text-sm">
            {error}
          </div>
        )}

        <div className="flex gap-3">
          <div className="flex-1">
            <div className="bg-white rounded-lg p-4 h-full border border-gray-100">
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Total Receipt Amount</span>
                  <span className="font-medium">
                    ₹ {deliveryData.total_price}
                  </span>
                </div>
                <div className="border-t border-gray-200 pt-3">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Total Paid Amount</span>
                    <span className="font-medium">
                      ₹ {deliveryData.paid_amount}
                    </span>
                  </div>
                </div>
                <div className="border-t border-gray-200 pt-3">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Payment Due</span>
                    <span className="font-medium">
                      ₹ {deliveryData.remaining_payment}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="bg-white rounded-lg p-4 h-full border border-gray-100">
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-600 mb-1">
                    New Paid Amount
                  </label>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                      ₹
                    </span>
                    <input
                      type="text"
                      className="w-full p-2 pl-7 bg-gray-50 border border-gray-200 rounded"
                      placeholder="Amount"
                      value={newAmount}
                      onChange={(e) => setNewAmount(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-gray-600 mb-2">Method</label>
                  <div className="flex flex-wrap gap-2">
                    {paymentMethods.map((method) => (
                      <button
                        key={method}
                        className={`px-4 py-1 rounded-full text-sm ${
                          paymentMethod === method
                            ? "bg-[#3B0069] text-white"
                            : "bg-gray-200 text-gray-600"
                        }`}
                        onClick={() => setPaymentMethod(method)}
                      >
                        {method}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Remaining Payment</span>
                    <span className="font-medium">
                      ₹ {deliveryData.remaining_payment}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-3">
          <button
            className={`bg-purple-700 text-white py-2 px-4 rounded-lg flex items-center justify-center ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleMarkAsReceived}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Processing..." : "Mark as Received"}
            {!isSubmitting && (
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
          </button>
          <button className="bg-purple-700 text-white py-2 px-4 rounded-lg flex items-center justify-center">
            Return
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPaymentFlow;
