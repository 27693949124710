import React from "react";
import { Truck, User2, Wallet, RotateCcw, CreditCard, X } from "lucide-react";

const TransactionModal = ({ isOpen, onClose, handleClick }) => {
  if (!isOpen) return null;

  const transactionTypes = [
    {
      title: "Material Purchase",
      key: "material",
      icon: <Truck className="w-5 h-5" />,
    },
    {
      title: "Worker's Payment",
      key: "worker",
      icon: <User2 className="w-5 h-5" />,
    },
    {
      title: "Other Payments",
      key: "other",
      icon: <Wallet className="w-5 h-5" />,
    },
    {
      title: "Add Refund",
      key: "refund",
      icon: <RotateCcw className="w-5 h-5" />,
    },
    {
      title: "Received",
      key: "receive",
      icon: <CreditCard className="w-5 h-5" />,
    },
  ];

  // Close modal when clicking outside
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4 relative">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-5 h-5" />
        </button>

        {/* Modal header */}
        <h2 className="text-xl font-semibold mb-6">Add New Transaction</h2>

        {/* Transaction type buttons */}
        <div className="flex flex-col gap-3">
          {transactionTypes.map((type) => (
            <button
              key={type.title}
              onClick={() => handleClick(type.key)}
              className="flex items-center gap-3 p-4 rounded-lg bg-purple-50 hover:bg-purple-100 transition-colors duration-200 w-full text-left"
            >
              <span className="text-purple-600">{type.icon}</span>
              <span className="text-purple-900">{type.title}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;
