import React, { useState, useEffect } from "react";
import { X, ChevronDown, Calendar, Plus } from "lucide-react";
import { BASE_URL } from "utils/constant";

const CreateTaskForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    task_name: "",
    task_details: "",
    start_date: "",
    end_date: "",
    priority: 1,
    estimated_amount: 0,
    task_members: [],
    documents: [],
    task_materials: []
  });

  const [siteMembers, setSiteMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    fetchSiteMembers();
  }, []);

  const fetchSiteMembers = async () => {
    try {
      const siteId = localStorage.getItem("site");
      const accessToken = localStorage.getItem("access");
      
      const response = await fetch(
        `${BASE_URL}/team/list-site-members/${siteId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      const data = await response.json();
      if (data.status) {
        setSiteMembers(data.data);
      } else {
        setError("Failed to fetch team members");
      }
    } catch (err) {
      setError("Error fetching team members");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const toggleMember = (memberId) => {
    setSelectedMembers(prev => {
      const isSelected = prev.includes(memberId);
      if (isSelected) {
        return prev.filter(id => id !== memberId);
      } else {
        return [...prev, memberId];
      }
    });

    setFormData(prev => ({
      ...prev,
      task_members: selectedMembers.includes(memberId)
        ? prev.task_members.filter(id => id !== memberId)
        : [...prev.task_members, memberId]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const siteId = localStorage.getItem("site");
      const accessToken = localStorage.getItem("access");

      const requestBody = {
        ...formData,
        sites: parseInt(siteId),
      };

      const response = await fetch(`${BASE_URL}/task/create-tasks/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status) {
        onClose();
      } else {
        setError(data.message || "Failed to create task");
      }
    } catch (err) {
      setError("Error creating task");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 shadow-lg mt-4 rounded-lg w-[65%] h-[80vh] mx-auto overflow-y-auto">
      <div className="relative mb-2">
        <div className="h-10 bg-white flex items-center mt-3 justify-center relative">
          <h2 className="text-lg font-semibold">Create Task</h2>
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <X size={18} />
          </button>
        </div>
      </div>

      {error && (
        <div className="px-6 py-2 text-red-600 text-sm">{error}</div>
      )}

      <form onSubmit={handleSubmit} className="px-6 pb-4 space-y-3">
        <div>
          <label className="block text-sm text-gray-600 mb-1">Task Name</label>
          <input
            type="text"
            name="task_name"
            value={formData.task_name}
            onChange={handleInputChange}
            placeholder="Type"
            className="w-full p-2 bg-gray-50 rounded-md"
            required
          />
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">Task Details</label>
          <textarea
            name="task_details"
            value={formData.task_details}
            onChange={handleInputChange}
            rows="2"
            placeholder="Lorem ipsum dolor sit amet"
            className="w-full p-2 bg-gray-50 rounded-md resize-none"
            required
          />
        </div>

        {/* Team Members Section */}
        <div>
          <label className="block text-sm text-gray-600 mb-1">Team Members</label>
          <div className="flex flex-wrap items-center gap-2 bg-gray-50 p-2 rounded-md">
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 rounded-full bg-white border border-purple-600 text-purple-600"
            >
              <Plus size={16} />
            </button>
            {siteMembers.map(member => (
              <div
                key={member.member_id}
                onClick={() => toggleMember(member.member_id)}
                className={`w-8 h-8 rounded-full overflow-hidden cursor-pointer relative ${
                  selectedMembers.includes(member.member_id)
                    ? 'ring-2 ring-purple-600'
                    : ''
                }`}
                title={`${member.first_name} ${member.last_name}`}
              >
                <div className={`w-full h-full flex items-center justify-center text-xs font-medium ${
                  selectedMembers.includes(member.member_id)
                    ? 'bg-[#3B0069] text-white'
                    : 'bg-gray-200 text-gray-600'
                }`}>
                  {member.first_name[0]}{member.last_name[0]}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Date Selection */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm text-gray-600 mb-1">Starting Date</label>
            <div className="relative">
              <input
                type="text"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder="Select start date"
                className="w-full p-2 bg-gray-50 rounded-md pr-8"
                required
              />
              <Calendar
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                size={16}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Ending Date</label>
            <div className="relative">
              <input
                type="text"
                name="end_date"
                value={formData.end_date}
                onChange={handleInputChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder="Select end date"
                className="w-full p-2 bg-gray-50 rounded-md pr-8"
                required
              />
              <Calendar
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                size={16}
              />
            </div>
          </div>
        </div>

        {/* Priority and Amount */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm text-gray-600 mb-1">Priority</label>
            <div className="relative">
              <select
                name="priority"
                value={formData.priority}
                onChange={handleInputChange}
                className="w-full p-2 bg-gray-50 rounded-md appearance-none"
              >
                <option value={0}>No Priority</option>
                <option value={1}>Medium Priority</option>
                <option value={2}>High Priority</option>
              </select>
              <ChevronDown
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={16}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Estimated Amount</label>
            <input
              type="number"
              name="estimated_amount"
              value={formData.estimated_amount}
              onChange={handleInputChange}
              placeholder="Enter amount"
              className="w-full p-2 bg-gray-50 rounded-md"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-purple-700 text-white py-2 rounded-md hover:bg-purple-800 transition duration-300 flex items-center justify-center disabled:bg-purple-400"
        >
          {loading ? "Creating..." : "Create Task ✓"}
        </button>
      </form>
    </div>
  );
};

export default CreateTaskForm;