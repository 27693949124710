import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Search, MoreVertical, ArrowUpRight, Share2 } from "lucide-react";
import { BASE_URL } from "utils/constant";
import StockUpdateForm from "./StockUpdateforTask";

const InventoryItemDetails = ({ item, onBack }) => {
  const [stockData, setStockData] = useState([]);
  const [showStockForm, setShowStockForm] = useState(false);
  const [stockSummary, setStockSummary] = useState({
    positive: 0,
    negative: 0,
    inStock: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateStockSummary = (data) => {
    const summary = data.reduce(
      (acc, curr) => {
        if (curr.entered_quantity > 0) {
          acc.positive += curr.entered_quantity;
        } else {
          acc.negative += Math.abs(curr.entered_quantity);
        }
        return acc;
      },
      { positive: 0, negative: 0 }
    );

    summary.inStock = summary.positive - summary.negative;
    return summary;
  };

  useEffect(() => {
    const fetchStockData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const accessToken = localStorage.getItem("access");
        const response = await fetch(
          `${BASE_URL}/inventory/get-stocks-of-a-material/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken.trim()}`,
            },
            body: JSON.stringify({
              material: item.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch stock data");
        }

        const data = await response.json();
        setStockData(data.data);
        const summary = calculateStockSummary(data.data);
        setStockSummary(summary);
      } catch (error) {
        console.error("Error fetching stock data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStockData();
  }, [item.id]);

  const handleStockUpdate = (newStockData) => {
    // Add the new stock data to the beginning of the array
    const updatedStockData = [newStockData, ...stockData];
    setStockData(updatedStockData);

    // Recalculate the summary
    const newSummary = calculateStockSummary(updatedStockData);
    setStockSummary(newSummary);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const chartData = stockData.map((item, index) => ({
    date: item.update_date,
    positive: item.entered_quantity > 0 ? item.entered_quantity : 0,
    negative: item.entered_quantity < 0 ? Math.abs(item.entered_quantity) : 0,
    index: index + 1,
  }));

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-700"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-red-600 mb-4">Error: {error}</div>
        <button onClick={onBack} className="text-blue-600 hover:text-blue-800">
          ← Back
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <button
        onClick={onBack}
        className="mb-4 text-blue-600 hover:text-blue-800"
      >
        ← Back
      </button>

      {/* Header Section with Site Name and Icons */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-sm text-gray-500">
          Site name & Address with pin
        </div>
        <div className="flex gap-4">
          <Search className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700" />
          <MoreVertical className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700" />
        </div>
      </div>

      {/* Main Container with Cement Details, Stock Details, and Graph */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Section: Cement, Specification, and Stock Details */}
        <div className="bg-white rounded-lg shadow p-4">
          {/* Cement Details */}
          <div className="flex justify-between items-start border-b border-gray-200 pb-4 mb-4">
            <div>
              <h1 className="text-xl font-semibold mb-1">
                {item.name || "Cement"}
              </h1>
              <div className="text-sm text-gray-600">
                Sub Category:{" "}
                <span className="text-gray-900">
                  {item.category || "Cement Grade 50"}
                </span>
              </div>
            </div>
            <div className="text-sm text-gray-600 text-right">
              Specification:{" "}
              <span className="text-black">
                {item.specification || "Ambuja Cement"}
              </span>
            </div>
          </div>

          {/* Stock Details Section */}
          <h3 className="text-sm font-medium mb-4">Stock Details</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center gap-2 p-2 border rounded-lg hover:bg-gray-50">
              <div className="w-4 h-4 bg-purple-100 rounded-lg flex items-center justify-center">
                <Share2 className="w-3 h-3 text-purple-600" />
              </div>
              <span className="text-gray-600 text-sm">InStock:</span>
              <span className="font-medium text-sm">
                {stockSummary.inStock.toFixed(2)} Bags
              </span>
            </div>
            <div className="flex items-center gap-2 p-2 border rounded-lg hover:bg-gray-50">
              <div className="w-4 h-4 bg-green-100 rounded-lg flex items-center justify-center">
                <ArrowUpRight className="w-3 h-3 text-green-600" />
              </div>
              <span className="text-gray-600 text-sm">Added:</span>
              <span className="font-medium text-sm">
                {stockSummary.positive.toFixed(2)} Bags
              </span>
            </div>
            <div className="flex items-center gap-2 p-2 border rounded-lg hover:bg-gray-50">
              <div className="w-4 h-4 bg-red-100 rounded-lg flex items-center justify-center">
                <ArrowUpRight className="w-3 h-3 text-red-600 rotate-90" />
              </div>
              <span className="text-gray-600 text-sm">Used:</span>
              <span className="font-medium text-sm">
                {stockSummary.negative.toFixed(2)} Bags
              </span>
            </div>
          </div>
        </div>

        {/* Right Section: Stock Updates Graph */}
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex justify-between items-center mb-4">
            <button className="text-gray-600 border-b-2 border-gray-900 pb-1">
              Stock Updates
            </button>
            <div className="flex items-center gap-4">
              <button className="text-gray-400 hover:text-gray-600">
                Deliveries
              </button>
              <MoreVertical className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700" />
            </div>
          </div>
          <h3 className="text-sm font-medium mb-4">Change in stock</h3>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={chartData}>
              <XAxis
                dataKey="index"
                tickFormatter={(value) => `Day ${value}`}
              />
              <YAxis />
              <Line
                name="Added"
                type="monotone"
                dataKey="positive"
                stroke="#10B981"
                strokeWidth={2}
                connectNulls
                dot={false}
              />
              <Line
                name="Used"
                type="monotone"
                dataKey="negative"
                stroke="#EF4444"
                strokeWidth={2}
                connectNulls
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Stock Update History Section */}
      <div className="bg-white rounded-lg shadow p-4 mt-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Stock Update History</h3>
          <div className="flex items-center ml-auto mr-10">
            <button
              className="bg-purple-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 pr-2 hover:bg-purple-800 transition-colors"
              onClick={() => setShowStockForm(true)}
            >
              <ArrowUpRight className="w-4 h-4" />
              Update Stock
            </button>
          </div>
        </div>
        <div className="space-y-4">
          {stockData.map((item) => (
            <div
              key={item.id}
              className="grid grid-cols-[auto,1fr,auto] gap-4 items-center border-b pb-3 hover:bg-gray-50"
            >
              <div className="flex items-center gap-3">
                <div
                  className={`w-6 h-6 flex items-center justify-center rounded-lg ${
                    item.entered_quantity > 0 ? "bg-green-100" : "bg-red-100"
                  }`}
                >
                  <ArrowUpRight
                    className={`w-4 h-4 ${
                      item.entered_quantity > 0
                        ? "text-green-600"
                        : "text-red-600 rotate-90"
                    }`}
                  />
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <span
                      className={`font-medium ${
                        item.entered_quantity > 0
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {item.entered_quantity > 0 ? "+" : ""}
                      {Math.abs(item.entered_quantity)} Bags
                    </span>
                    <span className="text-sm text-gray-500">
                      {item.entered_quantity > 0 ? "Added" : "Used"}
                    </span>
                  </div>
                  <div className="text-sm text-gray-500">
                    {formatDate(item.created_on)}
                  </div>
                </div>
              </div>
              <div className="text-sm text-gray-500">
                {item.comment
                  ? `Comment: ${item.comment}`
                  : "No comment provided"}
              </div>
              <div className="text-sm text-gray-500">Updated by: You</div>
            </div>
          ))}
        </div>
      </div>

      {/* Stock Update Modal */}
      {showStockForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <StockUpdateForm
            onClose={() => setShowStockForm(false)}
            materialId={item.id}
            currentStock={stockSummary.inStock}
            onStockUpdate={handleStockUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default InventoryItemDetails;
