import React, { useState } from "react";
import { Calendar, Upload, X } from "lucide-react";
import { BASE_URL } from "utils/constant";

export default function OtherPaymentForm({ onClose }) {
  const [transactionType, setTransactionType] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [remarks, setRemarks] = useState("");

  const transactionTypes = [
    "Equipments",
    "Electricity",
    "Transport",
    "Municipality/Panchayat",
    "Water Bill",
  ];

  const handleSave = async () => {
    if (!transactionType || !amount) return;

    const site = localStorage.getItem("site");
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const paymentData = {
      transaction_type: transactionType,
      transaction_date: formattedDate,
      amount: parseInt(amount),
      transaction_method: paymentMethod,
      comments: remarks,
      receipt_url: "",
      transaction_catagory_id: 6,
      site: parseInt(site),
    };

    try {
      const accessToken = localStorage.getItem("access");
      const response = await fetch(
        `${BASE_URL}/finance/create-general-transaction/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(paymentData),
        }
      );
      const data = await response.json();
      if (data.status) {
        onClose();
      }
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };

  return (
    <div className="w-full p-6 bg-gray-50">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 bg-white p-4 rounded-lg shadow-sm">
        <button onClick={onClose}>
          <X className="h-5 w-5" />
        </button>
        <h1 className="text-lg font-semibold">Other Payment</h1>
        <div className="w-5" />
      </div>

      {/* Main Form */}
      <div className="grid grid-cols-1 gap-4">
        {/* Purpose of Transaction */}
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <label className="block text-sm text-gray-600 mb-1">
            Purpose of Transaction
          </label>
          <input
            type="text"
            className="w-full p-3 bg-white rounded-lg border border-gray-200 mb-3"
            placeholder="Type"
            value={transactionType}
            onChange={(e) => setTransactionType(e.target.value)}
          />
          <div className="flex flex-wrap gap-2">
            {transactionTypes.map((type) => (
              <button
                key={type}
                onClick={() => setTransactionType(type)}
                className={`px-4 py-2 rounded-full text-sm ${
                  transactionType === type
                    ? "bg-purple-100 text-purple-600"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        {/* Left and Right Side Sections */}
        <div className="grid grid-cols-2 gap-4">
          {/* Left Side */}
          <div className="space-y-4">
            {/* Payment Date */}
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <label className="block text-sm text-gray-600 mb-1">
                Payment Date
              </label>
              <div className="flex gap-2">
                <button className="px-4 py-2 rounded-full bg-purple-100 text-purple-600 text-sm">
                  Now
                </button>
                <button className="px-4 py-2 rounded-full bg-gray-100 text-gray-600 text-sm flex items-center gap-2">
                  Pick an older date
                  <Calendar className="h-4 w-4" />
                </button>
              </div>
            </div>

            {/* Amount and Payment Method */}
            <div className="bg-white p-4 rounded-lg space-y-4 shadow-sm">
              <div>
                <label className="block text-sm text-gray-600 mb-1">
                  ₹ Amount
                </label>
                <input
                  type="text"
                  className="w-full p-3 bg-white rounded-lg border border-gray-200"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div>
                <label className="block text-sm text-gray-600 mb-1">
                  Payment Method
                </label>
                <div className="flex flex-wrap gap-2">
                  {["Cash", "UPI/Bank Transfer", "Cheque"].map((method) => (
                    <button
                      key={method}
                      className={`px-4 py-2 rounded-full text-sm ${
                        paymentMethod === method
                          ? "bg-purple-100 text-purple-600"
                          : "bg-gray-100 text-gray-600"
                      }`}
                      onClick={() => setPaymentMethod(method)}
                    >
                      {method}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Side */}
          <div className="space-y-4">
            {/* Remarks */}
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <input
                type="text"
                className="w-full p-2 bg-transparent border-b border-gray-300"
                placeholder="Remarks / Comments (Optional)"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>

            {/* Payment Slip */}
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <p className="text-sm text-gray-600 mb-2">
                Payment Slip (Optional)
              </p>
              <div className="flex gap-3">
                <button className="flex-1 p-3 bg-purple-50 text-purple-600 rounded-md">
                  Take Photos
                </button>
                <button className="flex-1 p-3 bg-purple-50 text-purple-600 rounded-md">
                  Upload Photos
                </button>
              </div>
              <div className="grid grid-cols-4 gap-2 mt-4">
                {[1, 2, 3, 4].map((placeholder) => (
                  <div
                    key={placeholder}
                    className="aspect-square bg-gray-100 rounded-md flex items-center justify-center"
                  >
                    <Upload className="h-6 w-6 text-gray-400" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <button
        className="w-full mt-6 bg-[#3B0069] text-white py-3 rounded-md font-medium hover:bg-purple-700 transition duration-300 flex items-center justify-center gap-2"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
}
