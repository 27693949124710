import React, { useState } from "react";
import { X, ChevronRight, Check } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { BASE_URL } from "utils/constant";

// Zod schema for form validation
const materialSchema = z.object({
  material_name: z.string().min(1, "Material name is required"),
  specification: z.string().min(1, "Specification is required"),
  subcategory: z.string().min(1, "Material subcategory is required"),
  uom: z.string().min(1, "Unit of measurement is required"),
  current_stock: z
    .number({
      required_error: "Current stock is required",
      invalid_type_error: "Current stock must be a number",
    })
    .nonnegative("Current stock must be non-negative"),
});

const MaterialCard = ({ onSelect, onClose }) => {
  return (
    <div className="bg-gray-100 shadow-lg mt-4 rounded-lg w-[65%] h-[80vh] mx-auto overflow-y-auto">
      <div className="relative mb-2">
        <div className="h-10 bg-white flex items-center mt-3 justify-center relative">
          <h2 className="text-lg font-semibold">Material Library</h2>
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <X size={18} />
          </button>
        </div>
      </div>

      <div className="p-4">
        <input
          type="text"
          placeholder="Custom Material"
          className="w-full p-2 mb-4 bg-white rounded-md"
        />

        <div className="space-y-2">
          {[
            {
              name: "Bricks",
              desc: "Clay bricks, concrete bricks, fly ash bricks, hollow blocks, etc.",
            },
            {
              name: "Cement",
              desc: "Portland cement, white cement, rapid hardening cement, etc.",
            },
            {
              name: "Concrete Blocks",
              desc: "Solid concrete blocks, hollow concrete blocks, AAC blocks.",
            },
            {
              name: "Timber/Wood",
              desc: "Lumber, plywood, particleboard, and other wood products.",
            },
            {
              name: "Glass",
              desc: "Float glass, safety glass, laminated glass, and glazing materials",
            },
            {
              name: "Plumbing Materials",
              desc: "PVC pipes, copper pipes, PEX pipes, fittings, valves, water fixtures.",
            },
            {
              name: "Electrical Materials",
              desc: "Copper wires, electrical conduits, switches, outlets, circuit breakers.",
            },
            {
              name: "Paints",
              desc: "Interior paints, exterior paints, primers",
            },
          ].map((material, idx) => (
            <div
              key={idx}
              onClick={() => onSelect(material.name)}
              className="p-3 bg-white rounded-md hover:bg-gray-50 cursor-pointer"
            >
              <div className="font-medium">{material.name}</div>
              <div className="text-sm text-gray-500">{material.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const UnitsCard = ({ onSelect, onClose }) => {
  return (
    <div className="bg-gray-100 shadow-lg mt-4 rounded-lg w-[65%] h-[80vh] mx-auto overflow-y-auto">
      <div className="relative mb-2">
        <div className="h-10 bg-white flex items-center mt-3 justify-center relative">
          <h2 className="text-lg font-semibold">Units</h2>
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <X size={18} />
          </button>
        </div>
      </div>

      <div className="p-4">
        <input
          type="text"
          placeholder="Custom Unit"
          className="w-full p-2 mb-4 bg-white rounded-md"
        />

        <div className="space-y-2">
          {[
            { name: "Pieces", symbol: "pcs" },
            { name: "Tons", symbol: "t" },
            { name: "CFT", symbol: "ft³" },
            { name: "Kilograms", symbol: "kg" },
            { name: "Meters", symbol: "m" },
            { name: "Liters", symbol: "L" },
            { name: "Square meters", symbol: "m²" },
            { name: "Cubic meters", symbol: "m³" },
          ].map((unit, idx) => (
            <div
              key={idx}
              onClick={() => onSelect(unit.name)}
              className="p-3 bg-white rounded-md hover:bg-gray-50 cursor-pointer flex justify-between items-center"
            >
              <span className="font-medium">{unit.name}</span>
              <span className="text-gray-500">{unit.symbol}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MainForm = ({
  onMaterialClick,
  onUnitClick,
  selectedMaterial,
  selectedUnit,
  onClose,
  register,
  errors,
  handleSubmit,
}) => {
  return (
    <div className="bg-gray-100 shadow-lg mt-4 rounded-lg w-[65%] h-[80vh] mx-auto overflow-y-auto">
      <div className="relative mb-2">
        <div className="h-10 bg-white flex items-center mt-3 justify-center relative">
          <h2 className="text-lg font-semibold">New Material</h2>
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <X size={18} />
          </button>
        </div>
      </div>

      <form className="px-6 pb-4 space-y-4" onSubmit={handleSubmit}>
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Material Name
          </label>
          <div className="flex items-center justify-between bg-white p-2 rounded-md">
            <input
              {...register("material_name")}
              type="text"
              placeholder="Name"
              className="bg-transparent w-full outline-none"
            />
            <ChevronRight size={20} className="text-gray-400" />
          </div>
          {errors.material_name && (
            <p className="text-red-500 text-sm mt-1">
              {errors.material_name.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Material Subcategory
          </label>
          <div
            onClick={onMaterialClick}
            className="flex items-center justify-between bg-white p-2 rounded-md cursor-pointer"
          >
            <input
              {...register("subcategory")}
              type="text"
              placeholder="Select material type"
              value={selectedMaterial}
              className="bg-transparent w-full outline-none cursor-pointer"
              readOnly
            />
            <ChevronRight size={20} className="text-gray-400" />
          </div>
          {errors.subcategory && (
            <p className="text-red-500 text-sm mt-1">
              {errors.subcategory.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Specification
          </label>
          <input
            {...register("specification")}
            type="text"
            placeholder="Type"
            className="w-full p-2 bg-white rounded-md outline-none"
          />
          {errors.specification && (
            <p className="text-red-500 text-sm mt-1">
              {errors.specification.message}
            </p>
          )}
          <p className="text-xs text-gray-500 mt-1">
            (Brand, Colour, Quality etc.)
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Unit of measurement
          </label>
          <div
            onClick={onUnitClick}
            className="flex items-center justify-between bg-white p-2 rounded-md cursor-pointer"
          >
            <input
              {...register("uom")}
              type="text"
              placeholder="Select unit"
              value={selectedUnit}
              className="bg-transparent w-full outline-none cursor-pointer"
              readOnly
            />
            <ChevronRight size={20} className="text-gray-400" />
          </div>
          {errors.uom && (
            <p className="text-red-500 text-sm mt-1">{errors.uom.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Current Stock
          </label>
          <input
            {...register("current_stock", { valueAsNumber: true })}
            type="number"
            placeholder="Enter"
            className="w-full p-2 bg-white rounded-md outline-none"
          />
          {errors.current_stock && (
            <p className="text-red-500 text-sm mt-1">
              {errors.current_stock.message}
            </p>
          )}
          <p className="text-xs text-gray-500 mt-1">
            (no entry will be a count as 0 is stock)
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-700 text-white py-2 rounded-md hover:bg-purple-800 transition duration-300 flex items-center justify-center mt-6"
        >
          <span>Save & Continue Purchase</span>
          <Check size={20} className="ml-2" />
        </button>
      </form>
    </div>
  );
};

const NewMaterialForm = ({ onClose }) => {
  const [currentView, setCurrentView] = useState("main");
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [submitError, setSubmitError] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(materialSchema),
    defaultValues: {
      material_name: "",
      specification: "",
      subcategory: "",
      uom: "",
      current_stock: 0,
    },
  });

  const handleMaterialSelect = (material) => {
    setSelectedMaterial(material);
    setValue("subcategory", material);
    setCurrentView("main");
  };

  const handleUnitSelect = (unit) => {
    setSelectedUnit(unit);
    setValue("uom", unit);
    setCurrentView("main");
  };

  const onSubmit = async (data) => {
    try {
      const siteId = localStorage.getItem("site");
      const accessToken = localStorage.getItem("access");
      const payload = {
        ...data,
        sites: parseInt(siteId),
      };

      const response = await fetch(`${BASE_URL}/inventory/create-material/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken.trim()}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to create material");
      }

      // Handle successful submission
      onClose();
    } catch (error) {
      setSubmitError(error.message);
    }
  };

  return (
    <>
      {currentView === "main" && (
        <MainForm
          onMaterialClick={() => setCurrentView("material")}
          onUnitClick={() => setCurrentView("units")}
          selectedMaterial={selectedMaterial}
          selectedUnit={selectedUnit}
          onClose={onClose}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit(onSubmit)}
        />
      )}

      {currentView === "material" && (
        <MaterialCard
          onSelect={handleMaterialSelect}
          onClose={() => setCurrentView("main")}
        />
      )}

      {currentView === "units" && (
        <UnitsCard
          onSelect={handleUnitSelect}
          onClose={() => setCurrentView("main")}
        />
      )}
    </>
  );
};

export default NewMaterialForm;
