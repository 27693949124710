import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "utils/constant";
import { PiSquareSplitVerticalFill } from "react-icons/pi";
import { AiFillPlusCircle } from "react-icons/ai";
import { add } from "date-fns";

const LeftSidebar = ({
  onFilterApply,
  onInvitationsClick,
  onNewProjectClick,
  organizationId,
  showInvitationsForm
}) => {
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();

  const handleMyOrgClick = () => {
    navigate("/organization-detail");
  };

  const getOrganizationMembers = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      const organization = localStorage.getItem("organization");
      const organizationId = JSON.parse(organization)?.id;
      console.log("HELLLOOO");
      if (accessToken && organizationId) {
        const response = await fetch(
          `${BASE_URL}/org/organization/${organizationId}/members/`,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            }),
          }
        );
        const data = await response.json();
        if (data.status) {
          console.log("Organization members:", data.data);
          setMembers(data.data);
        }
      }
    } catch (error) {
      console.log("Error fetching organization members:", error);
    }
  };
  useEffect(() => {
    getOrganizationMembers();
  }, [organizationId, showInvitationsForm]);

  return (
    <aside style={styles.sidebar}>
      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>New Project</h2>
        <div style={styles.menuItem} onClick={handleMyOrgClick}>
          <div
            className="flex items-center bg-[#4472FF40] rounded-full"
            style={{ padding: "4px" }}
          >
            <PiSquareSplitVerticalFill
              className="text-[#4472FF]"
              width={30}
              height={30}
            />
          </div>
          <span>My Organization</span>
        </div>
        <div style={styles.menuItem} onClick={onNewProjectClick}>
          <div
            className="flex items-center bg-[#FFBB3840] rounded-full"
            style={{ padding: "4px" }}
          >
            <PiSquareSplitVerticalFill
              className="text-[#FFBB38]"
              width={30}
              height={30}
            />
          </div>
          <span>Create Project</span>
        </div>
        <div style={styles.menuItem} onClick={onInvitationsClick}>
          <div
            className="flex items-center bg-[#2FDFD240] rounded-full"
            style={{ padding: "4px" }}
          >
            <PiSquareSplitVerticalFill
              className="text-[#2FDFD2]"
              width={30}
              height={30}
            />
          </div>
          <span>Invitations</span>
        </div>
        <div style={styles.menuItem}>
          <div
            className="flex items-center bg-[#2FDFD240] rounded-full"
            style={{ padding: "4px" }}
          >
            <PiSquareSplitVerticalFill
              className="text-[#2FDFD2]"
              width={30}
              height={30}
            />
          </div>
          <span>Search & Join</span>
        </div>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>Organization Team</h2>
        <button style={styles.addMemberButton} onClick={onInvitationsClick}>
          <AiFillPlusCircle fill="#3B0069" width={70} />
          Add New Member
        </button>
        <div className="mt-4 space-y-3 px-2">
          {members.map((member) => (
            <div key={member.id} className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
                {member.avatar ? (
                  <img
                    src={member.avatar}
                    alt={member.user.first_name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center text-purple-600 text-sm">
                    {member.user.first_name.charAt(0)}
                  </div>
                )}
              </div>
              <div className="flex-1">
                <div className="text-sm font-medium text-gray-900">
                  {member.user.first_name}
                </div>
                <div className="text-xs text-gray-500">
                  ({member.joined_as})
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

const styles = {
  addMemberButton: {
    display: `${
      localStorage.getItem("isIndividual") === "true" ? "none" : "flex"
    }`,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 8px",
    borderRadius: "10px",
    flexDirection: "row",
    gap: "10px",
    marginTop: "10px",
    border: "1px solid #3B0069",
    backgroundColor: "#FFFFFF",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    padding: "10px",
    cursor: "pointer",
  },
  sidebar: {
    width: "200px",
    padding: "-2px",
    backgroundColor: "#FFFFFF",
    marginTop: "12px",
    // Removed boxShadow property to eliminate the black line
  },
  section: {
    marginBottom: "40px",
    padding: "5px",
  },
  sectionTitle: {
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "0px",
    marginLeft: "14px",
    marginTop: "20px",
  },
  list: {
    listStyleType: "none",
    padding: "0",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    marginBottom: "20px",
    cursor: "pointer",
    marginLeft: "10px",
    fontWeight: "500",
    color: "#000000",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    verticalAlign: "middle",
    marginTop: "4px",
  }
};

export default LeftSidebar;