import React, { useState } from "react";
import { Search, ChevronUp, ChevronDown } from "lucide-react";

const FieldOfWork = () => {
  const [expanded, setExpanded] = useState("Building and Structural Work");

  const fields = [
    {
      name: "Building and Structural Work",
      icon: "🏗️",
      subfields: [
        "Masonry",
        "Concrete Work",
        "Carpentry",
        "Steelwork",
        "Roofing",
      ],
    },
    {
      name: "Installation and Systems",
      icon: "🔧",
      subfields: [
        "Electrical Work",
        "Plumbing",
        "Heating, Ventilation and AC",
        "Wiring and Closures",
        "Insulation",
      ],
    },
    {
      name: "Finishing Work",
      icon: "🎨",
      subfields: [
        "Painting and Decorating",
        "Flooring",
        "Tile Setting",
        "Drywall Installation",
      ],
    },
    {
      name: "Specialized Trades",
      icon: "🛠️",
      subfields: [
        "Welding",
        "Scaffolding",
        "Landscaping",
        "Demolition",
        "Surveying",
      ],
    },
    {
      name: "Equipment Operation and Maintenance",
      icon: "🚜",
      subfields: ["Heavy Equipment Operation", "Equipment Maintenance"],
    },
    {
      name: "Project Management and Supervision",
      icon: "👷",
      subfields: ["Site Management", "Construction Supervision"],
    },
  ];

  const toggleExpand = (fieldName) => {
    setExpanded(expanded === fieldName ? null : fieldName);
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4">Field of Work</h2>
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search Field of Work"
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
      </div>
      <div className="space-y-2">
        {fields.map((field) => (
          <div key={field.name} className="border border-gray-200 rounded-lg">
            <button
              className="w-full flex items-center justify-between p-3 text-left"
              onClick={() => toggleExpand(field.name)}
            >
              <span className="flex items-center">
                <span className="mr-2">{field.icon}</span>
                {field.name}
              </span>
              {expanded === field.name ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </button>
            {expanded === field.name && (
              <div className="px-3 pb-3">
                {field.subfields.map((subfield, index) => (
                  <label
                    key={subfield}
                    className="flex items-center space-x-2 py-1"
                  >
                    <input
                      type="radio"
                      name={`${field.name}-subfield`}
                      value={subfield}
                      defaultChecked={index === 0}
                      className="form-radio text-purple-600"
                    />
                    <span>{subfield}</span>
                  </label>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldOfWork;
