import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { FaSearch, FaCog, FaBell } from "react-icons/fa";
import avatar from "../assets/3d_avatar_26.png";
import sitemasterLogo from "../assets/new logo.png";
import { BASE_URL } from "../utils/constant";

function TopBar() {
  const navigate = useNavigate();
  const [site, setSite] = useState({});
  const alert = useAlert();

  const getSite = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI4NTA2MjgxLCJpYXQiOjE3MjU5MTQyODEsImp0aSI6Ijg5ZTE5ZTEyNjBlZjQyOGFiNmQzNTMyNzlkYTcxZTM4IiwidXNlcl9pZCI6MTZ9.Y0TYgc1p5QTdBQuFYuaphwqeTJNKR5DXI83g67wv1X0";
      const siteId = localStorage.getItem("site");

      if (accessToken && siteId) {
        const response = await fetch(
          `${BASE_URL}/sites/get-a-sites/${siteId}/`,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            }),
          }
        );

        const data = await response.json();
        if (data.status) {
          setSite(data.data);
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  useEffect(() => {
    getSite();
  }, []);

  return (
    <div className="top-bar">
      <div className="flex flex-row items-center justify-start gap-6">
        <div className="logo-container">
          <div
            style={{
              borderRadius: "10px",
              background: "#FFFFFF",
              height: "55px",
              width: "165px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "8px",
              marginRight: "8px",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={sitemasterLogo} alt="logo" width="150px" height="40px" />
          </div>
        </div>
        <div className="project-name">
          {localStorage.getItem("siteName") || "Loading..."}
        </div>
      </div>
      <div className="search-bar">
        <FaSearch className="search-icon" />
        <input type="text" placeholder="Search for something" />
      </div>
      <div className="icons">
        <FaCog className="icon" />
        <FaBell className="icon" />
        <img src={avatar} alt="User Profile" className="user-profile" />
      </div>
      <style jsx>{`
        .top-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff;
          padding: 10px 20px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          height: 60px;
          {/* position: fixed; /* Keep the top bar fixed */ */}
          top: 0;
          width: 100%;
          z-index: 1000; /* Ensure it stays on top of other elements */
        }
        .logo-container {
          display: flex;
          align-items: center;
        }
        .name {
          font-size: 18px;
          font-weight: 600;
          color: #3b0069;
        }
        .project-name {
         
          font-size: 22px;
          font-weight: 600;
          color: #333;
        }
        .search-bar {
          display: flex;
          align-items: center;
          background-color: #fff;
          border-radius: 20px;
          padding: 5px 10px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          max-width: 400px;
          width: 100%;
        }
        .search-icon {
          color: #888;
          margin-right: 10px;
        }
        .search-bar input {
          border: none;
          outline: none;
          width: 100%;
        }
        .icons {
          display: flex;
          align-items: center;
          gap: 20px;
        }
        .icon {
          font-size: 18px;
          color: #666;
          cursor: pointer;
        }
        .user-profile {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}

export default TopBar;
