import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import sitemasterLogo from "../assets/new logo.png";
import { useEffect, useState } from "react";
import { BASE_URL } from "../utils/constant";
import { toast } from "sonner";
const WelcomePage = () => {
  // const location = useLocation();
  // const email = location.state?.email || "User";
  const navigate = useNavigate();
  const [UserData, setUserData] = useState({});

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    try {
      const accessToken = localStorage.getItem("access");
      const res = await fetch(`${BASE_URL}/auth/user-details-of-user/`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
        }),
        redirect: "follow",
      });
      if (!res?.ok) {
        navigate("/login");
      }
      const response = await res.json();
      if (!response.status) {
        navigate("/login");
        //throw new error(response?.message ?? "something went wrong");
      }
      setUserData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const handleGetStarted = () => {
    if (UserData?.profession === "owner") {
      console.log("owner");
      localStorage.setItem("isOwner", "true");
      localStorage.setItem("isIndividual", "false");
      navigate("/");
    } else {
      localStorage.setItem("isOwner", "false");
      localStorage.setItem("isIndividual", "true");
      navigate("/");
    }
  };

  return (
    <div className="flex h-full bg-white">
      {/* Left half - SiteMaster logo */}
      <div className="w-1/2 flex items-center justify-center">
        <img src={sitemasterLogo} alt="SiteMaster Logo" className="w-1/2" />
      </div>

      {/* Right half - Content */}
      <div className="w-1/2 bg-white flex flex-col p-2">
        {/* Top half of right side - Worker image */}
        {/* <div className="h-1/3 bg-white flex items-center justify-center"> */}
        <img
          src="worker.png"
          alt="Construction Workers"
          className=""
          style={{ width: "65%", alignSelf: "center" }}
        />
        {/* </div> */}

        {/* Bottom half of right side - Welcome text and button */}
        <div className="h-1/2 bg-white p-4 flex flex-col justify-center">
          <h2 className="text-2xl font-semibold mb-4">
            Welcome, {UserData?.first_name}
          </h2>
          <p className="text-gray-600 mb-6">
            Your site is all set now, let's create your first site and manage
            your construction with the advanced features of SiteMaster...
          </p>
          <button
            onClick={handleGetStarted}
            style={{ alignSelf: "center" }}
            className="bg-btn-bg text-white px-6 py-2 rounded-lg w-50 flex items-center justify-center hover:bg-purple-800 transition-colors"
          >
            Get Started
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
