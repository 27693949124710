import React, { useState, useEffect } from "react";
import sample_image from "../assets/image.png";
import { PieChart, Pie, Cell } from "recharts";
import TransactionModal from "./AddNewTransaction";
import MaterialPurchaseForm from "./MaterialPurchaseForm";
import WorkersPaymentForm from "./FinanceWorkerPayment";
import OtherPaymentForm from "./OtherPaymentForm";
import RecievedPaymentForm from "./RecievedPaymentForm";
import RefundForm from "./RefundPaymentForm";
import {
  ClipboardList,
  Package,
  Users,
  RefreshCw,
  Clock,
  ChevronLeft,
  ChevronRight,
  Plus,
  X,
} from "lucide-react";
import { BASE_URL } from "utils/constant";
import ReceivedForm from "./RecievedForm";

const COLORS = ["#46237A", "#6B3FA0", "#8F6AC2", "#B394E8", "#D7C6F5"];
const ITEMS_PER_PAGE = 5;

const CATEGORY_NAMES = {
  3: "Material",
  5: "Labour",
  6: "Others",
  7: "Refund",
};
const MaterialPurchaseModal = ({ transaction, onClose }) => {
  if (!transaction) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg h-[70vh] w-full max-w-2xl overflow-y-auto">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Material Purchase Details</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-4">
          {/* Vendor Details */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Vendor/Shop Name</span>
            <div className="text-right">
              <div>{transaction.vendor_name}</div>
              <div className="text-sm text-gray-500">
                {transaction.contact_no}
              </div>
            </div>
          </div>

          {/* Transaction Details */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Date</span>
            <div className="text-right">
              <div>
                {new Date(transaction.date).toLocaleDateString("en-IN", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </div>
            </div>
          </div>

          {/* Materials List */}
          <div className="space-y-2">
            <span className="text-gray-600 block">
              Materials ({transaction.all_materials?.length || 0})
            </span>
            <div className="space-y-3">
              {transaction.all_materials?.map((material) => (
                <div key={material.id} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="font-medium">
                        {material.material_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {material.material_subcategory}
                      </div>
                    </div>
                    <div className="text-right">
                      <div>₹{material.total_price?.toLocaleString()}</div>
                      <div className="text-sm text-gray-500">
                        {material.quantity} {material.unit} × ₹
                        {material.unit_price}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Payment Details */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Payment Method</span>
            <span>{transaction.payment_method}</span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Payment Status</span>
            <span className="capitalize">{transaction.payment_status}</span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Total Amount</span>
            <span className="text-xl font-semibold">
              ₹{transaction.total_price?.toLocaleString()}
            </span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Paid Amount</span>
            <span className="text-green-600">
              ₹{transaction.paid_amount?.toLocaleString()}
            </span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Remaining Amount</span>
            <span className="text-red-600">
              ₹{transaction.remaining_payment?.toLocaleString()}
            </span>
          </div>

          {/* Remarks Section */}
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Remarks</span>
              <button className="p-2 hover:bg-purple-50 rounded-full text-purple-700">
                <Plus size={20} />
              </button>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg space-y-1">
              <div>{transaction.remarks}</div>
            </div>
          </div>

          {/* Receipt Images */}
          {transaction.receipt_image && (
            <div className="space-y-2">
              <span className="text-gray-600 block">Receipt</span>
              <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                <img
                  src={transaction.receipt_image}
                  alt="Receipt"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="flex justify-between p-6 border-t">
          <button className="px-6 py-2 bg-purple-700 text-white rounded-lg hover:bg-purple-800 flex items-center">
            Edit Details
          </button>
          <button className="px-6 py-2 border border-red-600 text-red-600 rounded-lg hover:bg-red-50 flex items-center">
            Delete Transaction Record
          </button>
        </div>
      </div>
    </div>
  );
};
const WorkerTransactionModal = ({ transaction, onClose }) => {
  if (!transaction) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className="bg-white rounded-lg h-[70vh] w-full max-w-2xl"
        style={{
          overflowY: "scroll",
        }}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Workers Payment Details</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-4">
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Receiver</span>
            <div className="text-right">
              <div>{transaction.worker_name}</div>
              <div className="text-sm text-gray-500">
                {transaction.worker_workname}
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Receiver Phone Number</span>
            <span>{transaction?.worker_phone || "+91-9876543210"}</span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Date</span>
            <div className="text-right">
              <div>
                {new Date(transaction.transaction_date).toLocaleDateString(
                  "en-IN",
                  {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                )}
              </div>
              <div className="text-sm text-gray-500">
                {new Date(transaction.transaction_date).toLocaleTimeString(
                  "en-IN",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Payment Amount</span>
            <span className="text-xl font-semibold">
              ₹{transaction.amount?.toLocaleString()}
            </span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Payment Method</span>
            <span>{transaction.transaction_method}</span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Payment Type</span>
            <span>{transaction.payment_type}</span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Total Paid Amount</span>
            <span className="text-green-600">
              ₹{(transaction.amount || 0).toLocaleString()}
            </span>
          </div>

          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Remaining Amount</span>
            <span className="text-red-600">
              ₹{(transaction.remaining_amount || 0).toLocaleString()}
            </span>
          </div>

          {/* Comments Section */}
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Comments/Remarks</span>
              <button className="p-2 hover:bg-purple-50 rounded-full text-purple-700">
                <Plus size={20} />
              </button>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg space-y-1">
              <div>{transaction?.comments}</div>
            </div>
          </div>

          {/* Payment Slips */}
          <div className="space-y-2">
            <span className="text-gray-600 block">Payment Slips</span>
            <div className="flex gap-4">
              {[1, 2, 3].map((_, index) => (
                <div
                  key={index}
                  className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center"
                >
                  <img
                    src={sample_image}
                    alt="Payment slip placeholder"
                    className="w-12 h-12 object-cover rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-between p-6 border-t">
          <button className="px-6 py-2 bg-purple-700 text-white rounded-lg hover:bg-purple-800 flex items-center">
            Edit Details
          </button>
          <button className="px-6 py-2 border border-red-600 text-red-600 rounded-lg hover:bg-red-50 flex items-center">
            Delete Transaction Record
          </button>
        </div>
      </div>
    </div>
  );
};
const RefundTransactionModal = ({ transaction, onClose }) => {
  if (!transaction) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg h-[70vh] w-full max-w-2xl overflow-y-auto">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Refund Details</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-4">
          {/* Transaction Type */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Transaction Type</span>
            <span>{transaction.transaction_type}</span>
          </div>

          {/* Purpose of Refund */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Purpose of Refund</span>
            <span>{transaction.comments}</span>
          </div>

          {/* Date */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Date</span>
            <div className="text-right">
              <div>
                {new Date(transaction.transaction_date).toLocaleDateString(
                  "en-IN",
                  {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                )}
              </div>
              <div className="text-sm text-gray-500">
                {new Date(transaction.created_on).toLocaleTimeString("en-IN", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </div>
          </div>

          {/* Refund Amount */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Refund Amount</span>
            <span className="text-xl font-semibold">
              ₹{transaction.amount?.toLocaleString()}
            </span>
          </div>

          {/* Refund Method */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Refund Method</span>
            <span>{transaction.transaction_method}</span>
          </div>

          {/* Refund Status */}
          <div className="flex justify-between items-center py-3 border-b">
            <span className="text-gray-600">Refund Status</span>
            <span>{transaction.transaction_status}</span>
          </div>

          {/* Comments/Remarks Section */}
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Comments/Remarks</span>
              <button className="p-2 hover:bg-purple-50 rounded-full text-purple-700">
                <Plus size={20} />
              </button>
            </div>

            <div className="space-y-3">
              {/* Latest Comment */}
              <div className="bg-gray-50 p-4 rounded-lg space-y-2">
                <div className="text-sm text-gray-500">
                  {new Date(transaction.created_on).toLocaleTimeString(
                    "en-IN",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                  ,{" "}
                  {new Date(transaction.created_on).toLocaleDateString(
                    "en-IN",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </div>
                <div>{transaction.comments}</div>
              </div>
            </div>
          </div>

          {/* Payment Slips */}
          {transaction.receipt_url && (
            <div className="space-y-2">
              <span className="text-gray-600 block">Payment Slips</span>
              <div className="flex gap-4">
                <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                  <img
                    src={transaction.receipt_url}
                    alt="Payment slip"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="flex justify-between p-6 border-t">
          <button className="px-6 py-2 bg-purple-700 text-white rounded-lg hover:bg-purple-800 flex items-center">
            Edit Details
          </button>
          <button className="px-6 py-2 border border-red-600 text-red-600 rounded-lg hover:bg-red-50 flex items-center">
            Delete Transaction Record
          </button>
        </div>
      </div>
    </div>
  );
};

const RefundStats = ({ transactions }) => {
  // Group refunds by type and calculate totals
  const refundStats = transactions.reduce((acc, transaction) => {
    const type = transaction.transaction_type;
    if (!acc[type]) {
      acc[type] = {
        name: type,
        value: 0,
        amount: 0,
        count: 0,
      };
    }
    acc[type].amount += Math.abs(transaction.amount);
    acc[type].count += 1;
    return acc;
  }, {});

  const chartData = Object.values(refundStats)
    .sort((a, b) => b.amount - a.amount)
    .map((stat) => ({
      ...stat,
      value: Math.round(
        (stat.amount /
          transactions.reduce((sum, t) => sum + Math.abs(t.amount), 0)) *
          100
      ),
    }));

  const COLORS = ["#46237A", "#6B3FA0", "#8F6AC2", "#B394E8", "#D7C6F5"];
  const totalRefundAmount = transactions.reduce(
    (sum, t) => sum + Math.abs(t.amount),
    0
  );

  return (
    <div className="flex items-center justify-between bg-white p-6 rounded-lg mb-6">
      <div className="w-64 h-64 relative">
        <PieChart width={280} height={280}>
          <Pie
            data={chartData}
            cx={125}
            cy={125}
            startAngle={90}
            endAngle={-270}
            innerRadius={85}
            outerRadius={110}
            paddingAngle={2}
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div className="text-xs text-gray-500">Total Refunds</div>
          <div className="text-xl font-semibold text-red-500">
            ₹{totalRefundAmount.toLocaleString()}
          </div>
        </div>
      </div>
      <div className="flex-1 ml-8">
        <h3 className="text-lg font-medium mb-4">Refund Distribution</h3>
        <div className="space-y-3">
          {chartData.map((item, index) => (
            <div key={item.name} className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-3"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className="text-sm">{item.name}</span>
              </div>
              <div className="flex items-center space-x-4">
                <span className="text-sm text-gray-500">
                  {item.count} refunds
                </span>
                <span className="font-medium w-16 text-right">
                  {item.value}%
                </span>
                <span className="text-red-500 w-24 text-right">
                  ₹{item.amount.toLocaleString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const RefundRow = ({ transaction, handleClick }) => {
  return (
    <div
      className="flex items-center p-4 hover:bg-gray-50 border-b"
      onClick={handleClick}
    >
      <div className="flex-shrink-0 w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mr-4">
        <RefreshCw className="text-purple-700" />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-start mb-2">
          <div>
            <span className="font-medium text-red-500">
              -₹{Math.abs(transaction?.amount).toLocaleString()}
            </span>
            <span className="text-gray-500 text-sm ml-2">
              {transaction?.transaction_method}
            </span>
          </div>
          <div className="text-gray-500 text-sm">
            {new Date(transaction?.transaction_date).toLocaleDateString(
              "en-IN",
              {
                day: "numeric",
                month: "short",
                hour: "2-digit",
                minute: "2-digit",
              }
            )}
          </div>
        </div>
        <div className="text-sm text-gray-600">
          Refund Type: {transaction?.transaction_type}
          <span className="ml-2 px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs">
            {transaction?.transaction_status}
          </span>
        </div>
        <div className="text-sm text-gray-500 mt-1">
          Original Transaction #{transaction?.original_transaction_id} • Created
          by {transaction?.created_by}
        </div>
      </div>
    </div>
  );
};

const WorkerRow = ({ transaction, handleClick }) => {
  return (
    <div
      className="flex items-center p-4 hover:bg-gray-50 border-b"
      onClick={handleClick}
    >
      <div className="flex-shrink-0 w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mr-4">
        <Users className="text-purple-700" />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-start mb-2">
          <div>
            <span className="font-medium">
              ₹{transaction?.amount?.toLocaleString()}
            </span>
            <span className="text-gray-500 text-sm ml-2">
              {transaction?.transaction_method}
            </span>
          </div>
          <div className="text-gray-500 text-sm">
            {new Date(transaction?.transaction_date).toLocaleDateString(
              "en-IN",
              {
                day: "numeric",
                month: "short",
                hour: "2-digit",
                minute: "2-digit",
              }
            )}
          </div>
        </div>
        <div className="text-sm text-gray-600">
          Worker: {transaction?.worker_name} ({transaction?.worker_workname})
        </div>
        <div className="text-sm text-gray-500 mt-1">
          {transaction?.payment_type} Payment #{transaction?.id}
        </div>
      </div>
    </div>
  );
};

// Worker stats component
const WorkerStats = ({ transactions }) => {
  // Group workers by category and calculate total payments
  const workerStats = transactions.reduce((acc, transaction) => {
    const category = transaction.worker_workcategory;
    if (!acc[category]) {
      acc[category] = {
        name: category,
        value: 0,
        amount: 0,
      };
    }
    acc[category].amount += transaction.amount;
    return acc;
  }, {});

  const chartData = Object.values(workerStats)
    .sort((a, b) => b.amount - a.amount)
    .map((stat) => ({
      ...stat,
      value: Math.round(
        (stat.amount / transactions.reduce((sum, t) => sum + t.amount, 0)) * 100
      ),
    }));

  const COLORS = ["#46237A", "#6B3FA0", "#8F6AC2", "#B394E8", "#D7C6F5"];

  return (
    <div className="flex items-center justify-between bg-white p-6 rounded-lg mb-6">
      <div className="w-64 h-64 relative">
        <PieChart width={280} height={280}>
          <Pie
            data={chartData}
            cx={125}
            cy={125}
            startAngle={90}
            endAngle={-270}
            innerRadius={85}
            outerRadius={110}
            paddingAngle={2}
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="flex-1 ml-8">
        <h3 className="text-lg font-medium mb-4">
          Worker Payment Distribution
        </h3>
        <div className="space-y-3">
          {chartData.map((item, index) => (
            <div key={item.name} className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-3"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className="text-sm">{item.name}</span>
              </div>
              <span className="font-medium">{item.value}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MaterialRow = ({ transaction, handleClick }) => {
  const totalMaterials = transaction?.all_materials?.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );
  const mainMaterial =
    transaction?.all_materials?.length > 0 ? transaction?.all_materials[0] : {};

  return (
    <div
      className="flex items-center p-4 hover:bg-gray-50 border-b cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex-shrink-0 w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mr-4">
        <Package className="text-purple-700" />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-start mb-2">
          <div>
            <span className="font-medium">
              ₹{transaction?.total_price?.toLocaleString()}
            </span>
            <span className="text-gray-500 text-sm ml-2">
              {transaction?.payment_method}
            </span>
          </div>
          <div className="text-gray-500 text-sm">
            {new Date(transaction?.date).toLocaleDateString("en-IN", {
              day: "numeric",
              month: "short",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>
        <div className="text-sm text-gray-600">
          Material: {mainMaterial?.material_name} (
          {mainMaterial?.material_subcategory}) - {totalMaterials}{" "}
          {mainMaterial?.unit}
        </div>
        <div className="text-sm text-gray-500 mt-1">
          Delivery #{transaction?.id}-{transaction?.transaction_id}
        </div>
      </div>
    </div>
  );
};

const MaterialStats = ({ transactions }) => {
  // Group materials by type and calculate total value
  const materialStats = transactions.reduce((acc, transaction) => {
    transaction?.all_materials?.forEach((material) => {
      const key = material.material_name;
      if (!acc[key]) {
        acc[key] = {
          name: key,
          value: 0,
          amount: 0,
        };
      }
      acc[key].amount += material.total_price;
      acc[key].value += material.quantity;
    });
    return acc;
  }, {});

  const chartData = Object.values(materialStats)
    .sort((a, b) => b.amount - a.amount)
    .map((stat) => ({
      ...stat,
      value: Math.round(
        (stat.amount /
          transactions.reduce((sum, t) => sum + t.total_price, 0)) *
          100
      ),
    }));

  const COLORS = ["#46237A", "#6B3FA0", "#8F6AC2", "#B394E8", "#D7C6F5"];

  return (
    <div className="flex items-center justify-between bg-white p-6 rounded-lg mb-6">
      <div className="w-64 h-64 relative">
        <PieChart width={280} height={280}>
          <Pie
            data={chartData}
            cx={125}
            cy={125}
            startAngle={90}
            endAngle={-270}
            innerRadius={85}
            outerRadius={110}
            paddingAngle={2}
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="flex-1 ml-8">
        <h3 className="text-lg font-medium mb-4">Material Distribution</h3>
        <div className="space-y-3">
          {chartData.map((item, index) => (
            <div key={item.name} className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-3"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span>{item.name}</span>
              </div>
              <span className="font-medium">{item.value}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Finance = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [pieData, setPieData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [showWorkerModal, setShowWorkerModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showOtherModal, setShowOtherModal] = useState(false);
  const [showReceiveModal, setShowReceiveModal] = useState(false);
  const handleCloseModal = () => {
    setSelectedTransaction(null);
  };
  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (transactions.length > 0) {
      calculatePieData();
      filterTransactions();
    }
  }, [transactions, activeTab]);

  // Reset current page when filtered transactions change
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredTransactions.length]);

  const filterTransactions = () => {
    let filtered = [...transactions];

    switch (activeTab) {
      case "material":
        filtered = filtered.filter((t) => t.transaction_catagory_id === 3);
        console.log(filtered);
        break;
      case "workers":
        filtered = filtered.filter((t) => t.transaction_catagory_id === 5);
        console.log(filtered);
        break;
      case "refund":
        filtered = filtered.filter((t) => t.transaction_catagory_id === 7);
        console.log(filtered);
        break;
      case "pending":
        filtered = filtered.filter(
          (t) => t.transaction_catagory_id === 3 && t.remaining_payment > 0
        );
        console.log(filtered);
        break;
      default:
        break;
    }

    setFilteredTransactions(filtered);
    setCurrentPage(1); // Reset to the first page to avoid out-of-sync pages
  };

  // Get total number of pages
  const totalPages = Math.ceil(filteredTransactions.length / ITEMS_PER_PAGE);

  // Ensure current page is within bounds
  const validCurrentPage = Math.min(Math.max(1, currentPage), totalPages);

  // Get current transactions for the page
  const currentTransactions = filteredTransactions.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const calculatePieData = () => {
    const categoryTotals = {
      3: 0,
      5: 0,
      6: 0,
      7: 0,
    };

    transactions.forEach((transaction) => {
      const categoryId = transaction?.transaction_catagory_id;
      const amount = Math.abs(
        transaction?.total_price || transaction?.amount || 0
      );

      if (categoryId in categoryTotals) {
        categoryTotals[categoryId] += amount;
      }
    });

    const total = Object.values(categoryTotals).reduce(
      (sum, amount) => sum + amount,
      0
    );
    setTotalAmount(total);

    const pieData = Object.entries(categoryTotals)
      .filter(([_, amount]) => amount > 0)
      .map(([categoryId, amount]) => ({
        name: CATEGORY_NAMES[categoryId],
        value: Math.round((amount / total) * 100),
        amount: amount,
      }))
      .sort((a, b) => b.value - a.value);

    setPieData(pieData);
  };

  const fetchTransactions = async () => {
    try {
      const site = localStorage.getItem("site");
      const token = localStorage.getItem("access");

      const response = await fetch(
        `${BASE_URL}/finance/get-all-transactions-of-a-site/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sites: site }),
        }
      );

      const data = await response.json();
      if (data.status) {
        setTransactions(data.data);
        setFilteredTransactions(data.data);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString("en-IN", {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchTransactions();
  };

  const handlePageChange = (pageNumber) => {
    // Ensure the page number stays within bounds
    const newPage = Math.min(Math.max(1, pageNumber), totalPages);
    setCurrentPage(newPage);
  };

  // Generate pagination numbers with improved visibility of current range
  // Generate pagination numbers to reflect the correct page range
  const generatePaginationNumbers = () => {
    const pages = [];
    const range = 2; // Number of pages to show around `currentPage`

    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    if (start > 1) {
      pages.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 rounded-lg hover:bg-gray-100"
        >
          1
        </button>
      );
      if (start > 2) pages.push(<span key="start-ellipsis">...</span>);
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-lg ${
            currentPage === i ? "bg-purple-700 text-white" : "hover:bg-gray-100"
          }`}
        >
          {i}
        </button>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) pages.push(<span key="end-ellipsis">...</span>);
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 rounded-lg hover:bg-gray-100"
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };
  // Rest of the component remains the same...

  const handleOpenModal = (transaction) => {
    // switch case
    switch (transaction) {
      case "material":
        setShowMaterialModal(true);
        break;
      case "worker":
        setShowWorkerModal(true);
        break;
      case "refund":
        setShowRefundModal(true);
        break;
      case "other":
        setShowOtherModal(true);
        break;
      case "receive":
        setShowReceiveModal(true);
        break;
      default:
        break;
    }
  };

  if (showMaterialModal) {
    return (
      <MaterialPurchaseForm
        onClose={() => {
          setShowMaterialModal(false);
          fetchTransactions();
        }}
      />
    );
  }

  if (showWorkerModal) {
    return (
      <WorkersPaymentForm
        onClose={() => {
          setShowWorkerModal(false);
          fetchTransactions();
        }}
      />
    );
  }

  if (showRefundModal) {
    return (
      <RefundForm
        onClose={() => {
          setShowRefundModal(false);
          fetchTransactions();
        }}
      />
    );
  }

  if (showOtherModal) {
    return (
      <OtherPaymentForm
        onClose={() => {
          setShowOtherModal(false);
          fetchTransactions();
        }}
      />
    );
  }

  if (showReceiveModal) {
    return (
      <ReceivedForm
        onClose={() => {
          setShowReceiveModal(false);
          fetchTransactions();
        }}
      />
    );
  }

  return (
    <div className="p-6 bg-gray-50 h-full w-full">
      {/* Stats Section */}
      <div className="bg-white rounded-lg p-6 mb-6 shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Transactions Stats</h2>
          <button
            className="p-2 hover:bg-gray-100 rounded-lg"
            onClick={handleRefresh}
          >
            <RefreshCw size={20} className={loading ? "animate-spin" : ""} />
          </button>
        </div>
        {activeTab === "material" ? (
          <MaterialStats transactions={currentTransactions} />
        ) : activeTab === "all" ? (
          <div className="flex items-center justify-between">
            <div className="w-64 h-64 relative">
              <PieChart width={280} height={280}>
                <Pie
                  data={pieData}
                  cx={125}
                  cy={125}
                  innerRadius={60}
                  outerRadius={100}
                  paddingAngle={2}
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div className="text-xs text-gray-500">Today's Transaction</div>
                <div className="text-xl font-semibold">
                  {formatAmount(totalAmount)}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 w-[60%] gap-4">
              {pieData.map((item, index) => (
                <div
                  key={item.name}
                  className="flex items-center border-b-[0.5px] border-gray-400 pb-1"
                >
                  <div
                    className="w-4 h-4 rounded-full mr-3"
                    style={{ backgroundColor: COLORS[index] }}
                  />
                  <span className="flex-1">{item.name}</span>
                  <span className="font-semibold text-right w-24">
                    {item.value}%
                  </span>
                  <span className="text-gray-500 text-sm ml-4 w-32 text-right">
                    {formatAmount(item.amount)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : activeTab === "workers" ? (
          <WorkerStats transactions={currentTransactions} />
        ) : activeTab === "refund" ? (
          <RefundStats transactions={currentTransactions} />
        ) : activeTab === "pending" ? (
          <div></div>
        ) : null}
      </div>

      {/* Transactions Section */}
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Today's Transaction</h2>
          <button
            className="bg-purple-700 text-white px-4 py-2 rounded-lg flex items-center"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <Plus size={20} className="mr-2" />
            Transaction
          </button>
        </div>

        {/* Tabs */}
        <div className="flex space-x-6 mb-6 border-b">
          <button
            onClick={() => setActiveTab("all")}
            className={`flex items-center pb-4 ${
              activeTab === "all"
                ? "border-b-2 border-purple-700 text-purple-700"
                : "text-gray-500"
            }`}
          >
            <ClipboardList size={20} className="mr-2" />
            All Transactions
          </button>
          <button
            onClick={() => setActiveTab("material")}
            className={`flex items-center pb-4 ${
              activeTab === "material"
                ? "border-b-2 border-purple-700 text-purple-700"
                : "text-gray-500"
            }`}
          >
            <Package size={20} className="mr-2" />
            Material
          </button>
          <button
            onClick={() => setActiveTab("workers")}
            className={`flex items-center pb-4 ${
              activeTab === "workers"
                ? "border-b-2 border-purple-700 text-purple-700"
                : "text-gray-500"
            }`}
          >
            <Users size={20} className="mr-2" />
            Workers
          </button>
          <button
            onClick={() => setActiveTab("refund")}
            className={`flex items-center pb-4 ${
              activeTab === "refund"
                ? "border-b-2 border-purple-700 text-purple-700"
                : "text-gray-500"
            }`}
          >
            <RefreshCw size={20} className="mr-2" />
            Refund
          </button>
          <button
            onClick={() => setActiveTab("pending")}
            className={`flex items-center pb-4 ${
              activeTab === "pending"
                ? "border-b-2 border-purple-700 text-purple-700"
                : "text-gray-500"
            }`}
          >
            <Clock size={20} className="mr-2" />
            Pending transaction
          </button>
        </div>

        {/* Table */}
        {activeTab === "material" ? (
          <>
            <div className="space-y-2">
              {currentTransactions.map((transaction) => (
                <MaterialRow
                  key={transaction?.id}
                  transaction={transaction}
                  handleClick={() => {
                    setSelectedTransaction(transaction);
                  }}
                />
              ))}
            </div>
          </>
        ) : activeTab === "all" ? (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left bg-gray-50">
                  <th className="py-3 px-4">Description</th>
                  <th className="py-3 px-4">Transaction ID</th>
                  <th className="py-3 px-4">Type</th>
                  <th className="py-3 px-4">Medium</th>
                  <th className="py-3 px-4">Date</th>
                  <th className="py-3 px-4">Amount</th>
                  <th className="py-3 px-4">Receipt</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      Loading...
                    </td>
                  </tr>
                ) : currentTransactions.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      No transactions found
                    </td>
                  </tr>
                ) : (
                  currentTransactions.map((transaction) => (
                    <tr key={transaction?.id} className="border-b">
                      <td className="py-3 px-4">
                        {transaction?.transaction_type || "Material Purchase"}
                      </td>
                      <td className="py-3 px-4">#{transaction?.id}</td>
                      <td className="py-3 px-4">
                        {transaction?.payment_status ||
                          transaction?.transaction_status}
                      </td>
                      <td className="py-3 px-4">
                        {transaction?.payment_method ||
                          transaction?.transaction_method}
                      </td>
                      <td className="py-3 px-4">
                        {formatDate(
                          transaction?.date || transaction?.transaction_date
                        )}
                      </td>
                      <td className="py-3 px-4">
                        <span
                          className={
                            transaction?.amount < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }
                        >
                          {formatAmount(
                            transaction?.total_price || transaction?.amount
                          )}
                        </span>
                      </td>
                      <td className="py-3 px-4">
                        <button className="text-purple-700 hover:text-purple-900">
                          View
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        ) : activeTab === "workers" ? (
          <>
            <div className="space-y-2">
              {currentTransactions.map((transaction) => (
                <WorkerRow
                  key={transaction?.id}
                  transaction={transaction}
                  handleClick={() => {
                    setSelectedTransaction(transaction);
                  }}
                />
              ))}
            </div>
          </>
        ) : activeTab === "refund" ? (
          <>
            <div className="space-y-2">
              {currentTransactions.map((transaction) => (
                <RefundRow
                  key={transaction?.id}
                  transaction={transaction}
                  handleClick={() => {
                    setSelectedTransaction(transaction);
                  }}
                />
              ))}
            </div>
          </>
        ) : activeTab === "pending" ? (
          <>
            <div className="space-y-2">
              {currentTransactions.map((transaction) => (
                <MaterialRow key={transaction?.id} transaction={transaction} />
              ))}
            </div>
          </>
        ) : null}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-6">
          <div className="text-sm text-gray-500">
            Showing{" "}
            {Math.min(
              filteredTransactions.length,
              (validCurrentPage - 1) * ITEMS_PER_PAGE + 1
            )}{" "}
            to{" "}
            {Math.min(
              validCurrentPage * ITEMS_PER_PAGE,
              filteredTransactions.length
            )}{" "}
            of {filteredTransactions.length} entries
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(validCurrentPage - 1)}
              disabled={validCurrentPage === 1}
              className={`p-2 rounded-lg ${
                validCurrentPage === 1
                  ? "text-gray-300 cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              <ChevronLeft size={20} />
            </button>
            {generatePaginationNumbers()}
            <button
              onClick={() => handlePageChange(validCurrentPage + 1)}
              disabled={validCurrentPage === totalPages}
              className={`p-2 rounded-lg ${
                validCurrentPage === totalPages
                  ? "text-gray-300 cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
        {selectedTransaction &&
          (activeTab === "material" ? (
            <MaterialPurchaseModal
              transaction={selectedTransaction}
              onClose={handleCloseModal} // Pass the close handler here
            />
          ) : activeTab === "workers" ? (
            <WorkerTransactionModal
              transaction={selectedTransaction}
              onClose={handleCloseModal} // Pass the close handler here
            />
          ) : activeTab === "refund" ? (
            <RefundTransactionModal
              transaction={selectedTransaction}
              onClose={handleCloseModal} // Pass the close handler here
            />
          ) : null)}
      </div>

      <TransactionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleClick={handleOpenModal}
      />
    </div>
  );
};

export default Finance;
