import React, { useState } from "react";
import {
  X,
  Calendar,
  Camera,
  Upload,
  MoreVertical,
  ArrowLeft,
} from "lucide-react";

const ContractorsPayment = ({ onClose }) => {
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paymentType, setPaymentType] = useState("Schedule");
  const [paymentDate, setPaymentDate] = useState("Now");

  const workerDetails = {
    name: "Worker's Name",
    field: "Field of work",
    contactNo: "+91-9876543210",
    dailyPayment: 500,
    totalPaymentTillNow: 10000,
    lastPayment: {
      amount: 500,
      date: "04:30 PM, 30 Jun 2023",
    },
  };

  return (
    <div className="bg-white p-6 max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <h1 className="text-xl font-semibold">Contractors Payment</h1>
        </div>
        <MoreVertical className="cursor-pointer" />
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <ArrowLeft className="text-gray-600" size={24} onClick={onClose} />
          <h2 className="text-sm font-semibold">
            Worker/Crew/Employee details
          </h2>
          <button className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full text-xs">
            Change
          </button>
        </div>
        <div className="flex items-center">
          <div className="w-10 h-10 bg-gray-200 rounded-full mr-3"></div>
          <div>
            <p className="font-semibold">{workerDetails.name}</p>
            <p className="text-xs text-gray-500">{workerDetails.field}</p>
            <p className="text-xs text-gray-500">
              Contact No: {workerDetails.contactNo}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-semibold mb-2">Payment Details</h3>
          <div className="text-sm">
            <div className="flex justify-between mb-1">
              <span>Daily Payment</span>
              <span>₹{workerDetails.dailyPayment}/day</span>
            </div>
            <div className="flex justify-between mb-1">
              <span>Total Payment till now</span>
              <span>₹{workerDetails.totalPaymentTillNow}</span>
            </div>
            <div className="flex justify-between text-xs text-gray-500">
              <span>Last Payment</span>
              <span>{workerDetails.lastPayment.date}</span>
              <span>₹{workerDetails.lastPayment.amount}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-4">
            <h3 className="text-sm font-semibold mb-2">Amount</h3>
            <input
              type="number"
              placeholder="₹"
              className="border rounded px-2 py-1 w-full text-right"
            />
          </div>

          <div className="mb-4">
            <h3 className="text-sm font-semibold mb-2">Payment Method</h3>
            <div className="flex space-x-2">
              {["Cash", "UPI/Bank Transfer", "Check"].map((method) => (
                <button
                  key={method}
                  className={`px-3 py-1 rounded-full text-xs ${
                    paymentMethod === method
                      ? "bg-purple-100 text-purple-700"
                      : "bg-gray-100 text-gray-700"
                  }`}
                  onClick={() => setPaymentMethod(method)}
                >
                  {method}
                </button>
              ))}
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-sm font-semibold mb-2">Payment Type</h3>
            <div className="flex space-x-2">
              {["Schedule", "Advance", "Extra/Bonus"].map((type) => (
                <button
                  key={type}
                  className={`px-3 py-1 rounded-full text-xs ${
                    paymentType === type
                      ? "bg-purple-100 text-purple-700"
                      : "bg-gray-100 text-gray-700"
                  }`}
                  onClick={() => setPaymentType(type)}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-semibold mb-2">
          Remarks/ Comments (Optional)
        </h3>
        <textarea
          className="w-full border rounded p-2 text-sm"
          rows="2"
          placeholder="Type"
        ></textarea>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-semibold mb-2">Payment Date</h3>
          <div className="flex items-center space-x-2">
            <button
              className={`px-3 py-1 rounded-full text-xs ${
                paymentDate === "Now"
                  ? "bg-purple-100 text-purple-700"
                  : "bg-gray-100 text-gray-700"
              }`}
              onClick={() => setPaymentDate("Now")}
            >
              Now
            </button>
            <button
              className={`px-3 py-1 rounded-full text-xs flex items-center ${
                paymentDate !== "Now"
                  ? "bg-purple-100 text-purple-700"
                  : "bg-gray-100 text-gray-700"
              }`}
              onClick={() => setPaymentDate("Pick")}
            >
              Pick a later date <Calendar className="ml-1 w-3 h-3" />
            </button>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-semibold mb-2">
            Payment Slip (Optional)
          </h3>
          <div className="flex space-x-2">
            <button className="flex items-center px-3 py-1 bg-purple-100 text-purple-700 rounded-md text-xs">
              <Camera className="mr-1 w-3 h-3" /> Take Photos
            </button>
            <button className="flex items-center px-3 py-1 bg-purple-100 text-purple-700 rounded-md text-xs">
              <Upload className="mr-1 w-3 h-3" /> Upload Photos
            </button>
          </div>
          <div className="mt-2 w-16 h-16 bg-gray-100 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center">
            <span className="text-4xl text-gray-300">+</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorsPayment;
