import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaCog, FaBell } from "react-icons/fa";
import sitemasterLogo from "../assets/new logo.png";
import sampleLogo from "../assets/sample_org.png";

const Header = ({ organization_name }) => {
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();

  const handlePhotoClick = () => {
    setShowLogout(!showLogout);
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    navigate("/login");
  };

  return (
    <>
      {/* Spacer div to prevent content from jumping when header becomes fixed */}
      <div style={styles.spacer}></div>

      <header style={styles.header}>
        <style>
          {`
            .search-box::placeholder {
              color: #b0b0b0;
              opacity: 1;
              padding-left: 30px;
            }
            .logout-menu {
              position: absolute;
              top: 100%;
              right: 0;
              background-color: white;
              box-shadow: 0 2px 10px rgba(0,0,0,0.1);
              border-radius: 8px;
              z-index: 1001;
              margin-top: 8px;
            }
            .logout-button {
              padding: 10px 20px;
              width: 100%;
              text-align: left;
              border: none;
              background: none;
              cursor: pointer;
              color: #4A1985;
              font-size: 14px;
              transition: background-color 0.2s;
            }
            .logout-button:hover {
              background-color: #f5f5f5;
            }
          `}
        </style>
        <div style={styles.logo}>
          <img src={sitemasterLogo} alt="logo" width="150px" height="40px" />
          <div className="flex items-center p-2 gap-6 rounded-lg shadow-md justify-between">
            <p
              className="text-lg font-bold truncate"
              style={{ maxWidth: "450px" }}
              title={organization_name}
            >
              {organization_name?.length > 25
                ? `${organization_name?.slice(0, 25)}...`
                : organization_name}
            </p>
            <img src={sampleLogo} alt="logo" width="40px" height="40px" />
          </div>
        </div>
        <div style={styles.rightSide}>
          <div style={styles.searchBoxContainer}>
            <FaSearch style={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search for something"
              className="search-box"
              style={styles.searchBox}
            />
          </div>
          <div style={styles.iconContainer}>
            <FaCog style={styles.icon} />
          </div>
          <div style={styles.iconContainer}>
            <FaBell style={styles.icon} />
          </div>
          <div style={styles.userIconContainer}>
            <div style={styles.userIcon} onClick={handlePhotoClick}>
              <img src="photo.png" alt="User" style={styles.userImage} />
            </div>
            {showLogout && (
              <div className="logout-menu">
                <button className="logout-button" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

const styles = {
  spacer: {
    height: "72px", // Should match header height
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px 10px 20px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "72px",
    zIndex: 1000
  },
  logo: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
  },
  searchBoxContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderRadius: "22px",
    padding: "3px 7px",
    marginRight: "10px",
    position: "relative",
    width: "220px",
  },
  searchIcon: {
    color: "#b0b0b0",
    position: "absolute",
    left: "20px",
    fontSize: "18px",
  },
  searchBox: {
    padding: "10px",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    width: "100%",
    color: "#4A1985",
    fontSize: "14px",
    paddingLeft: "12px",
    textAlign: "right",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#f5f5f5",
    marginLeft: "20px",
  },
  icon: {
    color: "#b0b0b0",
    fontSize: "20px",
  },
  userIconContainer: {
    position: "relative",
    marginLeft: "30px",
  },
  userIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
    cursor: "pointer",
  },
  userImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }
};

export default Header;
