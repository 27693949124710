import React, { useState } from "react";
import { ChevronLeft, MoreVertical } from "lucide-react";
import WorkerAddressDashboard from "./WorkerAddress";
import AddNewOptions from "./AddNewOptions";

const WorkerDashboard = () => {
  const [selectedTab, setSelectedTab] = useState("Contractor");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);

  const handleAddedClick = () => {
    setShowAddForm(true);
  };
  const handleClosedClick = () => {
    setShowAddForm(false);
  };

  const dummyData = {
    date: "20 June, 2023",
    totalContractor: 5,
    contractorPresentAtSite: 3,
    totalAbsent: 2,
    totalPaymentTillNow: 125000,
    totalPaymentToday: 25000,
    contractors: [
      {
        name: "Contractor leader's Name",
        field: "Field of work details",
        present: 2,
      },
      {
        name: "Contractor leader's Name",
        field: "Field of work details",
        present: 2,
      },
      {
        name: "Contractor leader's Name",
        field: "Field of work details",
        present: 2,
      },
      {
        name: "Contractor leader's Name",
        field: "Field of work details",
        present: 2,
      },
      {
        name: "Contractor leader's Name",
        field: "Field of work details",
        present: 2,
      },
    ],
  };

  const sortedContractors = [...dummyData.contractors].sort((a, b) => {
    return sortOrder === "asc"
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name);
  });

  if (selectedContractor) {
    return (
      <WorkerAddressDashboard
        contractor={selectedContractor}
        onBack={() => setSelectedContractor(null)}
      />
    );
  }
  if (showAddForm) {
    return <AddNewOptions onClose={handleClosedClick} />;
  }

  return (
    <div className="bg-gray-100 p-4 max-w-3xl mx-auto">
      <div className="flex items-center mb-4">
        <ChevronLeft className="mr-2" />
        <h1 className="text-xl font-semibold">Today, {dummyData.date}</h1>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
        <div className="flex justify-between mb-2">
          <button
            className={`font-semibold ${
              selectedTab === "Contractor" ? "text-blue-600" : "text-gray-500"
            }`}
            onClick={() => setSelectedTab("Contractor")}
          >
            Contractor
          </button>
          <button
            className={`font-semibold ${
              selectedTab === "Workers" ? "text-blue-600" : "text-gray-500"
            }`}
            onClick={() => setSelectedTab("Workers")}
          >
            Workers
          </button>
        </div>

        <h2 className="font-semibold mb-2">Today's Attendance</h2>
        <p>Total Contractor: {dummyData.totalContractor}</p>
        <p>Contractor Present at site: {dummyData.contractorPresentAtSite}</p>
        <p>Total Absent: {dummyData.totalAbsent}</p>

        <h2 className="font-semibold mt-4 mb-2">Payment Details</h2>
        <p>Total Payment Till Now: ₹{dummyData.totalPaymentTillNow}</p>
        <p>Total Payment Today: ₹{dummyData.totalPaymentToday}</p>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
        <div className="flex justify-between mb-4">
          <button className="font-semibold text-blue-600">Present</button>
          <button className="font-semibold text-gray-500">Absent</button>
          <button className="font-semibold text-gray-500">Unmarked</button>
        </div>

        <div className="flex items-center mb-4">
          <button
            className={`mr-2 px-3 py-1 ${
              selectedFilter === "All"
                ? "bg-purple-200 text-purple-700"
                : "text-gray-500"
            } rounded-full`}
            onClick={() => setSelectedFilter("All")}
          >
            All
          </button>
          <button
            className={`mr-2 px-3 py-1 ${
              selectedFilter === "Contractor"
                ? "bg-purple-200 text-purple-700"
                : "text-gray-500"
            } rounded-full`}
            onClick={() => setSelectedFilter("Contractor")}
          >
            Contractor
          </button>
          <button
            className={`mr-2 px-3 py-1 ${
              selectedFilter === "Workers"
                ? "bg-purple-200 text-purple-700"
                : "text-gray-500"
            } rounded-full`}
            onClick={() => setSelectedFilter("Workers")}
          >
            Workers
          </button>
          <div className="flex-grow"></div>
          <button
            className="mr-2 text-purple-700"
            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
          >
            Sorted by A-Z {sortOrder === "asc" ? "↓" : "↑"}
          </button>
          <button
            onClick={handleAddedClick}
            className="px-3 py-1 bg-[#3B0069] text-white rounded-md"
          >
            + Worker
          </button>
        </div>

        {sortedContractors.map((contractor, index) => (
          <div
            key={index}
            className="flex items-center justify-between py-2 border-t"
          >
            <div className="flex items-center">
              <div className="w-10 h-10 bg-gray-300 rounded-full mr-3"></div>
              <div>
                <p className="font-semibold">{contractor.name}</p>
                <p className="text-sm text-gray-500">{contractor.field}</p>
              </div>
            </div>
            <div className="flex items-center">
              <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm mr-2">
                {contractor.present} Present
              </span>
              <button
                className="text-blue-600 mr-2"
                onClick={() => setSelectedContractor(contractor)}
              >
                View Profile
              </button>
              <MoreVertical className="text-gray-500" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkerDashboard;
