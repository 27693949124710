import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Upload, Loader2,X } from "lucide-react";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "sonner";
import { BASE_URL } from "utils/constant";

const stepValidationSchemas = {
  0: z.object({
    site_name: z.string().min(2, "Site name is required"),
    site_area: z.number().min(1, "Site area is required"),
    uom: z.enum(["m_sq", "ft_sq", "acre"]),
  }),
  1: z.object({
    local_admin_body_name: z
      .string()
      .min(2, "Local administrative body name is required"),
    local_admin_body_id: z
      .number()
      .min(1, "Local administrative body ID is required"),
    address: z.string().min(5, "Site address is required"),
    city: z.string().min(2, "City is required"),
    pincode: z.string().regex(/^\d{6}$/, "Enter a valid 6-digit pin code"),
    state: z.string().min(2, "State is required"),
  }),
  2: z.object({
    site_catagory_id: z.number().min(1, "Building category is required"),
  }),
  3: z.object({
    start_date: z.string().min(1, "Start date is required"),
    constructionStatus: z.enum(["Ongoing", "Completed", "On Hold"]),
    constructionStage: z.string().optional(),
    image_file: z.instanceof(File).optional(),
  }),
};
const siteSchema = z.object({
  site_name: z.string().min(2, "Site name is required"),
  site_area: z.number().min(1, "Site area is required"),
  uom: z.enum(["m_sq", "ft_sq", "acre"]),
  address: z.string().min(5, "Site address is required"),
  city: z.string().min(2, "City is required"),
  pincode: z.string().regex(/^\d{6}$/, "Enter a valid 6-digit pin code"),
  state: z.string().min(2, "State is required"),
  start_date: z.string().min(1, "Start date is required"),
  constructionStage: z.string().optional(),
  constructionStatus: z.enum(["Ongoing", "Completed", "On Hold"]),
  local_admin_body_name: z
    .string()
    .min(2, "Local administrative body name is required"),
  local_admin_body_id: z
    .number()
    .min(1, "Local administrative body ID is required"),
  site_catagory_id: z.number().min(1, "Building category is required"),
  image_file : z.instanceof(File).optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});

const FormLabel = ({ children, required }) => (
  <label className="block text-sm font-medium text-grey-700 mb-1">
    {children}
    {required && <span className="text-red-500 ml-1">*</span>}
  </label>
);

const FormInput = React.forwardRef(({ error, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 ${
      error ? "border-red-500 focus:ring-red-500" : "border-gray-300"
    } bg-white`}
  />
));

const FormError = ({ children }) =>
  children ? <p className="mt-1 text-sm text-red-500">{children}</p> : null;

const NewSiteForm = ({ onClose }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState(null);
  const token = localStorage.getItem("access");
  const accessToken = token;
  // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMyMzY4OTU5LCJpYXQiOjE3Mjk3NzY5NTksImp0aSI6Ijk2YzBkNzM0MTQyMzQzZjhhNjI4ZmVkNzBiYTYzNTg3IiwidXNlcl9pZCI6MTF9.8jJcYWp3PJBEsCd_FPg4IFdejp1wtkvpz1s9QPDrZRU";
  const organization = localStorage.getItem("organization");
  const organizationId = JSON.parse(organization)?.id;

  const form = useForm({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      site_name: "",
      site_area: 0,
      uom: "m_sq",
      address: "",
      city: "",
      pincode: "",
      state: "",
      start_date: "",
      constructionStage: "",
      constructionStatus: "Ongoing",
      local_admin_body_id: 3, // Added default value
      local_admin_body_name: "",
      site_catagory_id: 2,
      image_url: [],
    },
  });

  const { data: userData } = useQuery({
    queryKey: ["user-details"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BASE_URL}/auth/user-details-of-user/`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return data;
    },
  });
  const createSite = useMutation({
    mutationFn: async (formData) => {
      if (!organizationId) {
        throw new Error("Organization ID is not found");
      }
      setIsSubmitting(true);
      
      try {
        // Create payload as an object instead of FormData
        const payload = {
          organization: Number(organizationId),
          site_name: formData.site_name,
          site_area: Number(formData.site_area) || 0,
          uom: formData.uom,
          address: formData.address,
          city: formData.city,
          pincode: String(formData.pincode),
          state: formData.state,
          start_date: formData.start_date,
          constructionStage: formData.constructionStage || "",
          constructionStatus: formData.constructionStatus,
          local_admin_body_name: String(formData.local_admin_body_name).trim(),
          local_admin_body_id: Number(formData.local_admin_body_id),
          site_catagory_id: Number(formData.site_catagory_id),
          active_flag: true,
        };
  
        // Handle file upload separately
        const formDataPayload = new FormData();
        Object.keys(payload).forEach(key => {
          formDataPayload.append(key, payload[key]);
        });
  
        // Append file if exists
        if (formData.image_file) {
          formDataPayload.append("image_file", formData.image_file);
        }
  
        const { data } = await axios.post(
          `${BASE_URL}/sites/create-sites/`,
          formDataPayload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        return data;
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.detail ||
          error.message ||
          "Failed to create site";
  
        console.log("API Error:", {
          status: error.response?.status,
          data: error.response?.data,
          message: errorMessage,
        });
        throw new Error(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    },
    onSuccess: () => {
      toast.success("Site created successfully");
      onClose();
      navigate("/");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to create site");
    },
  });
  const handleNext = async () => {
    const currentStepFields = Object.keys(
      stepValidationSchemas[currentStep].shape
    );

    const currentStepData = {};
    currentStepFields.forEach((field) => {
      currentStepData[field] = form.getValues(field);
    });
    try {
      await stepValidationSchemas[currentStep].parseAsync(currentStepData);
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      } else {
        const formData = form.getValues();
        if (!formData.local_admin_body_name?.trim()) {
          throw new Error("Local administrative body name is required");
        }

        if (!formData.site_catagory_id) {
          throw new Error("Building category is required");
        }
        createSite.mutate(formData);
      }
    } catch (error) {
      const formattedErrors = {};
      if (error.errors) {
        error.errors.forEach((err) => {
          const field = err.path[0];
          formattedErrors[field] = err.message;
        });
        form.setError(formattedErrors);
      }
    }
  };
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      onClose();
    }
  };
  const handleUploadPhoto = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      // Set form value for image_file
      form.setValue("image_file", file);
      
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    form.setValue("image_file", undefined);
    setPreviewImage(null);
  };

  const renderGeneralDetailsForm = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold mb-4">General Details</h3>

      <div>
        <FormLabel required>Site Name</FormLabel>
        <FormInput
          {...form.register("site_name")}
          placeholder="Enter Site Name"
          error={form.formState.errors.site_name}
        />
        <FormError>{form.formState.errors.site_name?.message}</FormError>
      </div>

      <div className="flex space-x-2">
        <div className="flex-grow">
          <FormLabel required>Site Area</FormLabel>
          <FormInput
            {...form.register("site_area", { valueAsNumber: true })}
            type="number"
            placeholder="Enter Site Area"
            error={form.formState.errors.site_area}
          />
          <FormError>{form.formState.errors.site_area?.message}</FormError>
        </div>

        <div className="w-1/3">
          <FormLabel required>Unit</FormLabel>
          <select
            {...form.register("uom")}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <option value="m_sq">sq m</option>
            <option value="ft_sq">sq ft</option>
            <option value="acre">acre</option>
          </select>
        </div>
      </div>
    </div>
  );
  const renderSiteAddressForm = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold mb-4">Site Address Details</h3>

      <div>
        <FormLabel required>Local Administrative Body Name</FormLabel>
        <FormInput
          {...form.register("local_admin_body_name")}
          placeholder="Enter Local Administrative Body Name"
          error={form.formState.errors.local_admin_body_name}
        />
        <FormError>
          {form.formState.errors.local_admin_body_name?.message}
        </FormError>
      </div>

      <div>
        <FormLabel required>Site Address</FormLabel>
        <FormInput
          {...form.register("address")}
          placeholder="Enter Site Address"
          error={form.formState.errors.address}
        />
        <FormError>{form.formState.errors.address?.message}</FormError>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <FormLabel required>City</FormLabel>
          <FormInput
            {...form.register("city")}
            placeholder="Enter City"
            error={form.formState.errors.city}
          />
          <FormError>{form.formState.errors.city?.message}</FormError>
        </div>

        <div>
          <FormLabel required>Pin Code</FormLabel>
          <FormInput
            {...form.register("pincode")}
            placeholder="Enter Pin Code"
            error={form.formState.errors.pincode}
          />
          <FormError>{form.formState.errors.pincode?.message}</FormError>
        </div>
      </div>

      <div>
        <FormLabel required>State</FormLabel>
        <FormInput
          {...form.register("state")}
          placeholder="Enter State"
          error={form.formState.errors.state}
        />
        <FormError>{form.formState.errors.state?.message}</FormError>
      </div>
    </div>
  );
  const renderBuildingCategorySelector = () => {
    const categories = [
      {
        id: 2,
        name: "Residential Building",
        description: "Apartments,Single-family home...",
      },
      {
        id: 3,
        name: "Commercial Building",
        description: "Office, Retail, Hotel, Industrial...",
      },
      {
        id: 4,
        name: "Institutional Building",
        description: "Medical, Educational, Government",
      },
      {
        id: 5,
        name: "Mixed Use Building",
        description: "More than one use or purpose",
      },
    ];
    return (
      <div className="space-y-4">
        <h3 className="text-lg font-semibold mb-4">Building Category</h3>
        {categories.map((category) => (
          <div
            key={category.id}
            className={`p-4 rounded-lg cursor-pointer transition-colors ${
              form.watch("site_catagory_id") === category.id
                ? "bg-purple-100"
                : "bg-gray-100 hover:bg-gray-200"
            }`}
            onClick={() => form.setValue("site_catagory_id", category.id)}
          >
            <div className="flex justify-between items-center">
              <div>
                <h4 className="font-medium">{category.name}</h4>
                <p className="text-sm text-gray-500">{category.description}</p>
              </div>
              {form.watch("site_catagory_id") === category.id && (
                <div className="w-5 h-5 bg-[#3B0069] rounded-full flex items-center justify-center">
                  <svg
                    className="w-3 h-3 text-white"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
        ))}
        <FormError>{form.formState.errors.site_catagory_id?.message}</FormError>
      </div>
    );
  };
  const renderSiteDetailsForm = () => (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold mb-4">Site Details</h3>
      <div>
        <FormLabel required>Start Date</FormLabel>
        <FormInput
          {...form.register("start_date")}
          type="date"
          error={form.formState.errors.start_date}
        />
        <FormError>{form.formState.errors.start_date?.message}</FormError>
      </div>
      <div>
        <FormLabel required>Construction Status</FormLabel>
        <select
          {...form.register("constructionStatus")}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          <option value="Ongoing">Ongoing</option>
          <option value="Completed">Completed</option>
          <option value="On Hold">On Hold</option>
        </select>
        <FormError>
          {form.formState.errors.constructionStatus?.message}
        </FormError>
      </div>
      <div>
        <FormLabel>Current Stage (Optional)</FormLabel>
        <FormInput
          {...form.register("constructionStage")}
          placeholder="e.g., Foundation work"
          error={form.formState.errors.constructionStage}
        />
      </div>
      <div>
        <FormLabel>Site Photos(Optional)</FormLabel>
        <div className="mt-2 flex items-center justify-center w-full">
          <label className="w-full flex flex-col items-center justify-center px-4 py-6 bg-white text-gray-600 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-50">
            <Upload className="h-10 w-10 text-grey-400" />
            <span className="mt-2 text-sm">Click to upload photos</span>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleUploadPhoto}
            />
          </label>
        </div>
        {previewImage && (
          <div className="relative mt-4">
            <img
              src={previewImage}
              alt="Site preview"
              className="w-full h-48 object-cover rounded-lg"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
            >
              <X size={16} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
  const renderSubmitButton = () => (
    <button
      onClick={handleNext}
      disabled={isSubmitting}
      className="w-full mt-6 bg-[#3B0069] text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-300 flex items-center justify-center"
    >
      {isSubmitting ? (
        <>
          <Loader2 className="animate-spin mr-2" size={20} />
          Submitting...
        </>
      ) : currentStep < 3 ? (
        "Next ->"
      ) : (
        "Submit"
      )}
    </button>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return renderGeneralDetailsForm();
      case 1:
        return renderSiteAddressForm();
      case 2:
        return renderBuildingCategorySelector();
      case 3:
        return renderSiteDetailsForm();
      default:
        return null;
    }
  };
  const handleSubmit = async () => {
    try {
      const formData = form.getValues();
      await siteSchema.parseAsync(formData);
      createSite.mutate(formData);
    } catch (error) {
      console.error("Validation Error:", error);
      if (error.errors) {
        error.errors.forEach((err) => {
          form.setError(err.path[0], {
            type: "manual",
            message: err.message,
          });
        });
      }
    }
  };
  return (
    <div
      className="w-[600px] h-[600px] mx-auto mt-10 bg-white shadow-lg rounded-lg "
      style={{ overflowY: "scroll" }}
    >
      <div className="flex items-center justify-between p-4 border-b">
        <button onClick={handleBack} className="text-gray-600">
          ←
        </button>
        <h2 className="text-xl font-semibold">New Site</h2>
        <button onClick={onClose} className="text-gray-600">
          ×
        </button>
      </div>

      <div className="p-6">
        <div className="w-full bg-purple-200 h-2 mb-6 rounded-full">
          <div
            className="h-2 bg-[#3B0069] rounded-full transition-all duration-300 ease-in-out"
            style={{ width: `${((currentStep + 1) / 4) * 100}%` }}
          ></div>
        </div>

        {renderStepContent()}
        <button
          onClick={currentStep === 3 ? handleSubmit : handleNext}
          disabled={isSubmitting}
          className="w-full mt-6 bg-[#3B0069] text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-300 flex items-center justify-center"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="animate-spin mr-2" size={20} />
              Submitting...
            </>
          ) : currentStep < 3 ? (
            "Next →"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};
export default NewSiteForm;