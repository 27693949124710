import { createSlice } from "@reduxjs/toolkit";

const siteSlice = createSlice({
    name: "site",
    initialState: { site_name: "My Site",site_id:-1 },
    reducers: {
        setSite(state, action) {
            state.site_name = action.payload.site_name;
            state.site_id = action.payload.site_id;
        },
    },
    });

export const siteActions = siteSlice.actions;

export default siteSlice;
