import React, { useState, useEffect } from "react";
import { BASE_URL } from "utils/constant";
import { useNavigate } from "react-router-dom";
import Avatar from "../assets/3d_avatar_26.png";
const MyProjects = ({ filteredSites,organizationId }) => {
  const [sites, setSites] = useState([]);
  const navigate = useNavigate();



  const getSites = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      if (accessToken) {
        const response = await fetch(`${BASE_URL}/sites/organizations/${organizationId}/sites/`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        });
        const data = await response.json();
        if (data) {
          setSites(data);
        }
      }
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };
  useEffect(() => {
    getSites();
  }, [organizationId]);
  return (
    <div className="p-2 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold mb-6">My Projects</h1>
      <div className="space-y-4 w-full mx-auto">
        {(filteredSites.length > 0 ? filteredSites : sites).map(
          (site, index) => (
            <div
              key={index}
              className="bg-white w-full rounded-lg shadow hover:shadow-lg transition-shadow duration-200 cursor-pointer p-2 pr-0"
              onClick={() => {
                localStorage.setItem("site", site.id);
                localStorage.setItem("siteName", site.site_name);
                navigate(`/sitehome`);
              }}
            >
              <div className="flex justify-between items-center">
                <div className="space-y-1">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {site.site_name}
                  </h3>
                  <p className="text-gray-500 text-sm">
                    {site.address},{" "}
                    {site.pincode}
                  </p>
                </div>

                <div className="flex -space-x-3">
                  {[1, 2, 3].map((_, i) => (
                    <div
                      key={i}
                      className="w-8 h-8 rounded-full border-2 border-white ring-1 ring-gray-200"
                    >
                      <img
                        src={Avatar}
                        alt="Team member"
                        className="w-full h-full rounded-full object-cover"
                      />
                    </div>
                  ))}
                  <div className="w-8 h-8 rounded-full border-2 border-white bg-gray-100 flex items-center justify-center text-xs text-gray-500 ring-1 ring-gray-200">
                    +6
                  </div>
                </div>

                <span className="text-blue-600 font-medium align-left px-4">
                  {site.constructionStatus}
                </span>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MyProjects;
