import React from "react";
import { useNavigate } from "react-router-dom";

const AddYourOrganization = () => {
  const navigate = useNavigate();
  const addYourOrganization = () => {
    navigate("/organization-details");
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-md p-8 max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-6 text-center">
          Your Organization / Company Details
        </h2>

        <button
          onClick={addYourOrganization}
          className="w-full bg-purple-100 text-purple-700 font-semibold py-3 px-4 rounded-lg mb-6 flex items-center justify-center"
        >
          <span>Add Your Organization</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 ml-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <p className="text-gray-600 text-center">
          Create an organization to add company members
        </p>
      </div>
    </div>
  );
};

export default AddYourOrganization;
