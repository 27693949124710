import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import base_background from "../assets/Base (1).png";
import main_logo from "../assets/SM_Full_White-100 1 (1).png";
import arrow_forward from "../assets/arrow_forward.png";
import arrow from "../assets/Vector (1).png";
import edit from "../assets/edit.png";
import character1 from "../assets/roles/Character.png";
import character2 from "../assets/roles/character-1.png";
import character3 from "../assets/roles/character-2.png";
import character4 from "../assets/roles/character-3.png";
import character5 from "../assets/roles/Character (1).png";
import character6 from "../assets/roles/character-1 (1).png";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { authActions } from "../store/auth-slice";
export default function Profession() {
  const user_details = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const loginValidationSchema = yup.object().shape({
    Profession: yup.string().required("Profession is Required"),
  });
  const professions = [
    {
      profession: "owner",
      source: character1,
    },
    {
      profession: "Contractor",
      source: character2,
    },
    {
      profession: "Supervisor",
      source: character6,
    },
    {
      profession: "Manager",
      source: character5,
    },
    {
      profession: "Architect",
      source: character3,
    },
    {
      profession: "Engineer",
      source: character3,
    },
    {
      profession: "Vendor",
      source: character4,
    },
    {
      profession: "Employee",
      source: character4,
    },
    {
      profession: "Others",
      source: character4,
    },
  ];
  const handleSignup = (Profession) => {
    console.log("Signin");
    console.log(Profession);
   
  };

  const formik = useFormik({
    initialValues: {
      Profession: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      // handleSignup(values.Profession);
      dispatch(authActions.login({profession: formik.values.Profession}));
      navigate("/userdetails");
    },
  });
  const [selectedProfession, setSelectedProfession] = React.useState(0);
  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={base_background}
          alt=""
          style={{
            height: "140%",
            width: "140%",
            objectFit: "cover",
            position: "relative",
            top: "-50px",
            left: "-50px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={arrow}
          alt=""
          style={{
            height: "115%",
            width: "115%",
            top: "0px",
            left: "-70px",
            objectFit: "contain",
            position: "relative",
            opacity: "0.5",
          }}
        />
      </div>
      <div className="flex flex-col align-middle items-center justify-center w-[50%]">
        <span className="py-5 font-extrabold text-neutral-900 text-2xl">
          <img src={main_logo} alt="" width="90%" />
        </span>
        <span
          className="font-extrabold text-neutral-900 font-medium mt-[-2]"
          style={{
            fontSize: "28px",
            color: "#ffffff",
            fontWeight: 400,
            opacity: 0.8,
            letterSpacing: "0.1em",
            textAlign: "center",
          }}
        >
          Build Smarter,Faster,Better
        </span>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          top: "70px",
          right: "50px",
        }}
      >
        {/* Form */}
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col align-middle justify-between px-10 pb-5"
          style={{ width: "80%" }}
        >
          <div className="flex flex-col">
            {/* Heading and options */}
            <span
              className="py-5 font-extrabold text-purple-800 text-2xl"
              style={{
                color: "#3B0069",
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Create an Account
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: 200,
                opacity: 0.8,
                marginTop: "-12px",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-purple-800"
                style={{ textDecoration: "underline" }}
              >
                Sign in
              </Link>
            </span>
            <span
              className="py-2"
              style={{
                fontSize: "24px",
                color: "#4B5563",
                fontWeight: 700,
                marginTop: "10px",
              }}
            >
              Select Your Profession
            </span>
            <div
              className="flex flex-row gap-4"
              style={{ width: "80%", flexWrap: "wrap",marginTop:"10px" }}
            >
              {professions.map((profession, index) => (
                <div
                  className="flex flex-col items-center"
                  key={index}
                  onClick={() => {
                    formik.setFieldValue("Profession", profession.profession);
                    setSelectedProfession(index);
                  }}
                >
                  <img
                    src={profession.source}
                    alt=""
                    className="w-20 h-20"
                    style={{
                      background:
                        selectedProfession === index ? "#3B006999" : "#6F6F6F",

                      borderRadius: "11px",
                      border:
                        selectedProfession === index
                          ? "1px solid #3B0069"
                          : "none",
                    }}
                  />
                  <span
                    className="text-center text-sm"
                    style={{ fontWeight: "400", color: "#232323",fontSize:"12px" }}
                  >
                    {profession.profession}
                  </span>
                </div>
              ))}
            </div>
            {/* Error message */}
            {formik.touched.Profession && formik.errors.Profession && (
              <span
                style={{
                  fontSize: 10,
                  color: "red",
                  position: "absolute",
                  bottom: "-13px",
                  left: "2px",
                }}
              >
                {formik.errors.Profession}
              </span>
            )}
          </div>
          {/* Submit button */}
          <div className="w-full mt-14" style={{ width: "80%" }}>
            <button
              type="submit"
              className={`py-3 px-8 rounded-lg w-full justify-center items-center flex flex-row gap-4 text-center ${
                formik.isValid ? "bg-purple-950" : "bg-gray-400"
              }`}
              disabled={!formik.isValid}
            >
              <span className="text-white text-lg">Next</span>
              <img
                src={arrow_forward}
                alt=""
                className="w-6 h-6"
                style={{ background: "#58267F", borderRadius: "50%" }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
