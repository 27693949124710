import React, { useState } from "react";
import { Calendar, X, ChevronDown, MoreVertical, Check, ArrowLeft, MoreHorizontal } from "lucide-react";

const SelectModal = ({ title, children, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
      <div className="flex justify-between items-center p-4 border-b">
        <X className="text-gray-400 cursor-pointer" onClick={onClose} />
        <h2 className="text-lg font-semibold">{title}</h2>
        <MoreVertical className="text-gray-400" />
      </div>
      <div className="p-6">{children}</div>
    </div>
  </div>
);

const ExportFormatButtons = ({ exportFormat, setExportFormat }) => (
  <div className="flex space-x-2">
    <button
      onClick={() => setExportFormat("PDF")}
      className={`px-6 py-2 rounded-full ${
        exportFormat === "PDF"
          ? "bg-purple-100 text-purple-700"
          : "bg-gray-100 text-gray-600"
      }`}
    >
      PDF
    </button>
    <button
      onClick={() => setExportFormat("Excel")}
      className={`px-6 py-2 rounded-full ${
        exportFormat === "Excel"
          ? "bg-purple-100 text-purple-700"
          : "bg-gray-100 text-gray-600"
      }`}
    >
      Excel
    </button>
  </div>
);

const GenerateReportModal = () => {
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("Today");
  const [fromDate, setFromDate] = useState("30/10/2023");
  const [toDate, setToDate] = useState("30/10/2023");
  const [activeModal, setActiveModal] = useState(null);
  const [exportFormat, setExportFormat] = useState("PDF");

  const sections = [
    [
      {
        id: "site-updates",
        label: "Site Updates",
        description: "See site updates at your project",
        icon: "🔄"
      },
      {
        id: "site-photos",
        label: "Site Photos",
        description: "See site photo at your project",
        icon: "📷"
      },
      {
        id: "payments",
        label: "Payments & Transactions",
        description: "Manage payment & transaction for your report across project",
        icon: "💰"
      }
    ],
    [
      {
        id: "attendance",
        label: "Attendance",
        description: "View attendance of your report across project",
        icon: "👥"
      },
      {
        id: "inventory",
        label: "Material Inventory",
        description: "See what's material you have in your inventory",
        icon: "📦"
      },
      {
        id: "deliveries",
        label: "Material Deliveries",
        description: "Manage deliveries in your material report across project",
        icon: "🚚"
      }
    ],
    [
      {
        id: "task-management",
        label: "Task Management",
        description: "Manage tasks, view user reports across project.",
        icon: "📋"
      }
    ]
  ];

  const SectionCard = ({ section }) => (
    <div 
      onClick={() => setActiveModal(section.id)}
      className="bg-white shadow-sm hover:shadow-md transition-shadow rounded-lg w-[280px] cursor-pointer"
    >
      <div className="p-4">
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <span className="text-xl">{section.icon}</span>
              <h4 className="font-medium text-gray-800">{section.label}</h4>
            </div>
            <p className="text-sm text-gray-500 pr-4">{section.description}</p>
          </div>
          <ArrowLeft className="transform rotate-180 text-gray-400" size={20} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-[90vh] w-full bg-gray-50 p-6" style={{
      overflowY: "auto"
    }}>
      <div className="w-full mx-auto space-y-6">
        {/* Header */}
        <div className="bg-white shadow-sm rounded-lg p-4 flex justify-between items-center">
          <ArrowLeft className="text-gray-600 cursor-pointer" size={20} />
          <h2 className="text-lg font-semibold">Generate Report</h2>
          <MoreHorizontal className="text-gray-600 cursor-pointer" size={20} />
        </div>

        {/* Time Period Selection */}
        <div className="bg-white shadow-sm rounded-lg p-6 space-y-4">
          <div>
            <h3 className="text-sm font-medium mb-3">Select Time Period</h3>
            <div className="flex gap-2 mb-4">
              {["Today", "7 Days", "1 Month", "Specific"].map((period) => (
                <button
                  key={period}
                  onClick={() => setSelectedTimePeriod(period)}
                  className={`px-4 py-1.5 rounded-full text-sm ${
                    selectedTimePeriod === period
                      ? "bg-purple-100 text-purple-700"
                      : "bg-gray-100 text-gray-600"
                  }`}
                >
                  {period}
                </button>
              ))}
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            {["From", "To"].map((label) => (
              <div key={label}>
                <label className="block text-sm text-gray-600 mb-1">{label}</label>
                <div className="relative">
                  <input
                    type="text"
                    value="30/10/2023"
                    className="w-full p-2 pr-10 border rounded-lg"
                    readOnly
                  />
                  <Calendar className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Report Sections */}
        <div className="bg-white shadow-sm rounded-lg p-4 mb-4">
          <h3 className="text-lg font-semibold text-center">Report Sections</h3>
        </div>

        <div className="space-y-4">
          {sections.map((row, idx) => (
            <div key={idx} className="flex flex-wrap justify-center gap-6">
              {row.map((section) => (
                <SectionCard key={section.id} section={section} />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Modals */}
      {activeModal && (
        <SelectModal 
          title={sections.flat().find(s => s.id === activeModal)?.label} 
          onClose={() => setActiveModal(null)}
        >
          <div className="space-y-4">
            <div className="h-1 bg-purple-700 rounded-full w-1/3 mb-6"></div>
            
            <div className="space-y-4">
              <h3 className="font-semibold">{sections.flat().find(s => s.id === activeModal)?.label}</h3>
              <p className="text-sm text-gray-500">Add details about the project report</p>
              
              <div>
                <label className="block text-sm font-medium mb-2">Report Type</label>
                <div className="relative">
                  <select className="w-full p-3 border rounded-md appearance-none bg-purple-50 text-purple-700 pr-10">
                    <option>All details</option>
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-purple-700" />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Export Format</label>
                <ExportFormatButtons exportFormat={exportFormat} setExportFormat={setExportFormat} />
              </div>
            </div>

            <button className="w-full bg-purple-700 text-white py-2.5 rounded-md font-semibold mt-6">
              Save
            </button>
          </div>
        </SelectModal>
      )}
    </div>
  );
};

export default GenerateReportModal;