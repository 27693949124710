import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import base_background from "../assets/Base (1).png";
import main_logo from "../assets/SM_Full_White-100 1 (1).png";
import arrow_forward from "../assets/arrow_forward.png";
import arrow from "../assets/Vector (1).png";
import { Link } from "react-router-dom";
import passwordIcon from "../assets/visibility_lock (1).png";
import * as yup from "yup";
import { useAlert } from "react-alert";
import { BASE_URL } from "../utils/constant";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";

export default function Login() {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();

  // Check if user is already logged in
  useEffect(() => {
    const token = localStorage.getItem("access");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  async function resendOtp(email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}/auth/resendotp/`,
        requestOptions
      );
      const result = await response.json();
      if (!result?.status) {
        console.log("err", result);
        alert.error(result?.message || "Failed to resend OTP");
      }
    } catch (error) {
      console.log("error", error);
      alert.error(error?.message || "Failed to resend OTP");
    }
  }
  async function create_token(email, password) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${BASE_URL}/token/create/`, requestOptions);
      const result = await response.json();
      if (result?.access) {
        localStorage.setItem("access", result.access);
        localStorage.setItem("refresh", result.refresh);
        dispatch(
          authActions.login({
            email: formik.values.email,
            password: formik.values.password,
          })
        );
        navigate("/welcome");
      } else {
        alert.error(result?.message || "Failed to create token");
      }
    } catch (error) {
      console.error("error", error);
      alert.error(error?.message || "Failed to connect to server");
    }
  }
  async function logIn(email, password) {
    setSpinner(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email.toLowerCase(),
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${BASE_URL}/auth/login/`, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log("Parsed Result:", result);

      if (result?.status) {
        create_token(email.toLowerCase(), password);
      } else {
        if (result.message?.toLowerCase().includes("not verified")) {
          await resendOtp(email.toLowerCase());
          navigate("/otp-screen");
          dispatch(
            authActions.login({
              email: formik.values.email,
              password: formik.values.password,
            })
          );
        } else if (
          result.message?.toLowerCase().includes("invalid credentials")
        ) {
          alert.error("Invalid Credentials");
        } else {
          alert.error(result?.message || "Login failed");
        }
      }
    } catch (error) {
      console.log("error", error);
      alert.error(error?.message || "Failed to connect to server");
    } finally {
      setSpinner(false);
    }
  }

  const [passwordVisible, setPasswordVisible] = useState(false);
  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    password: yup
      .string()
      .min(5, ({ min }) => `Password must be at least ${min} characters`)
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      await logIn(values.email, values.password);
    },
  });

  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TailSpin
        visible={spinner}
        height="60"
        width="60"
        color="#3B0069"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 100,
        }}
        wrapperClass=""
      />
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={base_background}
          alt=""
          style={{
            height: "140%",
            width: "140%",
            objectFit: "cover",
            position: "relative",
            top: "-50px",
            left: "-50px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={arrow}
          alt=""
          style={{
            height: "115%",
            width: "115%",
            top: "0px",
            left: "-70px",
            objectFit: "contain",
            position: "relative",
            opacity: "0.5",
          }}
        />
      </div>
      <div className="flex flex-col align-middle items-center justify-center w-[50%]">
        <span className="py-5 font-extrabold text-neutral-900 text-2xl">
          <img src={main_logo} alt="" width="90%" />
        </span>
        <span
          className="font-extrabold text-neutral-900  mt-[-2]"
          style={{
            fontSize: "28px",
            color: "#ffffff",
            fontWeight: 400,
            opacity: 0.8,
            letterSpacing: "0.1em",
            textAlign: "center",
          }}
        >
          Build Smarter,Faster,Better
        </span>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          top: "70px",
          right: "50px",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col align-middle justify-between px-10  pb-5"
          style={{ width: "80%" }}
        >
          <div className="flex flex-col">
            <span
              className="py-5 font-extrabold text-purple-800 text-2xl"
              style={{
                color: "#3B0069",
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Login
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: 200,
                opacity: 0.8,
                marginTop: "-12px",
              }}
            >
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="text-purple-800"
                style={{ textDecoration: "underline" }}
              >
                Register
              </Link>
            </span>
            <span
              className="py-2 "
              style={{
                fontSize: "24px",
                color: "#4B5563",
                fontWeight: 700,
                marginTop: "10px",
              }}
            >
              Log In with Email
            </span>

            <span className="pt-3 text-neutral-800 font-medium">Email ID</span>
            <input
              type="email"
              name="email"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your email here"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              style={{ width: "80%" }}
            />
            {formik.touched.email && formik.errors.email && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.email}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">Password</span>
            <div
              style={{
                position: "relative",
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                className="bg-gray-200 rounded p-2 px-8"
                placeholder="Enter password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                style={{ width: "100%" }}
              />
              <img
                src={passwordIcon}
                onClick={() => setPasswordVisible(!passwordVisible)}
                alt="show password"
                style={{
                  position: "absolute",
                  right: 18,
                  top: 14,
                  cursor: "pointer",
                }}
                className="w-4 h-3"
              />
            </div>

            {formik.touched.password && formik.errors.password && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.password}
              </span>
            )}
          </div>
          <span
            style={{
              fontSize: "16px",
              color: "#3B0069",
              fontWeight: 400,
              textDecoration: "underline",
            }}
          >
            Forgot Password?{" "}
            <a href="/signup" className="text-purple-800">
              Reset
            </a>
          </span>
          <div className="w-full mt-14" style={{ width: "80%" }}>
            <button
              type="submit"
              className={`py-3 px-8 rounded-lg w-full justify-center items-center flex flex-row gap-4 text-center ${
                formik.isValid ? "bg-purple-950" : "bg-gray-400"
              }`}
              disabled={!formik.isValid || spinner}
            >
              <span className="font-bold text-white text-lg">
                {spinner ? "Logging in..." : "Log in"}
              </span>

              <img
                src={arrow_forward}
                alt=""
                className="w-6 h-6"
                style={{ background: "#58267F", borderRadius: "50%" }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
