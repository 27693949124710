import React, { useState, useEffect, useRef } from "react";
import { Calendar, Upload, X, Search } from "lucide-react";
import { BASE_URL } from "utils/constant";

export default function RefundForm({ onClose }) {
  const [oldTransaction, setOldTransaction] = useState("");
  const [selectedTransactionAmount, setSelectedTransactionAmount] =
    useState("");
  const [reasonForRefund, setReasonForRefund] = useState("");
  const [refundStatus, setRefundStatus] = useState("Full Refund");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundMethod, setRefundMethod] = useState("Cash");
  const [remarks, setRemarks] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const fetchTransactions = async () => {
    try {
      const site = localStorage.getItem("site");
      const accessToken = localStorage.getItem("access");
      const formData = new FormData();
      formData.append("sites", site);
      const response = await fetch(
        `${BASE_URL}/finance/get-all-transactions-of-a-site/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (data.status) {
        setTransactions(data.data);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const handleTransactionSelect = (transaction) => {
    setOldTransaction(
      `₹${transaction.amount} - ${transaction.transaction_date}`
    );
    setSelectedTransactionAmount(transaction.amount);
    setSelectedTransactionId(transaction.id);
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    if (!selectedTransactionId || !refundAmount) return;

    const site = localStorage.getItem("site");
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const refundData = {
      transaction_type: "Refund",
      transaction_date: formattedDate,
      original_transaction_id: selectedTransactionId,
      amount: parseInt(refundAmount),
      transaction_method: refundMethod,
      transaction_status: refundStatus,
      comments: remarks,
      receipt_url: "",
      transaction_catagory_id: 7,
      site: parseInt(site),
    };

    try {
      const accessToken = localStorage.getItem("access");
      const response = await fetch(
        `${BASE_URL}/finance/create-general-transaction/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(refundData),
        }
      );
      const data = await response.json();
      if (data.status) {
        onClose();
      }
    } catch (error) {
      console.error("Error creating refund:", error);
    }
  };

  const filteredTransactions = transactions.filter((transaction) => {
    if (!transaction) return false;

    const amountString = transaction.amount?.toString() || "";
    const dateString = transaction.transaction_date || "";

    return amountString.includes(searchTerm) || dateString.includes(searchTerm);
  });

  return (
    <>
      <div className="w-full p-6 bg-gray-50">
        {/* Header */}
        <div className="flex justify-between items-center mb-4 bg-white p-4 rounded-lg shadow-sm">
          <button onClick={onClose}>
            <X className="h-5 w-5" />
          </button>
          <h1 className="text-lg font-semibold">Refund</h1>
          <div className="w-5" />
        </div>

        {/* Main Form */}
        <div className="grid grid-cols-2 gap-4">
          {/* First Box: Old Transaction Details and Reason for Refund */}
          <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">
                Old Transaction Details
              </label>
              <button
                className="w-full px-4 py-2 bg-gray-100 rounded-md text-left"
                onClick={() => {
                  setIsModalOpen(true);
                  fetchTransactions();
                }}
              >
                {oldTransaction || "Select Old Transaction"}
              </button>
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">
                Reason for Refund
              </label>
              <input
                type="text"
                className="w-full p-3 bg-white rounded-lg border border-gray-200"
                placeholder="Type"
                value={reasonForRefund}
                onChange={(e) => setReasonForRefund(e.target.value)}
              />
            </div>
          </div>

          {/* Second Box: Refund Date */}
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <label className="block text-sm text-gray-600 mb-1">
              Refund Date
            </label>
            <div className="flex gap-2">
              <button className="px-4 py-2 rounded-full bg-purple-100 text-purple-600 text-sm">
                Now
              </button>
              <button className="px-4 py-2 rounded-full bg-gray-100 text-gray-600 text-sm flex items-center gap-2">
                Pick an older date
                <Calendar className="h-4 w-4" />
              </button>
            </div>
          </div>

          {/* Third Box: Refund Status, Amount, and Method */}
          <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">
                Refund Status
              </label>
              <div className="flex gap-2">
                {["Full Refund", "Partial Refund"].map((status) => (
                  <button
                    key={status}
                    className={`px-4 py-2 rounded-full text-sm ${
                      refundStatus === status
                        ? "bg-purple-100 text-purple-600"
                        : "bg-gray-100 text-gray-600"
                    }`}
                    onClick={() => setRefundStatus(status)}
                  >
                    {status}
                  </button>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm text-gray-600 mb-1">
                ₹ Refund Amount
              </label>
              <input
                type="text"
                className="w-full p-3 bg-white rounded-lg border border-gray-200"
                placeholder="Enter refund amount"
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm text-gray-600 mb-1">
                Refund Method
              </label>
              <div className="flex flex-wrap gap-2">
                {["Cash", "UPI/Bank Transfer", "Cheque"].map((method) => (
                  <button
                    key={method}
                    className={`px-4 py-2 rounded-full text-sm ${
                      refundMethod === method
                        ? "bg-purple-100 text-purple-600"
                        : "bg-gray-100 text-gray-600"
                    }`}
                    onClick={() => setRefundMethod(method)}
                  >
                    {method}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Fourth Box: Remarks and Payment Slip */}
          <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
            <div>
              <input
                type="text"
                className="w-full p-2 bg-transparent border-b border-gray-300"
                placeholder="Remarks / Comments (Optional)"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>

            <div>
              <p className="text-sm text-gray-600 mb-2">
                Payment Slip (Optional)
              </p>
              <div className="flex gap-3">
                <button className="flex-1 p-3 bg-purple-50 text-purple-600 rounded-md">
                  Take Photos
                </button>
                <button className="flex-1 p-3 bg-purple-50 text-purple-600 rounded-md">
                  Upload Photos
                </button>
              </div>
              <div className="grid grid-cols-4 gap-2 mt-4">
                {[1, 2, 3, 4].map((placeholder) => (
                  <div
                    key={placeholder}
                    className="aspect-square bg-gray-100 rounded-md flex items-center justify-center"
                  >
                    <Upload className="h-6 w-6 text-gray-400" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Save Button */}
        <button
          className="w-full mt-6 bg-[#3B0069] text-white py-3 rounded-md font-medium hover:bg-purple-700 transition duration-300 flex items-center justify-center gap-2"
          onClick={handleSave}
        >
          Save
        </button>
      </div>

      {/* Custom Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            ref={modalRef}
            className="bg-white rounded-lg w-[600px] max-h-[80vh] overflow-hidden"
          >
            <div className="p-4 border-b">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Select Transaction</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="p-4">
              <div className="mb-4 relative">
                <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search by amount or date..."
                  className="w-full pl-10 pr-4 py-2 border rounded-md"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="max-h-[400px] overflow-y-auto">
                {filteredTransactions.map((transaction) => (
                  <div
                    key={transaction.id}
                    className="p-3 border-b hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleTransactionSelect(transaction)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium">₹{transaction.amount}</p>
                        <p className="text-sm text-gray-600">
                          {transaction.transaction_date}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm text-gray-600">
                          {transaction.transaction_method}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
