import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { BASE_URL } from 'utils/constant';

const SubtaskDetailModal = ({ isOpen, onClose, subtask, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    subtask_name: '',
    subtask_details: '',
    start_date: '',
    end_date: '',
    assign_to: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Initialize form data when subtask prop changes
  useEffect(() => {
    if (subtask) {
      setFormData({
        subtask_name: subtask.subtask_name || '',
        subtask_details: subtask.subtask_details || '',
        start_date: subtask.start_date || '',
        end_date: subtask.end_date || '',
        assign_to: subtask.assign_to || '',
      });
    }
  }, [subtask]);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      setError('');
      
      const accessToken = localStorage.getItem('access');
      const response = await fetch(`${BASE_URL}/task/update-subtask/${subtask.id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.status) {
        onUpdate?.();
        setIsEditing(false);
      } else {
        setError(data.message || 'Failed to update subtask');
      }
    } catch (err) {
      setError('An error occurred while updating the subtask');
    } finally {
      setLoading(false);
    }
  };

  const handleMarkComplete = async () => {
    try {
      setLoading(true);
      setError('');
      
      const accessToken = localStorage.getItem('access');
      const response = await fetch(`${BASE_URL}/task/update-subtask/${subtask.id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ is_completed: true }),
      });

      const data = await response.json();

      if (data.status) {
        onUpdate?.();
        onClose();
      } else {
        setError(data.message || 'Failed to mark subtask as complete');
      }
    } catch (err) {
      setError('An error occurred while updating the subtask');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    // Ensure form data is up to date before entering edit mode
    setFormData({
      subtask_name: subtask.subtask_name || '',
      subtask_details: subtask.subtask_details || '',
      start_date: subtask.start_date || '',
      end_date: subtask.end_date || '',
      assign_to: subtask.assign_to || '',
    });
    setIsEditing(true);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl p-6 mx-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Sub Task Details</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Subtask Name:
            </label>
            {isEditing ? (
              <input
                type="text"
                name="subtask_name"
                value={formData.subtask_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg"
              />
            ) : (
              <p className="p-2 bg-gray-50 rounded-lg">{subtask?.subtask_name}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Subtask Details:
            </label>
            {isEditing ? (
              <textarea
                name="subtask_details"
                value={formData.subtask_details}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg"
                rows={3}
              />
            ) : (
              <p className="p-2 bg-gray-50 rounded-lg">{subtask?.subtask_details}</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date:
              </label>
              {isEditing ? (
                <input
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-lg"
                />
              ) : (
                <p className="p-2 bg-gray-50 rounded-lg">{subtask?.start_date}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date:
              </label>
              {isEditing ? (
                <input
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-lg"
                />
              ) : (
                <p className="p-2 bg-gray-50 rounded-lg">{subtask?.end_date}</p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Assigned To:
            </label>
            {isEditing ? (
              <input
                type="text"
                name="assign_to"
                value={formData.assign_to}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg"
              />
            ) : (
              <p className="p-2 bg-gray-50 rounded-lg">{subtask?.assign_to}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status:
            </label>
            <p className="p-2 bg-gray-50 rounded-lg">
              {subtask?.is_completed ? 'Completed' : 'Ongoing'}
            </p>
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            {isEditing ? (
              <>
                <button
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 text-gray-600 border rounded-lg"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdate}
                  className="px-4 py-2 bg-[#3B0069] text-white rounded-lg"
                  disabled={loading}
                >
                  {loading ? 'Saving...' : 'Save Changes'}
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleEditClick}
                  className="px-4 py-2 bg-[#3B0069] text-white rounded-lg"
                >
                  Edit Subtask
                </button>
                {!subtask?.is_completed && (
                  <button
                    onClick={handleMarkComplete}
                    className="px-4 py-2 bg-green-600 text-white rounded-lg"
                    disabled={loading}
                  >
                    Mark as Completed
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubtaskDetailModal;