import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PlusCircle,
  Loader2,
  UserCircle2,
  X,
  Upload,
  ChevronRight,
} from "lucide-react";
import { format } from "date-fns";
import axios from "axios";
import { BASE_URL } from "utils/constant";

const SiteUpdateForm = ({ onClose, onSubmit, isSubmitting }) => {
  const [comment, setComment] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [error, setError] = useState("");

  const siteId = localStorage.getItem("site");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!comment.trim()) {
      setError("Comment is required");
      return;
    }

    const formData = new FormData();
    formData.append('comment', comment);
    formData.append('sites', siteId);
    imageFiles.forEach((file, index) => {
      formData.append('imageFiles', file);
    });

    onSubmit(formData);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFiles(prevFiles => [...prevFiles, file]);

      // Create preview URL
      const previewUrl = URL.createObjectURL(file);
      setImagePreviews(prevPreviews => [...prevPreviews, previewUrl]);
    }
  };

  const removeImage = (index) => {
    // Remove file from imageFiles
    setImageFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    
    // Remove preview URL
    setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-4">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">New Site Update</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4">
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-md">
              {error}
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Comment
              </label>
              <textarea
                rows={4}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write your site update here..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Images
              </label>
              <div className="flex flex-wrap gap-2">
                {imagePreviews.map((url, index) => (
                  <div key={index} className="relative">
                    <img
                      src={url}
                      alt={`Upload ${index + 1}`}
                      className="w-16 h-16 object-cover rounded"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    >
                      <X className="w-3 h-3" />
                    </button>
                  </div>
                ))}
                <label className="w-16 h-16 flex items-center justify-center border-2 border-dashed border-gray-300 rounded cursor-pointer hover:border-purple-500">
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <Upload className="w-6 h-6 text-gray-400" />
                </label>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md border"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-[#3B0069] hover:bg-purple-700 rounded-md disabled:opacity-50"
            >
              {isSubmitting ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                "Post Update"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const SiteCommunications = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const queryClient = useQueryClient();
  const siteId = localStorage.getItem("site");
  const accessToken = localStorage.getItem("access");

  const { data: communications = [], isLoading } = useQuery({
    queryKey: ["site-communications"],
    queryFn: async () => {
      const response = await axios.post(
        `${BASE_URL}/sites/list-todays-siteupdate/`,
        { sites: siteId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data || [];
    },
    refetchInterval: 30000,
  });

  const createMutation = useMutation({
    mutationFn: async (formData) => {
      const response = await axios.post(
        `${BASE_URL}/sites/create-siteupdate/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["site-communications"]);
      setIsFormOpen(false);
    },
  });

  const formatTimeAgo = (dateString) => {
    try {
      const date = new Date(dateString);
      const now = new Date();
      const diffInMinutes = Math.floor((now - date) / 1000 / 60);

      if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
      }
      return format(date, "hh:mm a, dd MMM");
    } catch (error) {
      return dateString;
    }
  };

  return (
    <div className="h-full bg-[#f9fafb] flex flex-col">
      {isFormOpen && (
        <SiteUpdateForm
          onClose={() => setIsFormOpen(false)}
          onSubmit={createMutation.mutate}
          isSubmitting={createMutation.isPending}
        />
      )}

      <div className="flex-1 p-4 overflow-hidden flex flex-col">
        <div className="bg-white rounded-lg shadow-sm flex-1 flex flex-col">
          <div className="p-4 flex items-center justify-between border-b border-gray-100">
            <span className="text-sm text-gray-500">Updates Today</span>
            <button
              onClick={() => setIsFormOpen(true)}
              className="w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600 hover:bg-purple-200"
            >
              <PlusCircle className="w-6 h-6" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {isLoading ? (
              <div className="flex justify-center items-center p-4">
                <Loader2 className="w-6 h-6 animate-spin text-purple-600" />
              </div>
            ) : communications.length === 0 ? (
              <div className="text-center p-8 text-gray-500">
                No site updates yet today.
              </div>
            ) : (
              <div className="space-y-4">
                {communications.map((comm, index) => (
                  <div
                    key={comm.id}
                    className={`p-4 ${
                      index !== communications.length - 1 ? "border-b" : ""
                    } border-gray-100 hover:bg-gray-50 transition-colors`}
                  >
                    <div className="flex gap-3">
                      <UserCircle2 className="w-8 h-8 text-gray-400 flex-shrink-0" />
                      <div className="flex-1 min-w-0">
                        <p className="text-sm text-gray-600 break-words">
                          {comm.comment}
                        </p>
                        {comm.imageUrls?.length > 0 && (
                          <div className="flex gap-2 mt-2 overflow-x-auto">
                            {comm.imageUrls.slice(0, 2).map((url, index) => (
                              <img
                                key={index}
                                src={url}
                                alt={`Communication ${index + 1}`}
                                className="w-16 h-16 object-cover rounded-lg flex-shrink-0"
                              />
                            ))}
                            {comm.imageUrls.length > 2 && (
                              <div className="w-16 h-16 bg-gray-200 rounded-lg flex items-center justify-center text-sm font-medium text-gray-600 flex-shrink-0">
                                +{comm.imageUrls.length - 2}
                              </div>
                            )}
                          </div>
                        )}
                        <div className="text-xs text-gray-400 mt-1">
                          {formatTimeAgo(comm.created_on)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteCommunications;