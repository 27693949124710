import React, { useState, useEffect } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { BASE_URL } from 'utils/constant';

const UpdateStockModal = ({ isOpen, onClose, taskId, onSuccess }) => {
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [updateDate, setUpdateDate] = useState(new Date().toISOString().split('T')[0]);
  const [comments, setComments] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchMaterials();
    }
  }, [isOpen, taskId]);

  const fetchMaterials = async () => {
    try {
      const accessToken = localStorage.getItem('access');
      const response = await fetch(`${BASE_URL}/task/get-task-materials/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ task_id: taskId }),
      });
      const data = await response.json();
      if (data.status) {
        setMaterials(data.data);
      }
    } catch (err) {
      setError('Failed to fetch materials');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!selectedMaterial) {
      setError('Please select a material');
      return;
    }

    const numQuantity = parseFloat(quantity);
    const availableQuantity = selectedMaterial.estimated_quantity - selectedMaterial.total_used;

    if (numQuantity <= 0) {
      setError('Please enter a valid quantity');
      return;
    }

    if (numQuantity > availableQuantity) {
      setError(`Cannot use more than available quantity (${availableQuantity})`);
      return;
    }

    try {
      setLoading(true);
      const accessToken = localStorage.getItem('access');
      const response = await fetch(`${BASE_URL}/inventory/create-stock/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          update_date: updateDate,
          entered_quantity: -numQuantity, // Negative for used material
          comment: comments,
          material: selectedMaterial.material_id,
          task_id: taskId,
        }),
      });

      const data = await response.json();
      if (data.status) {
        onSuccess?.();
        onClose();
      } else {
        setError(data.message || 'Failed to update stock');
      }
    } catch (err) {
      setError('An error occurred while updating stock');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Stock Update</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md flex items-center gap-2">
              <AlertCircle className="h-4 w-4" />
              <span>{error}</span>
            </div>
          )}

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Materials</label>
            <select
              className="w-full p-2 border rounded-md"
              onChange={(e) => setSelectedMaterial(materials.find(m => m.id === parseInt(e.target.value)))}
              value={selectedMaterial?.id || ''}
            >
              <option value="">Select Material</option>
              {materials.map((material) => (
                <option key={material.id} value={material.id}>
                  {material.material_name} - {material.specification} 
                  ({material.estimated_quantity - material.total_used} available)
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Update Date</label>
            <input
              type="date"
              className="w-full p-2 border rounded-md"
              value={updateDate}
              onChange={(e) => setUpdateDate(e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Enter Amount</label>
            <input
              type="number"
              className="w-full p-2 border rounded-md"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter quantity used"
              min="0"
              step="0.01"
            />
            {selectedMaterial && (
              <p className="text-sm text-gray-500">
                Available: {selectedMaterial.estimated_quantity - selectedMaterial.total_used}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Remarks/Comments</label>
            <textarea
              className="w-full p-2 border rounded-md"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Add any comments..."
              rows={3}
            />
          </div>

          <button
            type="submit"
            className="w-full bg-[#3B0069] text-white py-2 rounded-md hover:bg-purple-700 disabled:opacity-50"
            disabled={loading}
          >
            {loading ? 'Updating...' : 'Update'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateStockModal;