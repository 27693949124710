import React, { useState, useMemo } from "react";

const DeliveryStatusFilter = ({ deliveries = [], onFilterChange }) => {
  const [activeStatus, setActiveStatus] = useState("Upcoming");

  const filteredDeliveries = useMemo(() => {
    return deliveries.filter((delivery) => {
      switch (activeStatus) {
        case "Upcoming":
          return delivery.transaction_id === 1;
        case "Delivered":
          return delivery.transaction_id === 2;
        case "Cancelled":
          return delivery.transaction_id === 3;
        default:
          return true;
      }
    });
  }, [deliveries, activeStatus]);

  const deliveryStats = useMemo(() => {
    const upcoming = deliveries.filter((d) => d.transaction_id === 1).length;
    return `${upcoming} upcoming deliveries`;
  }, [deliveries]);

  const handleStatusChange = (status) => {
    setActiveStatus(status);
    onFilterChange(filteredDeliveries);
  };

  return (
    <div className="w-full space-y-4">
      {/* Status Tabs */}
      <div className="flex space-x-4 border-b border-gray-200">
        {["Upcoming", "Delivered", "Cancelled"].map((status) => (
          <button
            key={status}
            onClick={() => handleStatusChange(status)}
            className={`pb-2 px-4 relative ${
              activeStatus === status
                ? "text-purple-700 font-medium"
                : "text-gray-500"
            }`}
          >
            {status}
            {activeStatus === status && (
              <div className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-700" />
            )}
          </button>
        ))}
      </div>

      {/* Delivery Count */}
      {activeStatus === "Upcoming" && (
        <div className="text-gray-600 text-sm">{deliveryStats}</div>
      )}
    </div>
  );
};

export default DeliveryStatusFilter;
