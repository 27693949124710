import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import base_background from "../assets/Base (1).png";
import main_logo from "../assets/SM_Full_White-100 1 (1).png";
import arrow_forward from "../assets/arrow_forward.png";
import arrow from "../assets/Vector (1).png";
import passwordIcon from "../assets/visibility_lock (1).png";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { BASE_URL } from "../utils/constant";
export default function UserDetails() {
  const alert = useAlert();
  const loginValidationSchema = yup.object().shape({
    first_name: yup.string().required("First Name is Required"),
    last_name: yup.string().required("Last Name is Required"),
    // phone should only contain digits and be 10 digits
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Phone Number is Required"),
  });
  const user_details = useSelector((state) => state.auth);
  const navigate = useNavigate();
  async function AddUserDetails(firstName, lastName, phone) {
    const lang = "English";

    let accessToken = localStorage.getItem("access");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      contact_number: phone,
      first_name: firstName,
      last_name: lastName,
      language: lang,
      profession: user_details.profession,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (accessToken) {
      try {
        const response = await fetch(
          `${BASE_URL}/auth/create-userdetails/`,
          requestOptions
        );
        const result = await response.json();

        // console.log(result);

        if (result?.status) {
          navigate("/useraddress");
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        // Toast.error(error?.message);
        alert.error(error?.message);
      }
    }
  }

  const handleSignup = (first_name, last_name) => {
    console.log("Signin");
    console.log(first_name);
    console.log(last_name);
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      //   handleSignup(values.first_name, values.last_name);
      //   navigate("/useraddress");
      AddUserDetails(values.first_name, values.last_name, values.phone);
    },
  });
  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={base_background}
          alt=""
          style={{
            height: "140%",
            width: "140%",
            objectFit: "cover",
            position: "relative",
            top: "-50px",
            left: "-50px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={arrow}
          alt=""
          style={{
            height: "115%",
            width: "115%",
            top: "0px",
            left: "-70px",
            objectFit: "contain",
            position: "relative",
            opacity: "0.5",
          }}
        />
      </div>
      <div className="flex flex-col align-middle items-center justify-center w-[50%]">
        <span className="py-5 font-extrabold text-neutral-900 text-2xl">
          <img src={main_logo} alt="" width="90%" />
        </span>
        <span
          className="font-extrabold text-neutral-900 font-medium mt-[-2]"
          style={{
            fontSize: "28px",
            color: "#ffffff",
            fontWeight: 400,
            opacity: 0.8,
            letterSpacing: "0.1em",
            textAlign: "center",
          }}
        >
          Build Smarter,Faster,Better
        </span>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          top: "70px",
          /* margin-top: auto; */
          right: "50px",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col align-middle justify-between px-10  pb-5"
          style={{ width: "80%" }}
        >
          <div className="flex flex-col">
            <span
              className="py-5 font-extrabold text-purple-800 text-2xl"
              style={{
                color: "#3B0069",
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Create an Account
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: 200,
                opacity: 0.8,
                marginTop: "-12px",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-purple-800"
                style={{ textDecoration: "underline" }}
              >
                Sign in
              </Link>
            </span>
            <span
              className="py-2 "
              style={{
                fontSize: "24px",
                color: "#4B5563",
                fontWeight: 700,
                marginTop: "10px",
              }}
            >
              Sign Up with Email
            </span>

            <span className="pt-3 text-neutral-800 font-medium">
              First Name
            </span>
            <input
              type="text"
              name="first_name"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              style={{ width: "80%" }}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.first_name}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">Last Name</span>
            <input
              type="text"
              name="last_name"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              style={{ width: "80%" }}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.last_name}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">
              Phone Number
            </span>
            <div className="flex flex-row gap-4" style={{ width: "80%" }}>
              {/* Static input for "IND" */}
              <input
                type="text"
                className="bg-gray-200 rounded p-2"
                disabled={true}
                style={{
                  width: "23%",

                  placeholderColor: "black",
                  fontWeight: "500",
                }}
                placeholder="IND (+91)"
              />
              {/* Input for phone number */}
              <input
                type="text"
                name="phone"
                className="bg-gray-200 rounded p-2 px-8"
                placeholder="Enter your phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                style={{
                  width: "77%",
                  fontSize: formik.values.phone.length > 0 ? "20px" : "16px",
                  color: "black",
                  fontWeight: formik.values.phone.length > 0 ? "500" : "300",
                  letterSpacing: "1px",
                }}
              />
              {/* Prefix for phone number */}
            </div>

            {formik.touched.phone && formik.errors.phone && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.phone}
              </span>
            )}
            <div
              className="flex flex-row gap-4"
              style={{
                width: "80%",
                marginTop: "20px",
                alignContent: "center",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <input
                type="checkbox"
                name="notification_check"
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  border: "1px solid #58267F",
                  alignSelf: "center",
                }}
              />
              <label
                for="notification_check"
                style={{ fontSize: "12px", fontWeight: "600" }}
              >
                {" "}
                Receive notifications, alerts, daily reports, updates and more
                in your WhatsApp inbox.
              </label>
            </div>
          </div>

          <div className="w-full mt-14" style={{ width: "80%" }}>
            <button
              type="submit"
              className={`py-3 px-8 rounded-lg w-full justify-center items-center flex flex-row gap-4 text-center ${
                formik.isValid ? "bg-purple-950" : "bg-gray-400"
              }`}
              disabled={!formik.isValid}
              onClick={() => {
                // dispatch(authActions.login({ email: formik.values.email,password:formik.values.password }));
                console.log("Logged in");

                // navigate("/otp-screen");
              }}
            >
              <span className="font-bold text-white text-lg">Next</span>

              {/* Replace the icon component here */}

              <img
                src={arrow_forward}
                alt=""
                className="w-6 h-6"
                style={{ background: "#58267F", borderRadius: "50%" }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
