import React, { useState, useEffect } from "react";
import { Search, Plus, X } from "lucide-react";
import MemberDetails from "./MemberDetails"; // Ensure this component is available
import avatar from "../assets/3d_avatar_26.png";
import { toast } from "sonner";
import { BASE_URL } from "utils/constant";

const AddNewMember = ({ onClose, organizationId }) => {
  const [emailInput, setEmailInput] = useState("");
  const [addedMembers, setAddedMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  async function getUserRole() {
    try {
      // console.log("getUserRole");
      const accessToken = localStorage.getItem("access");

      if (accessToken) {
        const response = await fetch(`${BASE_URL}/auth/user-details-of-user/`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        });

        const data = await response.json();
        // console.log(data);
        if (data?.status) {
          // console.log(data?.data?.profession);
          setUserData(data?.data);
          // setIsOwner(data?.data?.profession == "owner");
        } else {
          return;
        }
      }
    } catch (error) {
      toast.error(error?.message);
      // console.log("h2", error);
      // navigation.navigate("LoginScreen");
    }
  }

  useEffect(() => {
    getUserRole();
  }, []);

  const getOrganizationMembers = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      if (accessToken && organizationId) {
        const response = await fetch(
          `${BASE_URL}/org/organization/${organizationId}/members/`,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            }),
          }
        );
        const data = await response.json();
        if (data.status) {
          console.log("Organization members:", data.data);
          setAddedMembers(data.data);
        }
      }
    } catch (error) {
      console.log("Error fetching organization members:", error);
    }
  };
  useEffect(() => {
    getOrganizationMembers();
  }, [organizationId]);

  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      const accessToken = localStorage.getItem("access");
      if (!accessToken) {
        toast.error("Access token not found");
        setLoading(false);
        return;
      }

      const response = await fetch(`${BASE_URL}/auth/search-users/`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
      });

      const result = await response.json();
      if (!result?.status) {
        toast.error(result?.message || "Failed to fetch users");
        setLoading(false);
        return;
      }

      setAllUsers(result?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const addMemberToOrganization = async (user) => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("access");
      if (!accessToken) {
        toast.error("Access token not found");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${BASE_URL}/org/organization/${organizationId}/add-member/`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
          body: JSON.stringify({
            organization: organizationId,
            user_id: user?.user_id,
            is_admin: false,
            member_control: false,
            joined_as: "member",
            created_by: userData?.first_name,
            last_updated_by: userData?.first_name,
          }),
        }
      );

      const result = await response.json();
      if (!result?.status) {
        toast.error(result?.message || "Failed to add member");
        setLoading(false);
        return;
      }

      // setAddedMembers([...addedMembers, user]);
      getOrganizationMembers();
      setEmailInput("");
      setFilteredUsers([]);
      toast.success("Member added successfully");
      onclose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add member");
    }
  };

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
    const filtered = allUsers.filter(
      (user) => user.user_email.toLowerCase() === e.target.value.toLowerCase()
    );
    setFilteredUsers(filtered);
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className="w-[650px] h-[400px] mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="flex items-center justify-between p-4 border-b">
        <h2 className="text-xl font-semibold">Add New Member</h2>
        <button onClick={onClose} className="text-gray-600">
          <X size={24} />
        </button>
      </div>
      <div className="flex-1 p-4 flex">
        <div className="w-1/2 pr-2 border-r">
          <h3 className="text-sm font-medium mb-2">Find With Email ID</h3>
          <div className="relative mb-4">
            <Search
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={16}
            />
            <input
              type="text"
              placeholder="Add Mail ID"
              value={emailInput}
              onChange={handleInputChange}
              className="w-full pl-8 pr-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-purple-600"
            />
          </div>
          {filteredUsers.length > 0 && (
            <ul className="space-y-2">
              {filteredUsers.map((user, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between bg-gray-100 rounded-md p-2 cursor-pointer"
                >
                  <div className="flex items-center">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="w-6 h-6 rounded-full mr-2"
                    />
                    <span className="text-xs truncate">{user.user_email}</span>
                  </div>
                  <button
                    onClick={() => addMemberToOrganization(user)}
                    className="bg-gray-200 text-gray-700 rounded-md p-1 hover:bg-gray-300"
                  >
                    <Plus size={16} />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-1/2 pl-2">
          <h3 className="text-sm font-medium mb-2">Added Members</h3>
          <ul className="space-y-2 overflow-y-auto h-[200px]">
            {addedMembers.map((member, index) => (
              <li
                key={index}
                className="flex items-center justify-between bg-gray-100 rounded-md p-1"
              >
                <div className="flex items-center">
                  <img
                    src={avatar}
                    alt="Avatar"
                    className="w-6 h-6 rounded-full mr-2"
                  />
                  <div className="" style={{width:"80%"}}>
                    <p className="text-xs font-medium">
                      {member.user?.first_name}
                    </p>
                    <p className="text-xs text-gray-600 truncate">
                      {member.user?.email}
                    </p>
                  </div>
                </div>
                <button className="bg-[#3B0069] text-white text-xs font-bold px-2 py-1 rounded-full">
                  Added
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
};

export default AddNewMember;
