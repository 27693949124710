import React, { useState, useEffect, useCallback } from "react";
import {
  FaSearch,
  FaPlus,
  FaChevronDown,
  FaFilter,
  FaStore,
  FaUser,
  FaEllipsisH,
} from "react-icons/fa";
import Button from "./MaterialButton";
import Input from "./MaterialInput";
import Card from "./MaterialCard";
import { BASE_URL } from "utils/constant";
import VendorShopManagement from "./VendorShopManagement";
import InventoryItemDetails from "./InventoryItemDetails";
import NewMaterialForm from "./NewMaterialForm";
import DeliveryCard from "./DeliveryCard";
import VendorProfile from "./VendorProfile";
import DeliveryStatusFilter from "./DeliveryStatusFilter";
import MaterialPurchaseForm from "./MaterialPurchaseForm";
import MaterialPurchaseDetails from "./MaterialPurchaseDetails";
import NewVendorModal from "./NewVendor";
const InventoryItem = ({ item, onClick }) => (
  <div
    className="flex items-center justify-between p-4 border-b cursor-pointer hover:bg-gray-50"
    onClick={() => onClick(item)}
  >
    <div className="flex items-center space-x-4 w-1/3">
      <img
        src="marbles.png"
        alt={item.material_name}
        className="w-8 h-8 rounded-full"
      />
      <h3 className="font-semibold">{item.material_name}</h3>
    </div>
    <div className="flex items-center justify-between w-2/3">
      <div className="text-left w-1/3">
        <p className="text-sm text-gray-500">In stock:</p>
        <p className="font-semibold">
          {item.current_stock} {item.unit}
        </p>
      </div>
      <div className="flex items-center space-x-4">
        <div className="text-right">
          <p className="text-sm text-gray-500">
            Updated {formatDate(item.last_updated_on)}
          </p>
          <p
            className={`text-sm font-semibold ${
              item.added_stock > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {item.added_stock > 0 ? "+" : ""}
            {item.added_stock} {item.unit}
          </p>
        </div>
        <button className="bg-purple-100 text-purple-600 px-4 py-2 rounded-md hover:bg-purple-200 text-sm">
          Update
        </button>
      </div>
    </div>
  </div>
);

const VendorItem = ({ vendor, onClick }) => (
  <div
    className="w-full p-4 mb-2 bg-white rounded-lg shadow-sm border border-gray-200 cursor-pointer hover:bg-gray-50"
    onClick={() => onClick?.(vendor)}
  >
    <div className="flex items-center pb-2 border-b border-gray-200">
      <div className="p-2 bg-gray-100 rounded-full mr-3">
        {vendor.vendor_type === "Shop" ? (
          <FaStore size={28} className="text-purple-700" />
        ) : (
          <FaUser size={28} className="text-purple-700" />
        )}
      </div>

      <div className="flex-grow">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <h3 className="text-base font-semibold">{vendor.name}</h3>
            <span className="bg-gray-100 px-2 py-0.5 rounded-full text-xs font-medium text-gray-600">
              {vendor.vendor_type}
            </span>
          </div>
          <button className="hover:bg-gray-100 p-1 rounded-full">
            <FaEllipsisH size={20} className="text-gray-700" />
          </button>
        </div>
        <p className="text-sm font-medium text-gray-600 mt-0.5">
          +91- {vendor.contact_no}
        </p>
      </div>
    </div>

    <div className="flex justify-between items-center mt-2">
      <div>
        <p className="text-xs font-medium text-gray-600">
          Last Purchase/Delivery
        </p>
        <p className="text-xs text-gray-600">
          {formatDate(vendor.last_updated_on)}
        </p>
      </div>
      <div>
        <p className="text-xs font-medium text-gray-600">Upcoming Delivery</p>
        <p className="text-xs text-gray-600">
          {formatDate(vendor.last_updated_on)}
        </p>
      </div>
    </div>
  </div>
);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};

const Inventory = () => {
  const [currentTab, setCurrentTab] = useState("inventory");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [deliveryItems, setDeliveryItems] = useState([]);
  const [vendorItems, setVendorItems] = useState([]);
  const [machineryItems, setMachineryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [deliveryFilter, setDeliveryFilter] = useState("all");
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [showNewMaterialForm, setShowNewMaterialForm] = useState(false);
  const [showNewDeliveryForm, setShowNewDeliveryForm] = useState(false);
  const [showMaterialPurchaseDetails, setShowMaterialPurchaseDetails] =
    useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const itemsPerPage = 5;

  const fetchData = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access");
      const site = localStorage.getItem("site");

      const endpoints = {
        inventory: "get-materials-of-a-site",
        "Purchases & Deliveries": "get-deliveries-of-a-site",
        vendors: "list-site-vendors",
        machineries: "get-machineries-of-a-site",
      };

      const url = `${BASE_URL}/inventory/${endpoints[currentTab]}/`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken.trim()}`,
        },
        body: JSON.stringify({ sites: site }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        switch (currentTab) {
          case "inventory":
            setInventoryItems(data.data);
            break;
          case "Purchases & Deliveries":
            setDeliveryItems(data.data);
            setFilteredDeliveries(data.data);
            break;
          case "vendors":
            setVendorItems(data.data);
            break;
          case "machineries":
            setMachineryItems(data.data);
            break;
        }
      }
    } catch (error) {
      console.error(`Error fetching ${currentTab} data:`, error);
    }
  }, [currentTab]);

  useEffect(() => {
    fetchData();
    setCurrentPage(1);
  }, [fetchData, currentTab]);

  const handleFilterChange = useCallback((filteredItems) => {
    setFilteredDeliveries(filteredItems);
    setCurrentPage(1);
  }, []);

  const getCurrentItems = () => {
    switch (currentTab) {
      case "inventory":
        return inventoryItems;
      case "Purchases & Deliveries":
        return filteredDeliveries;
      case "vendors":
        return vendorItems;
      case "machineries":
        return machineryItems;
      default:
        return [];
    }
  };

  const renderItems = () => {
    const items = getCurrentItems();
    const paginatedItems = paginateItems(items);

    switch (currentTab) {
      case "inventory":
        return paginatedItems.map((item) => (
          <InventoryItem key={item.id} item={item} onClick={handleItemClick} />
        ));
      case "Purchases & Deliveries":
        return paginatedItems.map((delivery) => (
          <DeliveryCard
            key={delivery.id}
            delivery={delivery}
            onClick={handleDeliveryClick}
          />
        ));
      case "vendors":
        return paginatedItems.map((vendor) => (
          <VendorItem
            key={vendor.id}
            vendor={vendor}
            onClick={handleVendorClick}
          />
        ));
      default:
        return null;
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
  };

  const handleDeliveryClick = (delivery) => {
    console.log("Delivery clicked:", delivery);
    setSelectedDelivery(delivery);
    setShowMaterialPurchaseDetails(true);
  };

  const paginateItems = (itemsToDisplay) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return itemsToDisplay.slice(startIndex, endIndex);
  };

  if (showMaterialPurchaseDetails) {
    return (
      <MaterialPurchaseDetails
        delivery={selectedDelivery}
        onClose={() => setShowMaterialPurchaseDetails(false)}
      />
    );
  }
  if (selectedItem) {
    return (
      <div className="bg-gray-100 w-full h-full flex flex-col p-4">
        <InventoryItemDetails
          item={selectedItem}
          onBack={() => setSelectedItem(null)}
        />
      </div>
    );
  }

  if (selectedVendor) {
    return (
      <div className="bg-gray-100 w-full h-full flex flex-col p-4">
        <VendorProfile
          vendor={selectedVendor}
          onBack={() => setSelectedVendor(null)}
        />
      </div>
    );
  }

  if (showNewDeliveryForm) {
    return (
      <MaterialPurchaseForm onClose={() => {
        setShowNewDeliveryForm(false);
        fetchData();
      }} />
    );
  }

  return (
    <div className="bg-gray-100 w-full  flex flex-col p-4">
      <ul className="flex mb-4 border-b">
        {["inventory", "Purchases & Deliveries", "vendors", "machineries"].map(
          (tab) => (
            <li
              key={tab}
              className={`mr-8 cursor-pointer font-bold pb-2 ${
                currentTab === tab ? "border-b-2 border-blue-600" : ""
              }`}
              style={{
                color: currentTab === tab ? "#1814F3" : "#718EBF",
              }}
              onClick={() => setCurrentTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </li>
          )
        )}
      </ul>
      {currentTab === "Purchases & Deliveries" && (
        <div className="mb-4">
          <DeliveryStatusFilter
            deliveries={deliveryItems}
            onFilterChange={handleFilterChange}
          />
        </div>
      )}
      <div className="flex justify-end items-center space-x-4 mb-4">
        <div className="relative">
          <button className="flex items-center space-x-2 px-4 py-2 bg-white rounded-lg shadow-sm">
            <span className="text-gray-600">Sorted by Recent</span>
            <FaChevronDown className="text-gray-400" />
          </button>
        </div>

        <button className="p-2 bg-white rounded-lg shadow-sm">
          <FaFilter className="text-gray-600" />
        </button>

        <button
          className="flex items-center space-x-2 px-4 py-2 bg-[#3B0069] text-white rounded-lg"
          onClick={() => {
            if (currentTab === "inventory") {
              setShowNewMaterialForm(true);
            } else if (currentTab === "Purchases & Deliveries") {
              setShowNewDeliveryForm(true);
            } else if (currentTab === "vendors") {
              setIsVendorModalOpen(true);
            }
          }}
        >
          <FaPlus />
          <span>
            {currentTab === "inventory"
              ? "New Material"
              : currentTab === "Purchases & Deliveries"
              ? "New Delivery"
              : currentTab === "vendors"
              ? "New Vendor"
              : "New Machinery"}
          </span>
        </button>
      </div>

      <div className="flex-1 overflow-y-auto">
        <Card>{renderItems()}</Card>
      </div>

      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-purple-500 text-white py-2 px-4 rounded-md"
        >
          Previous
        </Button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-purple-500 text-white py-2 px-4 rounded-md"
        >
          Next
        </Button>
      </div>

      {showNewMaterialForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <NewMaterialForm
            onClose={() => setShowNewMaterialForm(false)}
            // onSubmit={async (formData) => {
            //   try {
            //     // Handle form submission
            //     await handleNewMaterialSubmit(formData);
            //     setShowNewMaterialForm(false);
            //     // Refresh inventory items
            //     fetchData();
            //   } catch (error) {
            //     console.error("Error submitting new material:", error);
            //   }
            // }}
          />
        </div>
      )}

      <NewVendorModal
        isOpen={isVendorModalOpen}
        onClose={() => setIsVendorModalOpen(false)}
      />
    </div>
  );
};

export default Inventory;
