import { React, useState } from "react";
import PaymentDetailsForm from "./PaymentDetailsForm";
import FieldOfWork from "./PaymentDetailsForm";

const NewWorkerForm = () => {
  const [addNext, setNext] = useState(false);

  const clickOnNext = () => {
    setNext(true);
  };
  const closeOnNext = () => {
    setNext(false);
  };
  if (addNext) {
    return <FieldOfWork onClose={closeOnNext} />;
  }

  return (
    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center p-4 mt-4">
      <div className="bg-white p-6 w-full max-w-lg mx-auto rounded-lg shadow-lg">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold text-gray-700 mt-6 flex items-center justify-center">
            New Worker
          </h2>
          <button className="text-gray-500 hover:text-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Progress Bar */}
        <div className="w-full bg-[#3B0069] h-1.5 mb-6 rounded-full"></div>

        {/* Form */}
        <form className="space-y-6">
          <div>
            <h3 className="text-sm font-medium mb-1 text-gray-700">
              General Details
            </h3>
            <p className="text-xs text-gray-500 mb-4">
              add Name, Field of Work, Address, Contact Number
            </p>
          </div>

          <div className="space-y-4">
            {/* Worker's Name */}
            <div className="space-y-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Worker's Name
              </label>
              <input
                id="name"
                type="text"
                placeholder="Type"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              />
            </div>

            {/* Field of Work */}
            <div className="space-y-2">
              <label
                htmlFor="field"
                className="block text-sm font-medium text-gray-700"
              >
                Field of Work
              </label>
              <select
                id="field"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              >
                <option value="">Select Field of Work</option>
                <option value="construction">Construction</option>
                <option value="plumbing">Plumbing</option>
                <option value="electrical">Electrical</option>
                <option value="carpentry">Carpentry</option>
              </select>
            </div>

            {/* Worker's Contact Number */}
            <div className="space-y-2">
              <label
                htmlFor="contact"
                className="block text-sm font-medium text-gray-700"
              >
                Worker's Contact number
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                  +91
                </span>
                <input
                  id="contact"
                  type="tel"
                  placeholder="98700 00000"
                  className="flex-1 block rounded-r-md border-gray-300 focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
                />
              </div>
            </div>

            {/* Address */}
            <div className="space-y-2">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                Address (Optional)
              </label>
              <input
                id="address"
                type="text"
                placeholder="Type"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            onClick={clickOnNext}
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#3B0069] hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewWorkerForm;
