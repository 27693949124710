import React, { useState } from 'react';
import { X, Camera, Upload } from 'lucide-react';
import { BASE_URL } from 'utils/constant';

const CreateTransactionModal = ({ isOpen, onClose, taskId, onSuccess }) => {
  const [formData, setFormData] = useState({
    transaction_type: '',
    transaction_date: new Date().toISOString().split('T')[0],
    amount: '',
    transaction_method: 'Cash',
    comments: '',
    receipt_url: '',
  });

  const transactionTypes = [
    { id: 'equipment', label: 'Equipments' },
    { id: 'electricity', label: 'Electricity' },
    { id: 'transport', label: 'Transport' },
    { id: 'municipality', label: 'Municipality/Panchayat' },
    { id: 'water', label: 'Water Bill' },
  ];

  const paymentMethods = [
    { id: 'Cash', label: 'Cash' },
    { id: 'UPI/Bank Transfer', label: 'UPI/Bank Transfer' },
    { id: 'Cheque', label: 'Cheque' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const accessToken = localStorage.getItem('access');
      const siteId = localStorage.getItem('site');
      const response = await fetch(`${BASE_URL}/finance/create-general-transaction/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          task_id: taskId,
          site: siteId,
          transaction_type: formData.transaction_type,
          transaction_date: formData.transaction_date,
          amount: Number(formData.amount),
          transaction_method: formData.transaction_method,
          transaction_status: 'paid',
          comments: formData.comments,
          receipt_url: formData.receipt_url,
          transaction_catagory_id: 6, // for other payment
        }),
      });

      const data = await response.json();
      
      if (data.status) {
        onSuccess?.();
        onClose();
      } else {
        alert(data.message || 'Failed to create transaction');
      }
    } catch (error) {
      alert('An error occurred while creating the transaction');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" style={{
        height: '100vh',
    }}>
      <div className="bg-white rounded-lg w-full max-w-2xl h-[80%] mx-4 flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Make Payment</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto px-6" style={{
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgb(107 114 128 / 0.2) transparent',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgb(107 114 128 / 0.2)',
            borderRadius: '20px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          }
        }}>
          <form onSubmit={handleSubmit} className="py-6 space-y-6">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Purpose of Transaction
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded-lg bg-gray-50"
                placeholder="Type"
                value={formData.transaction_type}
                onChange={(e) => setFormData({...formData, transaction_type: e.target.value})}
              />
            </div>

            <div>
              <p className="text-sm text-gray-600 mb-2">Or pick one of these</p>
              <div className="flex flex-wrap gap-2">
                {transactionTypes.map((type) => (
                  <button
                    key={type.id}
                    type="button"
                    className={`px-4 py-2 rounded-full text-sm ${
                      formData.transaction_type === type.id
                        ? 'bg-purple-100 text-purple-700 border-purple-300'
                        : 'bg-gray-100 text-gray-700 border-gray-300'
                    } border`}
                    onClick={() => setFormData({...formData, transaction_type: type.id})}
                  >
                    {type.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Payment Date
                </label>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className={`px-4 py-2 rounded-full text-sm ${
                      formData.transaction_date === new Date().toISOString().split('T')[0]
                        ? 'bg-purple-100 text-purple-700 border-purple-300'
                        : 'bg-gray-100 text-gray-700 border-gray-300'
                    } border`}
                    onClick={() => setFormData({
                      ...formData,
                      transaction_date: new Date().toISOString().split('T')[0]
                    })}
                  >
                    Now
                  </button>
                  <input
                    type="date"
                    className="flex-1 p-2 border rounded-lg bg-gray-50"
                    value={formData.transaction_date}
                    onChange={(e) => setFormData({...formData, transaction_date: e.target.value})}
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Amount
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-2.5">₹</span>
                  <input
                    type="number"
                    className="w-full p-2 pl-8 border rounded-lg bg-gray-50"
                    placeholder="Amount"
                    value={formData.amount}
                    onChange={(e) => setFormData({...formData, amount: e.target.value})}
                  />
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Payment Method
              </label>
              <div className="flex gap-2">
                {paymentMethods.map((method) => (
                  <button
                    key={method.id}
                    type="button"
                    className={`px-4 py-2 rounded-full text-sm ${
                      formData.transaction_method === method.id
                        ? 'bg-purple-100 text-purple-700 border-purple-300'
                        : 'bg-gray-100 text-gray-700 border-gray-300'
                    } border`}
                    onClick={() => setFormData({...formData, transaction_method: method.id})}
                  >
                    {method.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Remarks/Comments (Optional)
              </label>
              <textarea
                className="w-full p-2 border rounded-lg bg-gray-50"
                rows="3"
                value={formData.comments}
                onChange={(e) => setFormData({...formData, comments: e.target.value})}
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Payment Slip (Optional)
              </label>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  className="flex items-center justify-center gap-2 p-3 border rounded-lg bg-purple-50 text-purple-700"
                >
                  <Camera className="w-5 h-5" />
                  Take Photos
                </button>
                <button
                  type="button"
                  className="flex items-center justify-center gap-2 p-3 border rounded-lg bg-gray-50 text-gray-700"
                >
                  <Upload className="w-5 h-5" />
                  Upload Photos
                </button>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-purple-700 text-white py-3 rounded-lg hover:bg-purple-800 transition-colors"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTransactionModal;