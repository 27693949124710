import React, { useState, useEffect } from "react";
import { Pencil, Clock } from "lucide-react";
import { BASE_URL } from "utils/constant";

const OrganizationDetails = () => {
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  const fetchOrganizationDetails = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      const organization = localStorage.getItem("organization");
      const organizationId = JSON.parse(organization)?.id;

      if (!accessToken) {
        throw new Error("Missing access token or organization ID");
      }

      const response = await fetch(
        `${BASE_URL}/org/get-a-organization/${organizationId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const result = await response.json();

      if (result.status) {
        setOrganization(result.data);
      } else {
        throw new Error(
          result.message || "Failed to fetch organization details"
        );
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return <div className="p-4">Loading organization details...</div>;
  if (error) return <div className="p-4 text-red-500">Error: {error}</div>;

  return (
    <div className="p-6 max-w-5xl mx-auto space-y-6">
      {/* Top Card */}
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <div className="flex items-start gap-6">
          <div className="w-24 h-24 bg-orange-50 rounded-lg border border-orange-200 flex items-center justify-center">
            {organization?.logo_image ? (
              <img
                src={organization.logo_image}
                alt="Organization Logo"
                className="w-20 h-20 object-contain"
              />
            ) : (
              <div className="text-2xl font-bold text-orange-600">
                {organization?.organization_name?.charAt(0)}
              </div>
            )}
          </div>

          <div className="flex-1 space-y-3">
            <div className="flex items-center gap-3">
              <h2 className="text-xl font-bold">
                {organization?.organization_name}
              </h2>
              <span className="px-2 py-1 text-sm bg-green-600 text-white rounded-full">
                Active
              </span>
            </div>

            <div className="flex items-center gap-2 text-gray-600">
              <Clock className="w-4 h-4" />
              <span>Validitity: 30 Nov, 2025</span>
            </div>

            <div className="text-gray-600">Total Seat : 3 out of 5 Used</div>
          </div>
        </div>
      </div>

      {/* Organization Details Title */}
      <h3 className="text-lg font-semibold">Organization Details</h3>

      {/* Details Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <DetailBox
          label="Organization Name"
          value={organization?.organization_name}
        />
        <DetailBox
          label="Business Address"
          value={organization?.business_address}
        />
        <DetailBox label="Pin Code" value={organization?.pincode} />
        <DetailBox label="State" value={organization?.state} />
        <DetailBox
          label="Business Contact Number"
          value={organization?.contact_number}
        />
        <DetailBox
          label="Organization Website Link"
          value={organization?.website}
        />
      </div>
    </div>
  );
};

const DetailBox = ({ label, value }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm">
    <div className="flex flex-col space-y-2">
      <span className="text-sm text-gray-600">{label}</span>
      <div className="flex items-center justify-between">
        <span className="font-medium">{value || "-"}</span>
        <button className="p-1.5 hover:bg-gray-100 rounded-full">
          <Pencil className="w-4 h-4 text-gray-400" />
        </button>
      </div>
    </div>
  </div>
);

export default OrganizationDetails;
