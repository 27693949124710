import React, { useState, useEffect } from "react";
import { Calendar, Upload, X, Search, Plus, Trash } from "lucide-react";
import axios from "axios";
import { BASE_URL } from "utils/constant";
const MaterialPurchaseForm = ({ onClose }) => {
  const [deliveryStatus, setDeliveryStatus] = useState("Upcoming");
  const [paymentStatus, setPaymentStatus] = useState("Part Payment");
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [materials, setMaterials] = useState([]);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedMaterials, setSelectedMaterials] = useState([
    { material_id: "", quantity: "", unit_price: "", total_price: 0 },
    { material_id: "", quantity: "", unit_price: "", total_price: 0 },
  ]);
  const [newMaterial, setNewMaterial] = useState({
    material_name: "",
    specification: "",
    subcategory: "",
    uom: "",
    current_stock: 0,
  });

  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);

  const fetchVendors = async () => {
    try {
      const siteId = localStorage.getItem("site");
      const response = await axios.post(
        `${BASE_URL}/inventory/list-site-vendors/`,
        {
          sites: siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      setVendors(response.data.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Vendor selection handler
  const handleVendorSelect = (vendor) => {
    setSelectedVendor(vendor);
    setShowVendorModal(false);
  };

  const fetchMaterials = async () => {
    try {
      const siteId = localStorage.getItem("site");
      const token = localStorage.getItem("access");

      const response = await axios.post(
        `${BASE_URL}/inventory/get-materials-of-a-site/`,
        { sites: siteId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAvailableMaterials(response.data.data);
    } catch (error) {
      console.error("Error fetching materials:", error);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  const handleCreateMaterial = async () => {
    try {
      const token = localStorage.getItem("access");
      const siteId = localStorage.getItem("site");

      await axios.post(
        `${BASE_URL}/inventory/create-material/`,
        {
          ...newMaterial,
          sites: siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchMaterials();
      setShowNewMaterialModal(false);
      setNewMaterial({
        material_name: "",
        specification: "",
        subcategory: "",
        uom: "",
        current_stock: 0,
      });
    } catch (error) {
      console.error("Error creating material:", error);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("access");
      const siteId = localStorage.getItem("site");

      const validMaterials = selectedMaterials.filter(
        (m) => m.material_id && m.quantity && m.unit_price
      );
      const totalPrice = validMaterials.reduce(
        (sum, m) => sum + m.total_price,
        0
      );
      const remainingPayment = totalPrice - Number(paidAmount);

      const payload = {
        date: deliveryDate,
        total_price: totalPrice,
        payment_method: paymentMethod,
        payment_status: paymentStatus.toLowerCase().replace(" ", "-"),
        paid_amount: Number(paidAmount),
        receipt_url: "",
        remaining_payment: remainingPayment,
        remarks,
        transaction_catagory_id: 3,
        transaction_id: 1,
        sites: Number(siteId),
        vendor: selectedVendor.id,
        all_materials: validMaterials,
      };

      await axios.post(`${BASE_URL}/inventory/create-delivery/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      onClose();
    } catch (error) {
      console.error("Error saving delivery:", error);
    }
  };

  const handleMaterialSelect = (materialId, index) => {
    const material = availableMaterials.find((m) => m.id === materialId);
    const updatedMaterials = [...selectedMaterials];
    updatedMaterials[index] = {
      ...updatedMaterials[index],
      material_id: materialId,
      material_name: material.material_name,
      uom: material.uom,
    };
    setSelectedMaterials(updatedMaterials);
    setShowMaterialModal(false);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedMaterials = [...selectedMaterials];
    updatedMaterials[index] = {
      ...updatedMaterials[index],
      quantity: Number(quantity),
      total_price:
        Number(quantity) * Number(updatedMaterials[index].unit_price || 0),
    };
    setSelectedMaterials(updatedMaterials);
  };

  const handleUnitPriceChange = (index, price) => {
    const updatedMaterials = [...selectedMaterials];
    updatedMaterials[index] = {
      ...updatedMaterials[index],
      unit_price: Number(price),
      total_price:
        Number(updatedMaterials[index].quantity || 0) * Number(price),
    };
    setSelectedMaterials(updatedMaterials);
  };

  const MaterialModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Select Material</h2>
            <button onClick={() => setShowMaterialModal(false)}>
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="mb-4">
            <button
              onClick={() => {
                setShowMaterialModal(false);
                setShowNewMaterialModal(true);
              }}
              className="w-full p-3 bg-purple-100 text-purple-700 rounded-lg flex items-center justify-center"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add New Material
            </button>
          </div>

          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search Your Material"
              className="w-full p-2 pl-10 border rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div className="space-y-2 max-h-60 overflow-y-auto">
            {availableMaterials
              .filter((m) =>
                m.material_name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((material) => (
                <button
                  key={material.id}
                  onClick={() =>
                    handleMaterialSelect(material.id, selectedMaterialIndex)
                  }
                  className="w-full p-3 text-left hover:bg-gray-100 rounded-lg"
                >
                  <div className="font-medium">{material.material_name}</div>
                  <div className="text-sm text-gray-600">
                    {material.specification}
                  </div>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const NewMaterialModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">New Material</h2>
            <button onClick={() => setShowNewMaterialModal(false)}>
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Material Name
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                value={newMaterial.material_name}
                onChange={(e) =>
                  setNewMaterial({
                    ...newMaterial,
                    material_name: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Material Subcategory
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                value={newMaterial.subcategory}
                onChange={(e) =>
                  setNewMaterial({
                    ...newMaterial,
                    subcategory: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Specification
              </label>
              <input
                type="text"
                placeholder="Brand, Color, Quality etc."
                className="w-full p-2 border rounded-md"
                value={newMaterial.specification}
                onChange={(e) =>
                  setNewMaterial({
                    ...newMaterial,
                    specification: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Unit of measurement
              </label>
              <select
                className="w-full p-2 border rounded-md"
                value={newMaterial.uom}
                onChange={(e) =>
                  setNewMaterial({ ...newMaterial, uom: e.target.value })
                }
              >
                <option value="">Select unit</option>
                <option value="Bags">Bags</option>
                <option value="Meters(M)">Meters(M)</option>
                <option value="Liters(L)">Liters(L)</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Current Stock
              </label>
              <input
                type="number"
                className="w-full p-2 border rounded-md"
                value={newMaterial.current_stock}
                onChange={(e) =>
                  setNewMaterial({
                    ...newMaterial,
                    current_stock: Number(e.target.value),
                  })
                }
              />
            </div>

            <button
              onClick={handleCreateMaterial}
              className="w-full bg-[#3B0069] text-white py-2 rounded-md font-medium hover:bg-purple-700"
            >
              Save & Continue Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const VendorModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Select Vendor/Shop</h2>
            <button onClick={() => setShowVendorModal(false)}>
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search Vendor"
              className="w-full p-2 pl-10 border rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <div className="space-y-2 max-h-60 overflow-y-auto">
            {vendors
              .filter((vendor) =>
                vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((vendor) => (
                <div
                  key={vendor.id}
                  onClick={() => handleVendorSelect(vendor)}
                  className="w-full p-3 text-left hover:bg-gray-100 rounded-lg cursor-pointer flex justify-between items-center"
                >
                  <div>
                    <div className="font-medium">{vendor.name}</div>
                    <div className="text-sm text-gray-600">
                      +91 {vendor.contact_no}
                    </div>
                  </div>
                  <span className="text-sm bg-gray-200 px-2 py-1 rounded-md">
                    {vendor.vendor_type}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
  const handleDeleteMaterial = (index) => {
    const updatedMaterials = selectedMaterials.filter((_, i) => i !== index);
    setSelectedMaterials(updatedMaterials);
  };
  return (
    <div className="w-full p-6 bg-gray-50">
      <div className="flex justify-between  items-center mb-4">
        <div className="flex items-center">
          <button
            className="mr-3"
            onClick={() => {
              onClose();
            }}
          >
            {/* Back Arrow Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h1 className="flex justify-center items-center text-lg font-semibold">
            Material Purchase
          </h1>
        </div>
        <button className="text-gray-600">
          {/* More Options Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </button>
      </div>
      {/* Original form JSX remains the same until the Materials Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-4">
          <div className="bg-white p-4 rounded-lg space-y-4">
            <div>
              <p className="text-sm text-gray-600 mb-1">Delivery No.</p>
              <p className="font-semibold">#23-09-456</p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Select Delivery Date
              </label>
              <div className="relative">
                <input
                  type="date"
                  className="w-full p-2 border rounded-md pl-10"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                />
                {/* <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" /> */}
              </div>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-700 mb-1">
                Delivery Status
              </p>
              <div className="flex space-x-2">
                <button
                  className={`px-3 py-1 rounded-full text-sm ${
                    deliveryStatus === "Upcoming"
                      ? "bg-purple-100 text-purple-700"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => setDeliveryStatus("Upcoming")}
                >
                  Upcoming
                </button>
                <button
                  className={`px-3 py-1 rounded-full text-sm ${
                    deliveryStatus === "Delivered"
                      ? "bg-purple-100 text-purple-700"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  onClick={() => setDeliveryStatus("Delivered")}
                >
                  Delivered
                </button>
              </div>
            </div>
          </div>

          {/* Payment Details */}
          <div className="bg-white p-4 rounded-lg space-y-4">
            <div>
              <p className="text-sm font-medium text-gray-700 mb-1">
                Payment Status
              </p>
              <div className="flex flex-wrap gap-2">
                {["Pre-Paid", "Part Payment", "Post-Paid"].map((status) => (
                  <button
                    key={status}
                    className={`px-3 py-1 rounded-full text-sm ${
                      paymentStatus === status
                        ? "bg-purple-100 text-purple-700"
                        : "bg-gray-200 text-gray-700"
                    }`}
                    onClick={() => setPaymentStatus(status)}
                  >
                    {status}
                  </button>
                ))}
              </div>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-700 mb-1">
                Payment Method
              </p>
              <div className="flex flex-wrap gap-2">
                {["Cash", "UPI/Bank Transfer", "Cheque"].map((method) => (
                  <button
                    key={method}
                    className={`px-3 py-1 rounded-full text-sm ${
                      paymentMethod === method
                        ? "bg-purple-100 text-purple-700"
                        : "bg-gray-200 text-gray-700"
                    }`}
                    onClick={() => setPaymentMethod(method)}
                  >
                    {method}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Paid Amount
              </label>
              <input
                type="number"
                placeholder="Amount"
                className="w-full p-2 border rounded-md"
                value={paidAmount}
                onChange={(e) => setPaidAmount(e.target.value)}
              />
            </div>

            <div>
              <p className="text-sm font-medium text-gray-700 mb-1">
                Remaining Payment
              </p>
              <p className="font-semibold">
                ₹{" "}
                {(
                  selectedMaterials.reduce(
                    (sum, m) => sum + (m.total_price || 0),
                    0
                  ) - Number(paidAmount || 0)
                ).toFixed(2)}
              </p>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-4">
          {/* Vendor Details */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Vendor Details
            </label>
            <button
              onClick={() => setShowVendorModal(true)}
              className="w-full p-2 border rounded-md text-left bg-white"
            >
              {selectedVendor ? selectedVendor.name : "Select Vendor/Shop"}
            </button>
          </div>

          {/* Materials Section */}
          <div className="bg-white p-4 rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <p className="font-medium">Materials</p>
              <button
                className="text-purple-600 font-medium"
                onClick={() => {
                  setSelectedMaterials([
                    ...selectedMaterials,
                    {
                      material_id: "",
                      quantity: "",
                      unit_price: "",
                      total_price: 0,
                    },
                  ]);
                }}
              >
                +
              </button>
            </div>

            {selectedMaterials.map((material, index) => (
              <div key={index} className="space-y-2">
                <div className="relative flex items-center">
                  <button
                    className="w-full p-2 border rounded-md text-left bg-white"
                    onClick={() => {
                      setSelectedMaterialIndex(index);
                      setShowMaterialModal(true);
                    }}
                  >
                    {material.material_name || "Select Material"}
                  </button>
                  <button
                    className="ml-2 text-red-600"
                    onClick={() => handleDeleteMaterial(index)}
                  >
                    <Trash className="h-5 w-5" />
                  </button>
                </div>
                <div className="flex justify-between">
                  <input
                    type="number"
                    placeholder="Total Unit"
                    className="w-1/3 p-2 border rounded-md"
                    value={material.quantity || ""}
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                  />
                  <input
                    type="text"
                    placeholder="Unit"
                    className="w-1/4 p-2 border rounded-md bg-gray-50"
                    value={material.uom || ""}
                    readOnly
                  />
                  <input
                    type="number"
                    placeholder="Unit Price"
                    className="w-1/3 p-2 border rounded-md"
                    value={material.unit_price || ""}
                    onChange={(e) =>
                      handleUnitPriceChange(index, e.target.value)
                    }
                  />
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">Total Price</p>
                  <p className="font-semibold">
                    ₹ {material.total_price?.toFixed(2) || "0.00"}
                  </p>
                </div>
              </div>
            ))}

            <div className="flex justify-between items-center pt-2 border-t">
              <p className="font-medium">Grand Total</p>
              <p className="font-semibold">
                ₹{" "}
                {selectedMaterials
                  .reduce((sum, m) => sum + (m.total_price || 0), 0)
                  .toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Remarks and Invoice */}
      <div className="mt-6 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Remarks/Comments (Optional)
          </label>
          <textarea
            className="w-full p-2 border rounded-md"
            rows="3"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          ></textarea>
        </div>

        <div>
          <p className="text-sm font-medium text-gray-700 mb-1">
            Invoice (Optional)
          </p>
          <div className="grid grid-cols-2 gap-3">
            <button className="flex items-center justify-center p-2 border border-purple-300 rounded-md text-purple-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4z" />
                <path d="M12 14a3 3 0 100-6 3 3 0 000 6z" />
              </svg>
              Take Photos
            </button>
            <button className="flex items-center justify-center p-2 border border-purple-300 rounded-md text-purple-600">
              <Upload className="h-5 w-5 mr-2" />
              Upload Photos
            </button>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="mt-6">
        <button
          onClick={handleSave}
          className="w-full bg-[#3B0069] text-white py-2 rounded-md font-medium hover:bg-purple-700 transition duration-300"
        >
          Save
        </button>
      </div>

      {/* Modals */}
      {/* Vendor Modal */}
      {showVendorModal && <VendorModal />}
      {showMaterialModal && <MaterialModal />}
      {showNewMaterialModal && <NewMaterialModal />}
    </div>
  );
};

export default MaterialPurchaseForm;
