import React, { useState, useEffect } from "react";
import { Pencil, X, Check } from "lucide-react";
import axios from "axios";
import { BASE_URL } from "utils/constant";

const Skeleton = ({ className = "" }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`} />
);

const SiteDetails = () => {
  const [siteData, setSiteData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [adminBodies, setAdminBodies] = useState([]);
  const [editMode, setEditMode] = useState({
    site_name: false,
    site_area: false,
    local_admin_body_name: false,
    address: false,
    site_catagory_id: false,
    start_date: false,
  });

  const [formData, setFormData] = useState({
    site_name: "",
    site_area: "",
    site_unit: "m_sq",
    local_admin_body_name: "",
    local_admin_body_id: "",
    address: "",
    site_catagory_id: "",
    start_date: "",
  });

  const LoadingSkeleton = () => (
    <div className="w-full mx-auto p-4">
      {/* Hero Image Skeleton */}
      <Skeleton className="h-48 w-full rounded-lg mb-4" />

      {/* Header Skeleton */}
      <div className="flex justify-between items-center mb-4">
        <Skeleton className="h-8 w-40" />
        <Skeleton className="h-6 w-20" />
      </div>

      {/* Content Skeleton */}
      <div className="bg-white rounded-lg">
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <div key={index} className="border-b border-gray-200 py-4">
            <div className="flex justify-between items-center mb-2">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-4 w-4 rounded-full" />
            </div>
            <Skeleton className="h-6 w-3/4" />
          </div>
        ))}
      </div>
    </div>
  );

  const fetchAdminBodies = async () => {
    try {
      const token = localStorage.getItem("access");
      const response = await axios.get(
        `${BASE_URL}/sites/get-all-localadminbody/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAdminBodies(response.data.data);
    } catch (error) {
      console.error("Error fetching admin bodies:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("access");
      const response = await axios.get(
        `${BASE_URL}/sites/get-all-sitecatagory/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSiteDetails = async () => {
    try {
      const siteId = localStorage.getItem("site");
      const token = localStorage.getItem("access");
      const response = await axios.get(
        `${BASE_URL}/sites/get-a-sites/${siteId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSiteData(response.data.data);
      setFormData({
        site_name: response.data.data.site_name,
        site_area: response.data.data.site_area,
        site_unit: response.data.data.uom,
        local_admin_body_name: response.data.data.local_admin_body_name,
        local_admin_body_id: response.data.data.local_admin_body_id,
        address: response.data.data.address,
        site_catagory_id: response.data.data.site_catagory_id,
        start_date: response.data.data.start_date,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching site details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSiteDetails();
    fetchCategories();
    fetchAdminBodies();
  }, []);

  const handleUpdate = async (field) => {
    try {
      const siteId = localStorage.getItem("site");
      const token = localStorage.getItem("access");
      let updateData = {};

      switch (field) {
        case "site_name":
          updateData = { site_name: formData.site_name };
          break;
        case "site_area":
          updateData = {
            site_area: parseFloat(formData.site_area),
            uom: formData.site_unit,
          };
          break;
        case "local_admin_body_name":
          updateData = {
            local_admin_body_name: formData.local_admin_body_name,
            local_admin_body_id: formData.local_admin_body_id,
          };
          break;
        case "site_catagory_id":
          updateData = { site_catagory_id: formData.site_catagory_id };
          break;
        default:
          updateData = { [field]: formData[field] };
      }

      await axios.patch(
        `${BASE_URL}/sites/update-sites/${siteId}/`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchSiteDetails();
      setEditMode({ ...editMode, [field]: false });
    } catch (error) {
      console.error("Error updating site details:", error);
    }
  };

  const getCategoryIcon = (categoryName) => {
    switch (categoryName?.toLowerCase()) {
      case "residential building":
        return "🏘️";
      case "commercial building":
        return "🏢";
      case "institutional building":
        return "🏫";
      case "mixed use building":
        return "🏗️";
      default:
        return "🏢";
    }
  };

  const renderField = (field, label, type = "text") => {
    if (loading) {
      return (
        <div className="border-b border-gray-200 py-4">
          <div className="flex justify-between items-center mb-2">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-4 rounded-full" />
          </div>
          <Skeleton className="h-6 w-3/4" />
        </div>
      );
    }

    const getCurrentCategory = () => {
      if (field === "site_catagory_id") {
        const category = categories.find(
          (cat) => cat.id === siteData?.site_catagory_id
        );
        return category?.catagory_name || "Not specified";
      }
      return null;
    };

    const renderEditContent = () => {
      switch (field) {
        case "local_admin_body_name":
          return (
            <div className="space-y-4">
              <div className="text-sm text-gray-600">
                Current Administrative Body
              </div>
              <div className="font-medium">
                {siteData?.local_admin_body_name}
              </div>
              <div className="text-sm text-gray-600 mt-4">
                New Administrative Body
              </div>
              <div className="space-y-3">
                <input
                  type="text"
                  value={formData.local_admin_body_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      local_admin_body_name: e.target.value,
                    })
                  }
                  className="w-full p-3 bg-gray-50 rounded-lg border border-gray-200"
                  placeholder="Enter Municipality name"
                />
                <div className="flex gap-3">
                  {adminBodies.map((body) => (
                    <button
                      key={body.id}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          local_admin_body_id: body.id,
                        })
                      }
                      className={`flex-1 p-3 rounded-lg border transition-all ${
                        formData.local_admin_body_id === body.id
                          ? "border-purple-500 bg-purple-50"
                          : "border-gray-200 hover:border-purple-200"
                      }`}
                    >
                      {body.admin_body_type}
                    </button>
                  ))}
                </div>
              </div>
              <button
                onClick={() => handleUpdate(field)}
                className="w-full bg-[#3B0069] text-white py-3 rounded-lg font-medium"
              >
                Update
              </button>
            </div>
          );
        case "site_area":
          return (
            <div className="flex gap-2">
              <input
                type="number"
                value={formData.site_area}
                onChange={(e) =>
                  setFormData({ ...formData, site_area: e.target.value })
                }
                className="flex-1 p-2 bg-gray-50 rounded border border-gray-200"
                placeholder="Enter Site Area"
              />
              <select
                value={formData.site_unit}
                onChange={(e) =>
                  setFormData({ ...formData, site_unit: e.target.value })
                }
                className="p-2 bg-gray-50 rounded border border-gray-200"
              >
                <option value="m_sq">Sq.M.</option>
                <option value="ft_sq">Sq.Ft.</option>
                <option value="acres">Acres</option>
                <option value="hectares">Hectares</option>
              </select>
            </div>
          );
        case "site_catagory_id":
          return (
            <div className="space-y-4">
              <div className="text-sm text-gray-600">
                Current Building Category
              </div>
              <div className="font-medium">{getCurrentCategory()}</div>
              <div className="text-sm text-gray-600 mt-4">
                New Building Category
              </div>
              <div className="grid grid-cols-2 gap-3">
                {categories.map((category) => (
                  <div
                    key={category.id}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        site_catagory_id: category.id,
                      })
                    }
                    className={`p-3 rounded-lg cursor-pointer border transition-all ${
                      formData.site_catagory_id === category.id
                        ? "border-purple-500 bg-purple-50"
                        : "border-gray-200 hover:border-purple-200"
                    }`}
                  >
                    <div className="flex items-start gap-3">
                      <div className="text-2xl">
                        {getCategoryIcon(category.catagory_name)}
                      </div>
                      <div>
                        <div className="font-medium text-sm">
                          {category.catagory_name}
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          {category.catagory_name === "Residential Building" &&
                            "Apartments, Single family homes"}
                          {category.catagory_name === "Commercial Building" &&
                            "Office, Retail, Mall, Industries"}
                          {category.catagory_name ===
                            "Institutional Building" &&
                            "Medical, Educational, Government"}
                          {category.catagory_name === "Mixed Use Building" &&
                            "Live + Work spaces used mixed"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={() => handleUpdate(field)}
                className="w-full mt-4 bg-[#3B0069] text-white py-3 rounded-lg font-medium"
              >
                Update
              </button>
            </div>
          );
        default:
          return (
            <input
              type={type}
              value={formData[field]}
              onChange={(e) =>
                setFormData({ ...formData, [field]: e.target.value })
              }
              className="w-full p-2 bg-gray-50 rounded border border-gray-200"
              placeholder={`Enter ${label}`}
            />
          );
      }
    };

    return (
      <div className="border-b border-gray-200 py-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-gray-500">{label}</span>
          {!editMode[field] ? (
            <button
              onClick={() => setEditMode({ ...editMode, [field]: true })}
              className="text-purple-600"
            >
              <Pencil size={16} />
            </button>
          ) : (
            <div className="flex gap-2">
              <button
                onClick={() => setEditMode({ ...editMode, [field]: false })}
                className="text-gray-500"
              >
                <X size={16} />
              </button>
              {field !== "local_admin_body_name" && (
                <button
                  onClick={() => handleUpdate(field)}
                  className="text-purple-600"
                >
                  <Check size={16} />
                </button>
              )}
            </div>
          )}
        </div>

        {editMode[field] ? (
          renderEditContent()
        ) : (
          <div className="font-medium">
            {field === "site_catagory_id"
              ? getCurrentCategory()
              : field === "site_area"
              ? `${siteData?.site_area} ${siteData?.uom}`
              : siteData?.[field]}
          </div>
        )}
      </div>
    );
  };

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <div
      className="w-full mx-auto p-4"
      style={{
        height: "90vh",
        overflowY: "scroll",
      }}
    >
      <div
        className="h-48 bg-cover bg-center rounded-lg mb-4"
        style={{
          backgroundImage: siteData?.image_file
            ? `url(${siteData.image_file})`
            : "url(https://static01.nyt.com/images/2019/05/29/realestate/00skyline-south4/88ce0191bfc249b6aae1b472158cccc4-superJumbo.jpg)",
        }}
      >
        <div className="h-full flex justify-end items-start p-4">
          <button className="bg-white rounded-lg px-3 py-2 text-sm flex items-center gap-2">
            <Pencil size={16} className="text-purple-600" />
            Edit Site
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Site Details</h2>
        <button
          onClick={() => window.location.reload()}
          className="text-purple-600 text-sm flex items-center gap-1"
        >
          Undo All
        </button>
      </div>

      <div className="bg-white rounded-lg">
        {renderField("site_name", "Site Name")}
        {renderField("site_area", "Site Area")}
        {renderField("local_admin_body_name", "Administrative Body")}
        {renderField("address", "Site Address")}
        {renderField("site_catagory_id", "Building Category")}
        {renderField("start_date", "Project Starting Date", "date")}
      </div>
    </div>
  );
};

export default SiteDetails;
