import React from "react";
import avatart from "../assets/3d_avatar_26.png";
import { IoIosArrowDropdown } from "react-icons/io";
import CreateSubtaskModal from "./CreateSubTaskModeltwo";
import { useState, useEffect } from "react";
import { BASE_URL } from "utils/constant";
import CreateTransactionModal from "./CreateTransactionModal";
import UpdateStockModal from "./StockUpdateForm";
import SubtaskDetailModal from "./SubTaskDetailModal";
const SubtasksView = ({
  subTasks,
  activeStatus,
  setActiveStatus,
  setShowSubtaskForm,
  convertDate,
  setSelectedSubtask
}) => {
  const getFilteredSubtasks = () => {
    const currentDate = new Date();
    return subTasks.filter((subtask) => {
      const endDate = new Date(subtask.end_date);
      switch (activeStatus) {
        case "completed":
          return subtask.is_completed === true;
        case "delayed":
          return endDate < currentDate && !subtask.is_completed;
        case "ongoing":
          return endDate >= currentDate && !subtask.is_completed;
        default:
          return true;
      }
    });
  };

  const StatusTab = ({ status, count }) => (
    <span
      className={`cursor-pointer ${
        activeStatus === status
          ? "text-blue-600 border-b-2 border-blue-600"
          : "text-gray-500"
      } pb-2`}
      onClick={() => setActiveStatus(status)}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)} ({count})
    </span>
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex space-x-4 mb-4 border-b pb-2">
        <StatusTab
          status="ongoing"
          count={
            subTasks.filter(
              (st) => !st.is_completed && new Date(st.end_date) >= new Date()
            ).length
          }
        />
        <StatusTab
          status="completed"
          count={subTasks.filter((st) => st.is_completed).length}
        />
        <StatusTab
          status="delayed"
          count={
            subTasks.filter(
              (st) => !st.is_completed && new Date(st.end_date) < new Date()
            ).length
          }
        />
        <div className="flex-grow"></div>
        <button
          className="bg-[#3B0069] text-white px-4 py-1 rounded-lg text-sm"
          onClick={() => setShowSubtaskForm(true)}
        >
          + Add Subtask
        </button>
      </div>

      {getFilteredSubtasks().map((subTask, index) => (
        <div
          key={index}
          className="flex items-center py-3 border-b last:border-b-0"
        >
          <div className="bg-blue-100 p-2 rounded-lg mr-4">
            <svg
              className="w-5 h-5 text-blue-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div className="flex-grow">
            <h4 className="font-semibold">{subTask?.subtask_name}</h4>
          </div>
          <div className="text-gray-500 text-sm mr-4">{subTask?.assign_to}</div>
          <div className="text-gray-500 text-sm mr-4">
            {convertDate(subTask?.end_date)}
          </div>
          <button
            className="text-[#718EBF] rounded-2xl border-2 px-4 py-2 border-[#718EBF]"
            onClick={() => setSelectedSubtask(subTask)}
          >
            View Details
          </button>
        </div>
      ))}
    </div>
  );
};

const TransactionsView = ({ taskId }) => {
  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const fetchTransactions = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
        body: JSON.stringify({
          task_id: taskId,
        }),
      };

      const response = await fetch(
        `${BASE_URL}/task/get-all-transactions/`,
        requestOptions
      );
      const data = await response.json();

      if (data.status) {
        setTransactions(data.data);
        // ADD AMOUNTS
        setTotalAmount(
          data.data.reduce((acc, transaction) => acc + transaction.amount, 0)
        );
      } else {
        setError(data.message || "Failed to fetch transactions.");
      }
    } catch (error) {
      setError(
        error.message || "An error occurred while fetching transactions."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [taskId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-semibold text-lg">Recent Transactions</h3>
            <p className="text-sm text-gray-500">
              Total Amount Used: ₹{totalAmount}
            </p>
          </div>
          <button
            className="bg-[#3B0069] text-white px-4 py-1 rounded-lg text-sm"
            onClick={() => {
              setShowTransactionModal(true);
            }}
          >
            + Add Transaction
          </button>
        </div>

        {loading ? (
          <div className="text-center py-8">Loading transactions...</div>
        ) : error ? (
          <div className="text-red-500 text-center py-8">{error}</div>
        ) : transactions.length === 0 ? (
          <div className="text-gray-400 text-center py-8">
            No transactions found
          </div>
        ) : (
          <>
            <div className="grid grid-cols-6 gap-4 text-sm font-medium text-gray-500 pb-2 border-b">
              <div>Transaction ID</div>
              <div>Type</div>
              <div>Method</div>
              <div>Date</div>
              <div>Amount</div>
              <div>Status</div>
            </div>
            <div className="space-y-2">
              {transactions.map((transaction) => (
                <div
                  key={transaction.id}
                  className="grid grid-cols-6 gap-4 text-sm py-2 border-b last:border-b-0"
                >
                  <div className="font-medium">#{transaction.id}</div>
                  <div className="capitalize">
                    {transaction.transaction_type}
                  </div>
                  <div className="capitalize">
                    {transaction.transaction_method}
                  </div>
                  <div>{formatDate(transaction.transaction_date)}</div>
                  <div className="text-red-500">
                    -₹{transaction.amount.toLocaleString()}
                  </div>
                  <div>
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        transaction.transaction_status === "paid"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {transaction.transaction_status}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <CreateTransactionModal
        isOpen={showTransactionModal}
        onClose={() => setShowTransactionModal(false)}
        taskId={taskId}
        // siteId={siteId}
        onSuccess={() => {
          // Refresh your transactions list
          fetchTransactions();
        }}
      />
    </div>
  );
};

const MaterialsView = ({ taskId }) => {
  const [materials, setMaterials] = useState([]);
  const [materialUses, setMaterialUses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeView, setActiveView] = useState("used");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const fetchMaterials = async () => {
    try {
      const accessToken = localStorage.getItem("access");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ task_id: taskId }),
      };

      const [materialsResponse, usesResponse] = await Promise.all([
        fetch(`${BASE_URL}/task/get-task-materials/`, requestOptions),
        fetch(`${BASE_URL}/task/get-material-uses/`, requestOptions),
      ]);

      const materialsData = await materialsResponse.json();
      const usesData = await usesResponse.json();

      if (materialsData.status && usesData.status) {
        setMaterials(materialsData.data);
        setMaterialUses(usesData.data);
      } else {
        setError("Failed to fetch materials data");
      }
    } catch (error) {
      setError("An error occurred while fetching materials");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, [taskId]);

  const renderUsedView = () => (
    <div className="space-y-4">
      {materialUses.map((use) => (
        <div
          key={use.id}
          className="flex items-center py-4 border-b last:border-b-0"
        >
          <div className="flex items-center flex-1">
            <div className="w-12 h-12 bg-gray-100 rounded-lg mr-4"></div>
            <div>
              <div className="font-medium">{use.material_name}</div>
              <div className="text-sm text-gray-500">{use.specification}</div>
            </div>
          </div>
          <div className="flex-1">
            <div className="text-red-500">
              ↘ {use.entered_quantity}{" "}
              {use.material_name.toLowerCase().includes("paint")
                ? "lit"
                : "pc."}
            </div>
          </div>
          <div className="flex-1 text-gray-600">
            {new Date(use.update_date).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </div>
          <button
            onClick={() => setShowUpdateModal(true)}
            className="ml-4 px-4 py-1 bg-purple-100 text-purple-700 rounded-lg flex items-center"
          >
            Update
            <svg
              className="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      ))}
    </div>
  );

  const renderInventoryView = () => (
    <div className="space-y-4">
      {materials.map((material) => (
        <div
          key={material.id}
          className="flex items-center py-4 border-b last:border-b-0"
        >
          <div className="flex items-center flex-1">
            <div className="w-12 h-12 bg-gray-100 rounded-lg mr-4"></div>
            <div>
              <div className="font-medium">{material.material_name}</div>
              <div className="text-sm text-gray-500">
                {material.specification}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="text-gray-600">In stock</div>
            <div>
              {material.estimated_quantity}{" "}
              {material.material_name.toLowerCase().includes("paint")
                ? "lit"
                : "pc."}
            </div>
          </div>
          <div className="text-green-500">
            +{" "}
            {materialUses
              .filter((use) => use.material === material.material_id)
              .reduce((total, use) => total + use.entered_quantity, 0)}{" "}
            {material.material_name.toLowerCase().includes("paint")
              ? "lit"
              : "pc."}
          </div>
          <div className="text-sm text-gray-500 ml-4">
            update {new Date().toLocaleTimeString()}{" "}
            {new Date().toLocaleDateString()}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center mb-6">
          <div className="flex space-x-8">
            <button
              className={`pb-2 ${
                activeView === "used"
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveView("used")}
            >
              Used
            </button>
            <button
              className={`pb-2 ${
                activeView === "inventory"
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveView("inventory")}
            >
              Inventory
            </button>
          </div>
          <button
            onClick={() => {
              if (activeView === "used") {
                setShowUpdateModal(true);
              }
            }}
            className="bg-[#3B0069] text-white px-6 py-2 rounded-lg text-sm flex items-center"
          >
            {activeView === "used" ? "↻ Update Stock" : "+ Add Material"}
          </button>
        </div>

        {loading ? (
          <div className="text-center py-8">Loading materials...</div>
        ) : error ? (
          <div className="text-red-500 text-center py-8">{error}</div>
        ) : activeView === "used" ? (
          renderUsedView()
        ) : (
          renderInventoryView()
        )}
      </div>
      <UpdateStockModal
        isOpen={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        taskId={taskId}
        onSuccess={() => {
          // Refresh your materials list or perform any other updates
          fetchMaterials();
        }}
      />
    </div>
  );
};

const RaisedRequestView = () => (
  <div className="bg-white rounded-lg shadow-md p-4">
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-lg">Raised Requests</h3>
        <button className="bg-[#3B0069] text-white px-4 py-1 rounded-lg text-sm">
          + New Request
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 text-sm font-medium text-gray-500 pb-2 border-b">
        <div>Request ID</div>
        <div>Type</div>
        <div>Date</div>
        <div>Status</div>
      </div>
      <div className="text-gray-400 text-center py-8">No requests found</div>
    </div>
  </div>
);

const TaskDetails = ({ task }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const [showSubtaskForm, setShowSubtaskForm] = React.useState(false);
  const [subTasks, setSubTasks] = React.useState([]);
  const [activeStatus, setActiveStatus] = useState("ongoing");
  const [activeView, setActiveView] = useState("subtasks");
  const [error, setError] = useState(null);
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  async function getSubTasks() {
    try {
      const accessToken = localStorage.getItem("access");
      const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }),
        body: JSON.stringify({
          task_id: task?.id,
        }),
        redirect: "follow",
      };

      if (accessToken) {
        const response = await fetch(
          `${BASE_URL}/task/get-all-subtasks/`,
          requestOptions
        );
        const data = await response.json();

        if (data.status) {
          setSubTasks(data.data);
        } else {
          setError(data.message || "Failed to fetch tasks.");
        }
      } else {
        setError("Access token is missing.");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching tasks.");
    }
  }

  useEffect(() => {
    getSubTasks();
  }, []);

  const convertDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}th ${d.toLocaleString("default", {
      month: "long",
    })}, ${d.getFullYear()}`;
  };

  const renderActiveView = () => {
    switch (activeView) {
      case "subtasks":
        return (
          <SubtasksView
            subTasks={subTasks}
            activeStatus={activeStatus}
            setActiveStatus={setActiveStatus}
            setShowSubtaskForm={setShowSubtaskForm}
            convertDate={convertDate}
            setSelectedSubtask = {setSelectedSubtask}
          />
        );
      case "transactions":
        return <TransactionsView taskId={task?.id} />;
      case "materials":
        return <MaterialsView taskId={task?.id} />;
      case "raised-requests":
        return <RaisedRequestView />;
      default:
        return null;
    }
  };

  return (
    <div className="w-[85%] mx-auto p-6 space-y-4 bg-gray-100">
      <div
        className="bg-white rounded-lg shadow-md px-4 flex flex-row items-center justify-between py-2 cursor-pointer"
        onClick={() => setShowDetails(!showDetails)}
      >
        <h2 className="text-xl font-semibold">Task Details</h2>
        <IoIosArrowDropdown size="2em" />
      </div>

      {showDetails && (
        <div className="flex bg-white flex-col rounded-lg">
          <div className="border-b-2 border-[#B0B0B0] p-4">
            <div className="flex justify-between items-center">
              <h3 className="font-semibold">Task Name : {task?.task_name}</h3>
              <span className="bg-green-400 text-white px-3 py-1 rounded-full text-lg">
                {task?.task_status}
              </span>
            </div>
          </div>

          <div className="border-b-2 border-[#B0B0B0] p-4">
            <h3 className="font-semibold mb-2">Task Details :</h3>
            <p className="text-gray-600">{task?.task_details}</p>
          </div>

          <div className="border-b-2 border-[#B0B0B0] p-4">
            <h3 className="font-semibold mb-2">Assign People :</h3>
            <div className="flex -space-x-2">
              {task?.task_members?.map((_, index) => (
                <div
                  key={index}
                  className="w-8 h-8 rounded-full bg-gray-300 border-2 border-white"
                >
                  <img
                    src={avatart}
                    alt="Team member"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              ))}
              <div className="w-8 h-8 rounded-full bg-gray-200 border-2 border-white flex items-center justify-center text-gray-500 text-xs">
                +1
              </div>
            </div>
          </div>

          <div className="p-4 flex flex-row items-center justify-start gap-2">
            <h3 className="font-semibold">Date Timeline :</h3>
            <p className="text-gray-600">
              {convertDate(task?.start_date)} To {convertDate(task?.end_date)}
            </p>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex justify-between items-center">
          <div className="space-x-4">
            {["subtasks", "transactions", "materials", "raised-requests"].map(
              (view) => (
                <button
                  key={view}
                  className={`${
                    activeView === view
                      ? "text-blue-600 font-semibold"
                      : "text-gray-500 hover:text-blue-400"
                  }`}
                  onClick={() => setActiveView(view)}
                >
                  {view.charAt(0).toUpperCase() +
                    view.slice(1).replace("-", " ")}
                </button>
              )
            )}
          </div>
        </div>
      </div>

      {renderActiveView()}

      <CreateSubtaskModal
        isOpen={showSubtaskForm}
        onClose={() => setShowSubtaskForm(false)}
        taskId={task?.id}
        onSuccess={() => {
          getSubTasks();
        }}
      />
      <SubtaskDetailModal
        isOpen={selectedSubtask !== null}
        onClose={() => setSelectedSubtask(null)}
        subtask={selectedSubtask}
        onUpdate={() => {
          // Refresh your subtasks list
          getSubTasks();
        }}
      />
    </div>
  );
};

export default TaskDetails;
