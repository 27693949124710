import React, { useState, useEffect } from "react";
import { Calendar, Upload, X } from "lucide-react";
import { BASE_URL } from "utils/constant";
const WorkerTypeSelector = ({ onSelect, onClose }) => {
  return (
    <div className="absolute z-50 bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 w-48">
      <button
        onClick={() => onSelect("Worker")}
        className="w-full px-4 py-2 text-left hover:bg-gray-50 text-sm"
      >
        Worker
      </button>
      <button
        onClick={() => onSelect("Contractor")}
        className="w-full px-4 py-2 text-left hover:bg-gray-50 text-sm"
      >
        Contractor
      </button>
    </div>
  );
};

const WorkerSelectionModal = ({ onClose, onSelectWorker, workerType }) => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkers = async () => {
      const site = localStorage.getItem("site");
      const accessToken = localStorage.getItem("access");
      try {
        const response = await fetch(`${BASE_URL}/worker/workers-page/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            worker_type: workerType,
            sites: parseInt(site),
          }),
        });
        const data = await response.json();
        if (data.status) {
          setWorkers(data.data);
        }
      } catch (error) {
        console.error("Error fetching workers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkers();
  }, [workerType]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Select {workerType}</h2>
          <button onClick={onClose}>
            <X className="h-5 w-5" />
          </button>
        </div>

        {loading ? (
          <div className="text-center py-4">Loading...</div>
        ) : (
          <div className="max-h-96 overflow-y-auto">
            {workers.map((worker) => (
              <button
                key={worker.id}
                className="w-full p-3 text-left hover:bg-gray-50 border-b border-gray-100"
                onClick={() => onSelectWorker(worker)}
              >
                <div className="font-medium">{worker.worker_name}</div>
                <div className="text-sm text-gray-500">
                  {worker.worker_workname} - {worker.worker_workcategory}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default function WorkersPaymentForm({ onClose }) {
  const [showTypeSelector, setShowTypeSelector] = useState(false);
  const [showWorkerModal, setShowWorkerModal] = useState(false);
  const [selectedWorkerType, setSelectedWorkerType] = useState("");
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paymentType, setPaymentType] = useState("Schedule");
  const [remarks, setRemarks] = useState("");

  const handleSave = async () => {
    if (!selectedWorker || !amount) return;
    const site = localStorage.getItem("site");
    const currentDate = new Date();
    // const formattedDate = `${currentDate
    //   .getDate()
    //   .toString()
    //   .padStart(2, "0")}-${(currentDate.getMonth() + 1)
    //   .toString()
    //   .padStart(2, "0")}-${currentDate.getFullYear().toString().slice(-2)}`;

    // date in YYYY-MM-DD format
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const paymentData = {
      payment_type: paymentType,
      amount: parseInt(amount),
      worker_id: selectedWorker.worker_id,
      transaction_date: formattedDate,
      transaction_method: paymentMethod,
      comments: remarks,
      receipt_url: "",
      transaction_catagory_id: 5,
      site: parseInt(site),
    };

    try {
      const accessToken = localStorage.getItem("access");

      const response = await fetch(
        `${BASE_URL}/finance/create-worker-transaction/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(paymentData),
        }
      );
      const data = await response.json();
      if (data.status) {
        onClose();
      }
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };

  return (
    <div className="w-full p-6 bg-gray-50">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 bg-white p-4 rounded-lg shadow-sm">
        <button onClick={onClose}>
          <X className="h-5 w-5" />
        </button>
        <h1 className="text-lg font-semibold">Workers Payment</h1>
        <div className="w-5" /> {/* Spacer for alignment */}
      </div>

      {/* Main Form */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Worker Details */}
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <label className="block text-sm text-gray-600 mb-1">
            Worker/Contractor details
          </label>
          <button
            className="w-full p-3 bg-white rounded-lg text-left flex justify-between items-center border border-gray-200"
            onClick={() => setShowTypeSelector(true)}
          >
            <span className="text-gray-500">
              {selectedWorker
                ? selectedWorker.worker_name
                : "Select Worker/Contractor"}
            </span>
            <svg
              className="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
          {showTypeSelector && (
            <div className="relative">
              <WorkerTypeSelector
                onSelect={(type) => {
                  setSelectedWorkerType(type);
                  setShowTypeSelector(false);
                  setShowWorkerModal(true);
                }}
                onClose={() => setShowTypeSelector(false)}
              />
            </div>
          )}
        </div>

        {/* Rest of the form components remain the same... */}
        {/* Payment Date */}
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <label className="block text-sm text-gray-600 mb-1">
            Payment Date
          </label>
          <div className="flex gap-2">
            <button className="px-4 py-2 rounded-full bg-purple-100 text-purple-600 text-sm">
              Now
            </button>
            <button className="px-4 py-2 rounded-full bg-gray-100 text-gray-600 text-sm flex items-center gap-2">
              Pick an older date
              <Calendar className="h-4 w-4" />
            </button>
          </div>
        </div>

        {/* Amount and Payment Details */}
        <div className="bg-white p-4 rounded-lg space-y-4 shadow-sm">
          <div>
            <label className="block text-sm text-gray-600 mb-1">₹ Amount</label>
            <input
              type="text"
              className="w-full p-3 bg-white rounded-lg border border-gray-200"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <div>
            <label className="block text-sm text-gray-600 mb-1">
              Payment Method
            </label>
            <div className="flex flex-wrap gap-2">
              {["Cash", "UPI/Bank Transfer", "Cheque"].map((method) => (
                <button
                  key={method}
                  className={`px-4 py-2 rounded-full text-sm ${
                    paymentMethod === method
                      ? "bg-purple-100 text-purple-600"
                      : "bg-gray-100 text-gray-600"
                  }`}
                  onClick={() => setPaymentMethod(method)}
                >
                  {method}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-600 mb-1">
              Payment Type
            </label>
            <div className="flex flex-wrap gap-2">
              {["Schedule", "Advance", "Extra/Bonus"].map((type) => (
                <button
                  key={type}
                  className={`px-4 py-2 rounded-full text-sm ${
                    paymentType === type
                      ? "bg-purple-100 text-purple-600"
                      : "bg-gray-100 text-gray-600"
                  }`}
                  onClick={() => setPaymentType(type)}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Remarks and Photos */}
        <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
          <input
            type="text"
            className="w-full p-2 bg-transparent border-b border-gray-300"
            placeholder="Remarks / Comments (Optional)"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />

          <div>
            <p className="text-sm text-gray-600 mb-2">
              Payment Slip (Optional)
            </p>
            <div className="flex gap-3">
              <button className="flex items-center justify-center gap-2 p-2 border border-purple-300 rounded-md text-purple-600">
                Take Photos
              </button>
              <button className="flex items-center justify-center gap-2 p-2 border border-purple-300 rounded-md text-purple-600">
                Upload Photos
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <button
        className="w-full mt-6 bg-[#3B0069] text-white py-2 rounded-md font-medium hover:bg-purple-700 transition duration-300 flex items-center justify-center gap-2"
        onClick={handleSave}
      >
        Save
      </button>

      {/* Worker Selection Modal */}
      {showWorkerModal && (
        <WorkerSelectionModal
          workerType={selectedWorkerType}
          onClose={() => setShowWorkerModal(false)}
          onSelectWorker={(worker) => {
            setSelectedWorker(worker);
            setShowWorkerModal(false);
          }}
        />
      )}
    </div>
  );
}
