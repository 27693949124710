import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import base_background from "../assets/Base (1).png";
import main_logo from "../assets/SM_Full_White-100 1 (1).png";
import arrow_forward from "../assets/arrow_forward.png";
import arrow from "../assets/Vector (1).png";
import passwordIcon from "../assets/visibility_lock (1).png";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { BASE_URL } from "../utils/constant";
import { useAlert } from "react-alert";
export default function UserAddress() {
  const loginValidationSchema = yup.object().shape({
    address: yup.string().required("Address is Required"),
    city: yup.string().required("City is Required"),
    //  pin must and 6 digits and only contain digits
    pin: yup
      .string()
      .matches(/^[0-9]{6}$/, "Must be exactly 6 digits")
      .required("Pin is Required"),
  });
  const alert = useAlert();
  const navigate = useNavigate();
  async function saveAddressDetails(address, city, state, pin) {
    // setLoading(true);
    let accessToken = localStorage.getItem("access");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      street_address: address,
      city: city,
      country: "India",
      state: state,
      pincode: pin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (accessToken) {
      try {
        const response = await fetch(
          `${BASE_URL}/auth/create-address/`,
          requestOptions
        );
        const result = await response.json();

        if (result.status) {
          //   navigation.navigate("WelcomeScreen");
          navigate("/welcome");
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        // Toast.error(error?.message);
        alert.error(error?.message);
      } finally {
        // setLoading(false);
        console.log("Finally");
      }
    }
  }

  const handleSignup = (address, city, pin) => {
    console.log("Signup");
    console.log(address);
    console.log(city);
    console.log(pin);
  };
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  const formik = useFormik({
    initialValues: {
      address: "",
      city: "",
      pin: "",
      state: "West Bengal",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      //   handleSignup(values.address, values.city, values.pin);
      saveAddressDetails(values.address, values.city, values.state, values.pin);
    },
  });
  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={base_background}
          alt=""
          style={{
            height: "140%",
            width: "140%",
            objectFit: "cover",
            position: "relative",
            top: "-50px",
            left: "-50px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={arrow}
          alt=""
          style={{
            height: "115%",
            width: "115%",
            top: "0px",
            left: "-70px",
            objectFit: "contain",
            position: "relative",
            opacity: "0.5",
          }}
        />
      </div>
      <div className="flex flex-col align-middle items-center justify-center w-[50%]">
        <span className="py-5 font-extrabold text-neutral-900 text-2xl">
          <img src={main_logo} alt="" width="90%" />
        </span>
        <span
          className="font-extrabold text-neutral-900 font-medium mt-[-2]"
          style={{
            fontSize: "28px",
            color: "#ffffff",
            fontWeight: 400,
            opacity: 0.8,
            letterSpacing: "0.1em",
            textAlign: "center",
          }}
        >
          Build Smarter,Faster,Better
        </span>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          top: "70px",
          /* margin-top: auto; */
          right: "50px",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col align-middle justify-between px-10  pb-5"
          style={{ width: "80%" }}
        >
          <div className="flex flex-col">
            <span
              className="py-5 font-extrabold text-purple-800 text-2xl"
              style={{
                color: "#3B0069",
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Create an Account
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: 200,
                opacity: 0.8,
                marginTop: "-12px",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-purple-800"
                style={{ textDecoration: "underline" }}
              >
                Sign in
              </Link>
            </span>
            <span
              className="py-2 "
              style={{
                fontSize: "24px",
                color: "#4B5563",
                fontWeight: 700,
                marginTop: "10px",
              }}
            >
              Sign Up with Email
            </span>

            <span className="pt-3 text-neutral-800 font-medium">
              Your Address
            </span>
            <input
              type="text"
              name="address"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your street address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              style={{ width: "80%" }}
            />
            {formik.touched.address && formik.errors.address && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.address}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">City/Town</span>
            <input
              type="text"
              name="city"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              style={{ width: "80%" }}
            />
            {formik.touched.city && formik.errors.city && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.city}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">Pin Code</span>
            <input
              type="text"
              name="pin"
              className="bg-gray-200 rounded p-2 px-8"
              placeholder="Enter your pin code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pin}
              style={{ width: "80%" }}
            />
            {formik.touched.pin && formik.errors.pin && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.pin}
              </span>
            )}
            <span className="pt-3 text-neutral-800 font-medium">State</span>
            <select
              name="state"
              className="bg-gray-200 rounded p-2 px-8"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              style={{ width: "80%" }}
            >
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {formik.touched.state && formik.errors.state && (
              <span style={{ fontSize: 10, color: "red" }}>
                {formik.errors.state}
              </span>
            )}
          </div>

          <div className="w-full mt-14" style={{ width: "80%" }}>
            <button
              type="submit"
              className={`py-3 px-8 rounded-lg w-full justify-center items-center flex flex-row gap-4 text-center ${
                formik.isValid ? "bg-purple-950" : "bg-gray-400"
              }`}
              disabled={!formik.isValid}
              onClick={() => {
                // dispatch(authActions.login({ email: formik.values.email,password:formik.values.password }));
                console.log("Logged in");

                // navigate("/otp-screen");
              }}
            >
              <span className="font-bold text-white text-lg">
                Create Account
              </span>

              {/* Replace the icon component here */}

              <img
                src={arrow_forward}
                alt=""
                className="w-6 h-6"
                style={{ background: "#58267F", borderRadius: "50%" }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
