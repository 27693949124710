import React, { useEffect, useState } from "react";
import { X, MoreVertical, Plus } from "lucide-react";
import { BASE_URL } from "utils/constant";
import DeliveryPaymentFlow from "./DelivaryPaymentFlow";

const MaterialPurchaseDetails = ({ delivery, onClose }) => {
  const [comments, setComments] = useState([]);
  const [showPaymentFlow, setShowPaymentFlow] = useState(false);

  const handleReceiveClick = () => {
    setShowPaymentFlow(true);
  };

  const handlePaymentFlowClose = () => {
    setShowPaymentFlow(false);
    onClose();
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const accessToken = localStorage.getItem("access");
        const response = await fetch(
          `${BASE_URL}/inventory/get-all-comments-of-delivery/`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ delivery: delivery?.id }),
          }
        );
        const data = await response.json();
        if (data.status) {
          setComments(data.data);
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    if (delivery?.id) {
      fetchComments();
    }
  }, [delivery?.id]);

  return (
    <div className="w-full bg-gray-50 p-4 relative">
      {showPaymentFlow ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="max-w-4xl w-full mx-4">
            <DeliveryPaymentFlow onClose={handlePaymentFlowClose} delivery={delivery}/>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center mb-6 bg-white p-4 rounded-lg shadow-sm">
            <button className="text-gray-600" onClick={onClose}>
              <X size={20} />
            </button>
            <h2 className="text-lg font-medium">Material Purchase Details</h2>
            <button className="text-gray-600">
              <MoreVertical size={20} />
            </button>
          </div>

          <div className="space-y-6">
            {/* Delivery Details */}
            <div className="bg-white p-6 rounded-lg shadow-sm space-y-4">
              <div className="flex justify-between">
                <div>
                  <p className="text-sm text-gray-500">Delivery Number</p>
                  <p className="font-medium">#{delivery?.id}</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-500">Expected Date</p>
                  <p className="font-medium">{delivery?.date}</p>
                </div>
              </div>
              <div>
                <p className="text-sm text-gray-500">Vendor/Shop Name</p>
                <div className="flex items-center gap-2">
                  <span className="bg-gray-100 px-2 py-1 rounded text-sm">
                    Shop
                  </span>
                  <span className="text-gray-600">
                    +91 {delivery?.contact_no}
                  </span>
                </div>
              </div>
            </div>

            {/* Materials */}
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex items-center gap-2 mb-4">
                <h3 className="font-medium">Materials</h3>
                <span className="bg-gray-100 text-sm px-2 py-0.5 rounded">
                  {delivery?.all_materials?.length}
                </span>
              </div>

              {delivery?.all_materials?.map((material, index) => (
                <div key={material.id} className="mb-4 last:mb-0">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <span className="text-gray-500 text-sm">
                        {String(index + 1).padStart(2, "0")}
                      </span>
                      <span className="font-medium">
                        {material.material_name}
                      </span>
                      <span className="text-gray-500 text-sm">
                        Grade- {material.grade}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Quantity</p>
                      <p className="font-medium">
                        {material.quantity} {material.unit}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Price/Unit</p>
                      <p className="font-medium">₹ {material.unit_price}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Total Price</p>
                      <p className="font-medium">₹ {material.total_price}</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex justify-between mt-4 pt-4 border-t">
                <span className="font-medium">Grand Total</span>
                <span className="font-medium">₹ {delivery?.total_price}</span>
              </div>
            </div>

            {/* Payment Entries */}
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Payment Entries</h3>
                <button className="flex items-center gap-1 text-sm bg-gray-100 px-3 py-1.5 rounded-full">
                  New <Plus size={16} />
                </button>
              </div>

              <div className="space-y-4">
                {delivery?.all_part_payments?.map((payment) => (
                  <div
                    key={payment.id}
                    className="flex justify-between items-start"
                  >
                    <div>
                      <p className="text-sm">
                        {new Date(payment.created_on).toLocaleString()}
                      </p>
                      <p className="text-sm text-gray-500">
                        {payment.payment_method} • {payment.remarks}
                      </p>
                    </div>
                    <span className="font-medium">₹ {payment.amount}</span>
                  </div>
                ))}
              </div>

              <div className="mt-4 pt-4 border-t space-y-2">
                <div className="flex justify-between">
                  <span className="font-medium">Total Paid Amount</span>
                  <span className="font-medium text-green-600">
                    ₹ {delivery?.paid_amount}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium">Remaining Amount</span>
                  <span className="font-medium text-red-500">
                    ₹ {delivery?.remaining_payment}
                  </span>
                </div>
              </div>
            </div>

            {/* Comments */}
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Comments/Remarks</h3>
                <button className="flex items-center gap-1 text-sm bg-gray-100 px-3 py-1.5 rounded-full">
                  New <Plus size={16} />
                </button>
              </div>

              <div className="space-y-3">
                {comments.map((comment) => (
                  <div
                    key={comment.id}
                    className="flex justify-between items-start"
                  >
                    <div>
                      <p className="text-sm">
                        {new Date(comment.created_on).toLocaleString()}
                      </p>
                      <p className="text-sm text-gray-600">{comment.comment}</p>
                    </div>
                    <button className="text-gray-400">
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4 justify-center">
              <button className="px-6 py-2.5 bg-gray-100 text-gray-700 rounded-full flex items-center gap-2">
                Returned <span className="transform rotate-45">↗</span>
              </button>
              <button
                onClick={handleReceiveClick}
                className="px-6 py-2.5 bg-[#3B0069] text-white rounded-full flex items-center gap-2"
              >
                Received <span>✓</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialPurchaseDetails;
