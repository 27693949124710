import React, { useState } from 'react';
import { BASE_URL } from 'utils/constant';

const NewVendorModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    vendor_type: 'Shop',
    contact_no: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const siteId = localStorage.getItem('site');
      const token = localStorage.getItem('access');

      const response = await fetch(`${BASE_URL}/inventory/create-vendor/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...formData,
          sites: parseInt(siteId),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create vendor');
      }

      // Reset form and close modal on success
      setFormData({
        name: '',
        vendor_type: 'Shop',
        contact_no: '',
      });
      onClose();
    } catch (err) {
      setError('Failed to create vendor. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">New Vendor/Shop</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Vendor / Shop Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          <div>
            <select
              name="vendor_type"
              value={formData.vendor_type}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              <option value="Shop">Shop</option>
              <option value="Vendor">Vendor</option>
            </select>
          </div>

          <div>
            <input
              type="tel"
              name="contact_no"
              placeholder="+91 98700 00000"
              value={formData.contact_no}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#3B0069] text-white py-2 rounded-lg hover:bg-[#2a004c] disabled:opacity-50"
          >
            {isLoading ? 'Creating...' : 'Save'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewVendorModal;