import React from "react";
import { useState, useEffect } from "react";
// import { useAlert } from "react-alert";
import { BASE_URL } from "../utils/constant";
import { useCallback } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

// Create styles

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
  },
});

// Create Document Component
const MyDocument = ({ report }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Payments Report</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Transaction Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Method</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Status</Text>
            </View>
          </View>
          {/* Table Rows */}
          {report?.payments?.map((payment, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{payment.transaction_date}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{payment.amount}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {payment.transaction_method}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {payment.transaction_status}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const PDFReport = () => {
  // const alert = useAlert();
  const [report, setReport] = useState();
 

  const getReport = useCallback(async () => {
    try {
      // setSpinner(true);
      const accessToken = localStorage.getItem("access");
      const site = localStorage.getItem("site");
      if (!site) {
        throw new Error("Site not found");
      }
      if (accessToken) {
        const response = await fetch(`${BASE_URL}/task/generate-report/`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
          body: JSON.stringify({
            sites: site,
            site_updates: true,
            site_photos: false,
            payments: true,
            attendance: false,
            material_inventory: true,
            material_deliveries: true,
            start_date: "2024-01-01",
            // end_date: "2024-04-24",
            end_date: new Date().toISOString().split("T")[0],
          }),
        });

        const data = await response.json();
        console.log(data);
        if (data?.status) {
          setReport(data?.data);
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      alert.error(error.message);
      // console.log(error);
      // navigate("/login");
    } finally {
      //   setLoading(false);
      console.log("done");
      // setSpinner(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getReport();
    };

    fetchData();
  }, [getReport]);

  // Rest of the component code

  // const getReport = async () => {
  //   try {
  //     // setSpinner(true);
  //     const accessToken = localStorage.getItem("access");
  //     const site = localStorage.getItem("site");
  //     if (!site) {
  //       throw new Error("Site not found");
  //     }
  //     if (accessToken) {
  //       const response = await fetch(`${BASE_URL}/task/generate-report/`, {
  //         method: "POST",
  //         headers: new Headers({
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${accessToken}`,
  //         }),
  //         body: JSON.stringify({
  //           sites: site,
  //           site_updates: true,
  //           site_photos: false,
  //           payments: true,
  //           attendance: false,
  //           material_inventory: true,
  //           material_deliveries: true,
  //           start_date: "2024-01-01",
  //           // end_date: "2024-04-24",
  //           end_date: new Date().toISOString().split("T")[0],
  //         }),
  //       });

  //       const data = await response.json();
  //       console.log(data);
  //       if (data?.status) {
  //         setReport(data?.data);
  //       } else {
  //         throw new Error(data.message);
  //       }
  //     }
  //   } catch (error) {
  //     alert.error(error.message);
  //     // console.log(error);
  //     // navigate("/login");
  //   } finally {
  //     //   setLoading(false);
  //     console.log("done");
  //     // setSpinner(false);
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await getReport();
  //   };

  //   fetchData();
  // }, [getReport]); // Empty dependency array since getReport doesn't depend on any external variables

  //when back button in browser is clicked, the user is redirected to the login page
  // useEffect(() => {
  //   window.onpopstate = (e) => {
  //     window.location.href = "/sitehome";
  //   };
  // }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PDFViewer
        style={{
          width: "50%",
          height: "95vh",
        }}
      >
        <MyDocument report={report} />
      </PDFViewer>
    </div>
  );
};

export default PDFReport;
