import React, { useEffect, useState } from "react";
import CashFlowStats from "./CashFlowStats";
import MyProjects from "./MyProjects";
import Button from "./Button";
import { BASE_URL } from "utils/constant";
import Logo from "../assets/site.png";

const MainContent = ({ filteredSites, onOrganizationClick,organizationId }) => {
  const [isOwner, setIsOwner] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const accessToken = localStorage.getItem("access"); // replace with actual token

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/auth/user-details-of-user/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();

        if (data.status && data.data.profession === "owner") {
          setIsOwner(true);
        } else {
          fetchOrganizations();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchOrganizations = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/org/list-user-organization/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();

        if (data.status) {
          setOrganizations(data.data);
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div style={styles.container}>
      {isOwner ? (
        <>
          <CashFlowStats filteredSites={filteredSites} />
          <MyProjects filteredSites={filteredSites} organizationId={organizationId} />
        </>
      ) : (
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold mb-6">Your Organizations</h1>
          <div className="flex flex-row items-center justify-start gap-4">
            {organizations.map((org, index) => (
              <div
                key={index}
                style={styles.orgItem}
                className="truncate"
                onClick={() => {
                  onOrganizationClick(org);
                }}
              >
                <img
                  src={Logo}
                  alt="Organization Logo"
                  style={styles.orgLogo}
                  className={`${org.id === organizationId ? "border-3 border-[#3b0069]" : "border-2 border-[#9A9A9A]"}`}
                />
                <span style={styles.orgName} className="truncate">
                  {org.organization_name}
                </span>
              </div>
            ))}
          </div>
          <MyProjects filteredSites={filteredSites} organizationId={organizationId}/>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#F7F8FC",
    flex: 1,
  },
  orgItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  orgLogo: {
    width: "60px",
    height: "60px",
    borderRadius: "50%"
  },
  orgName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },
};

export default MainContent;
