import React, { useState } from "react";
import Sidebar from "./Sidebar";
import TaskHome from "./TaskHome";
import TaskDetails from "./TaskFormDetails";
import Home from "./Home";
import PDFReport from "./PDFReport";
import Finance from "./Finance";
import Topbar from "./Topbar";
import Inventory from "./Material";
import Worker from "./Worker";
import SiteDetails from "./SiteDetails";
import GenerateReportModal from "./GenerateReport";

export default function Sitehome() {
  const [selectedTab, setSelectedTab] = useState("Home");
  const setTab = (value) => {
    setSelectedTab(value);
  };
  const [task, setTask] = useState(null);

  const handleTaskClick = (task) => {
    setTask(task);
    setSelectedTab("TaskDetails");
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Topbar className="flex-none" />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar setTab={setTab} selected={selectedTab} className="flex-none" />
        <div className="flex-1 overflow-auto">
          {selectedTab === "Home" ? (
            <Home />
          ) : selectedTab === "Tasks" ? (
            <TaskHome handleTaskClick={handleTaskClick} />
          ) : selectedTab === "PDF" ? (
            <PDFReport />
          ) : selectedTab === "Finance" ? (
            <Finance />
          ) : selectedTab === "Materials" ? (
            <Inventory />
          ) : selectedTab === "Workers" ? (
            <Worker />
          ) : selectedTab === "Details" ? (
            <SiteDetails />
          ) : selectedTab === "Report" ? (
            <GenerateReportModal />
          ) : selectedTab === "TaskDetails" ? (
            <TaskDetails task={task} />
          ) : null}
        </div>
      </div>
    </div>
  );
}