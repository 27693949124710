import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Upload, Loader2 } from "lucide-react";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "sonner";
import { BASE_URL } from "utils/constant";

// Form validation schema
const organizationSchema = z.object({
  organization_name: z.string().min(2, "Organization name is required"),
  business_address: z.string().min(5, "Business address is required"),
  pincode: z.string().regex(/^\d{6}$/, "Enter a valid 6-digit pin code"),
  state: z.string().min(2, "State is required"),
  contact_number: z
    .string()
    .regex(/^[6-9]\d{9}$/, "Enter a valid Indian mobile number")
    .optional()
    .or(z.literal("")),
  website: z.string().url("Enter a valid URL").optional().or(z.literal("")),
  logo_image_file: z.instanceof(File).optional(),
});

// Custom Form Components
const FormLabel = ({ children, required }) => (
  <label className="block text-sm font-medium text-gray-700 mb-1">
    {children}
    {required && <span className="text-red-500 ml-1">*</span>}
  </label>
);

const FormInput = React.forwardRef(({ error, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 ${
      error ? "border-red-500 focus:ring-red-500" : "border-gray-300"
    } bg-white`}
  />
));

const FormError = ({ children }) =>
  children ? <p className="mt-1 text-sm text-red-500">{children}</p> : null;

const Button = ({ children, isLoading, ...props }) => (
  <button
    {...props}
    className={`flex items-center justify-center px-4 py-2 font-medium rounded-md text-white bg-[#3B0069] hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed ${
      props.className || ""
    }`}
  >
    {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
    {children}
  </button>
);

const OrganizationDetailsForm = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access");

  const form = useForm({
    resolver: zodResolver(organizationSchema),
    defaultValues: {
      organizationName: "",
      businessAddress: "",
      pinCode: "",
      state: "",
      businessContact: "",
      websiteLink: "",
      logo: undefined,
    },
  });

  const { data: userData } = useQuery({
    queryKey: ["user-details"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BASE_URL}/auth/user-details-of-user/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.trim()}`,
          },
        }
      );
      return data;
    },
  });

  const createOrganization = useMutation({
    mutationFn: async (formData) => {
      const form = new FormData();

      //Convert owner ID to number and append
      if (userData?.id) {
        form.append("owner", Number(userData.id));
      }

      // Append all form fields with correct API field names
      form.append("organization_name", formData.organization_name);
      form.append("business_address", formData.business_address);
      form.append("pincode", formData.pincode);
      form.append("state", formData.state);

      if (formData.contact_number) {
        form.append("contact_number", formData.contact_number);
      }

      if (formData.website) {
        form.append("website", formData.website);
      }

      if (formData.logo_image_file) {
        form.append("logo_image_file", formData.logo_image_file);
      }
      form.append("active_flag", true);

      const { data } = await axios.post(
        `${BASE_URL}/org/create-organization/`,
        form,
        {
          headers: {
            Authorization: `Bearer ${accessToken.trim()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      //Store organization id in LocalStorage
      console.log("Organization created successfully", data);
      localStorage.setItem("organization", JSON.stringify(data.data));
      toast.success("Organization created successfully");
      navigate("/");
    },
    onError: (error) => {
      toast.error(
        error.response?.data?.message || "Failed to create organization"
      );
    },
  });

  const onSubmit = (data) => {
    createOrganization.mutate(data);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      form.setValue("logo_image_file", file);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-gray-900">
            Organization Details
          </h2>
          <p className="mt-1 text-sm text-gray-600">
            Please provide your organization's information below
          </p>
        </div>

        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <FormLabel required>Organization Name</FormLabel>
                <FormInput
                  {...form.register("organization_name")}
                  placeholder="Enter organization name"
                  error={form.formState.errors.organization_name}
                />
                <FormError>
                  {form.formState.errors.organization_name?.message}
                </FormError>
              </div>

              <div>
                <FormLabel required>Business Address</FormLabel>
                <FormInput
                  {...form.register("business_address")}
                  placeholder="Enter business address"
                  error={form.formState.errors.business_address}
                />
                <FormError>
                  {form.formState.errors.business_address?.message}
                </FormError>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <FormLabel required>Pin Code</FormLabel>
                  <FormInput
                    {...form.register("pincode")}
                    placeholder="Enter pin code"
                    error={form.formState.errors.pincode}
                  />
                  <FormError>
                    {form.formState.errors.pincode?.message}
                  </FormError>
                </div>

                <div>
                  <FormLabel required>State</FormLabel>
                  <FormInput
                    {...form.register("state")}
                    placeholder="Enter state"
                    error={form.formState.errors.state}
                  />
                  <FormError>{form.formState.errors.state?.message}</FormError>
                </div>
              </div>

              <div>
                <FormLabel>Business Contact (Optional)</FormLabel>
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                    IND +91
                  </span>
                  <FormInput
                    {...form.register("contact_number")}
                    className="rounded-l-none"
                    placeholder="98700 00000"
                    error={form.formState.errors.contact_number}
                  />
                </div>
                <FormError>
                  {form.formState.errors.contact_number?.message}
                </FormError>
              </div>

              <div>
                <FormLabel>Website Link (Optional)</FormLabel>
                <FormInput
                  {...form.register("website")}
                  placeholder="https://example.com"
                  error={form.formState.errors.website}
                />
                <FormError>{form.formState.errors.website?.message}</FormError>
              </div>
            </div>

            <div className="space-y-4">
              <FormLabel>Organization Logo (Optional)</FormLabel>
              <div className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6 bg-gray-50 min-h-[300px]">
                {form.watch("logo_image_file") ? (
                  <div className="space-y-4 text-center">
                    <p className="text-sm text-gray-500">
                      {form.watch("logo_image_file").name}
                    </p>
                    <button
                      type="button"
                      onClick={() => form.setValue("logo_image_file", undefined)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    >
                      Change File
                    </button>
                  </div>
                ) : (
                  <label className="flex flex-col items-center justify-center cursor-pointer">
                    <Upload className="h-10 w-10 text-gray-400" />
                    <span className="mt-2 text-sm text-gray-500">
                      Click to upload
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                  </label>
                )}
              </div>
            </div>
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={createOrganization.isPending}
            isLoading={createOrganization.isPending}
          >
            Save Organization
          </Button>
        </form>
      </div>
    </div>
  );
};

export default OrganizationDetailsForm;
