import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import base_background from "../assets/Base (1).png";
import main_logo from "../assets/SM_Full_White-100 1 (1).png";
import arrow_forward from "../assets/arrow_forward.png";
import arrow from "../assets/Vector (1).png";
import passwordIcon from "../assets/visibility_lock (1).png";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import edit from "../assets/edit.png";
import { useAlert } from "react-alert";
import { BASE_URL } from "../utils/constant";
export default function OtpScreen() {
  const [counter, setCounter] = useState(60);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const alert = useAlert();
  const navigate = useNavigate();
  const user_details = useSelector((state) => state.auth);

  const [passwordVisible, setPasswordVisible] = useState(false);

  async function create_token(email, password) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${BASE_URL}/token/create/`, requestOptions);
      const result = await response.json();
      if (result?.access) {
        localStorage.setItem("access", result.access);
        localStorage.setItem("refresh", result.refresh);
        navigate("/profession");
      } else {
        alert.error(result?.message || "Failed to create token");
      }
    } catch (error) {
      console.error("error", error);
      alert.error(error?.message || "Failed to connect to server");
    }
  }
  async function verifyOtp(email, Otp) {
    // setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email.toLowerCase(),
      otp: Otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${BASE_URL}/auth/verify/`, requestOptions);
      const res = await response.json();

      console.log(res);


      if (res?.status) {
        create_token(email.toLowerCase(),user_details?.password);
        // localStorage.setItem("access", res.data?.access);
        // localStorage.setItem("refresh", res.data?.refresh);
        // navigate("/profession");
      } else {
        // Alert.alert("Error", result.message, [
        //   {
        //     text: "Cancel",
        //     onPress: () => console.log("Cancel Pressed"),
        //     style: "cancel",
        //   },
        //   { text: "OK", onPress: () => console.log("OK Pressed") },
        // ]);
        alert.error("Error", res.message);
      }
    } catch (error) {
      console.error("error", error);
      //   Toast.error(error?.message);
      alert.error(error.message);
    } finally {
      //   setLoading(false);
      console.log("Otp verified");
    }
  }
  async function resendOtp(email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${BASE_URL}/auth/resendotp/`,
        requestOptions
      );
      const result = await response.text();
      if (result?.status) alert.success(result?.message);
    } catch (error) {
      //   Toast.error(error?.message);
      alert.error(error?.message);
    }
  }
  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email Address is Required"),
    otp: yup
      .array()
      .of(
        yup
          .string()
          .length(1, "Invalid OTP")
          .test("is-digit", "Enter valid digit", (value) => {
            return /^\d$/.test(value);
          })
      )
      .test("is-complete", "Enter valid digit", (value) => {
        return value.every((digit) => digit !== "");
      }),
  });

  const handleSignup = (email, otp) => {
    console.log("Signin");
    console.log(email);
    console.log(otp);
    // navigate("/profession");
  };
  const formik = useFormik({
    initialValues: {
      email: user_details.email,
      otp: ["", "", "", ""],
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      //   handleSignup(values.email, values.otp);
      let OTP = "";
      values.otp.forEach((digit) => {
        OTP += digit;
      });
      verifyOtp(values.email, OTP);
    },
  });

  useEffect(() => {
    console.log(user_details);
  });
  const [editEmail, setEditEmail] = useState(false);
  return (
    <div
      className=""
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={base_background}
          alt=""
          style={{
            height: "140%",
            width: "140%",
            objectFit: "cover",
            position: "relative",
            top: "-50px",
            left: "-50px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: -3,
          height: "100%",
          width: "50%",
          top: "0px",
          left: "0px",
        }}
      >
        <img
          src={arrow}
          alt=""
          style={{
            height: "115%",
            width: "115%",
            top: "0px",
            left: "-70px",
            objectFit: "contain",
            position: "relative",
            opacity: "0.5",
          }}
        />
      </div>
      <div className="flex flex-col align-middle items-center justify-center w-[50%]">
        <span className="py-5 font-extrabold text-neutral-900 text-2xl">
          <img src={main_logo} alt="" width="90%" />
        </span>
        <span
          className="font-extrabold text-neutral-900 font-medium mt-[-2]"
          style={{
            fontSize: "28px",
            color: "#ffffff",
            fontWeight: 400,
            opacity: 0.8,
            letterSpacing: "0.1em",
            textAlign: "center",
          }}
        >
          Build Smarter,Faster,Better
        </span>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          position: "relative",
          top: "70px",
          /* margin-top: auto; */
          right: "50px",
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col align-middle justify-between px-10  pb-5"
          style={{ width: "80%" }}
        >
          <div className="flex flex-col">
            <span
              className="py-5 font-extrabold text-purple-800 text-2xl"
              style={{
                color: "#3B0069",
                fontSize: "32px",
                fontWeight: 700,
              }}
            >
              Create an Account
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "black",
                fontWeight: 200,
                opacity: 0.8,
                marginTop: "-12px",
              }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-purple-800"
                style={{ textDecoration: "underline" }}
              >
                Sign in
              </Link>
            </span>
            <span
              className="py-2 "
              style={{
                fontSize: "24px",
                color: "#4B5563",
                fontWeight: 700,
                marginTop: "10px",
              }}
            >
              Enter OTP
            </span>

            <span className="pt-3 text-neutral-800 font-medium mb-3">
              Enter the 4 Digit code sent to your email
            </span>
            <div
              style={{
                position: "relative",
                width: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                type="email"
                name="email"
                className="bg-gray-200 rounded px-4 py-6"
                placeholder="Enter email here"
                disabled={!editEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                style={{
                  width: "100%",
                  height: "56px",
                  paddingBottom: "0px",
                  fontWeight: "500",
                  color: "#3B0069",
                  fontSize: "16px",
                }}
              />

              <span
                className="text-neutral-700 font-medium"
                style={{
                  position: "absolute",
                  top: "6px",
                  left: "16px",
                  fontSize: "12px",
                }}
              >
                Verification code sent to{" "}
              </span>
              <span
                onClick={() => {
                  setEditEmail(true);
                  console.log(formik.errors.otp);
                }}
                style={{
                  position: "absolute",
                  right: "16px",
                  top: "13px",
                  width: "32px",
                  background: "white",
                  borderRadius: "50%",
                  height: "32px",
                  display: "flex",
                  padding: "5px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "space-around",
                  alignItems: "center",
                }}
              >
                <img
                  src={edit}
                  alt="edit"
                  style={{
                    cursor: "pointer",
                  }}
                  className="w-5 h-5"
                />
              </span>
              {formik.touched.email && formik.errors.email && (
                <span
                  style={{
                    fontSize: 10,
                    color: "red",
                    position: "absolute",
                    bottom: "-13px",
                    left: "2px",
                  }}
                >
                  {formik.errors.email}
                </span>
              )}
            </div>
            <div
              className="flex flex-row"
              style={{
                marginTop: "32px",
                columnGap: "13px",
                marginLeft: "-6px",
              }}
            >
              {formik.values.otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  name="otp"
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) =>
                    formik.setFieldValue(`otp[${index}]`, e.target.value)
                  }
                  className="border mx-1"
                  style={{
                    width: "17%",
                    height: "48px",
                    fontWeight: "600",
                    color: "#232323",
                    fontSize: "26px",
                    textAlign: "center",
                    borderRadius: "8px",
                    backgroundColor: "#F3F4F6",
                    //   on focus give a border style to the input
                    border: "1px solid #E5E7EB",
                  }}
                />
              ))}
            </div>
            {formik.touched.otp && formik.errors.otp && (
              <span
                style={{
                  fontSize: 10,
                  color: "red",
                  bottom: "-13px",
                  left: "2px",
                }}
              >
                {/* show only one otp error */}
                {formik.errors.otp[0]
                  ? formik.errors.otp[0]
                  : formik.errors.otp[1]
                  ? formik.errors.otp[1]
                  : formik.errors.otp[2]
                  ? formik.errors.otp[2]
                  : formik.errors.otp[3]
                  ? formik.errors.otp[3]
                  : ""}
              </span>
            )}
          </div>

          <span
            className="pt-3 text-neutral-800 font-medium"
            style={{ fontSize: "12px" }}
          >
            Didn't receive OTP?{" "}
          </span>
          <span
            className="text-purple-950 font-medium"
            style={{ fontSize: "14px" }}
          >
            <span
              style={{ textDecoration: "underline" }}
              onClick={() => {
                if (counter <= 0) {
                  resendOtp(formik.values.email);
                  setCounter(60);
                }
              }}
            >
              Resend OTP
            </span>{" "}
            in {counter} seconds
          </span>

          <div className="w-full mt-14" style={{ width: "80%" }}>
            <button
              type="submit"
              className={`py-3 px-8 rounded-lg w-full justify-center items-center flex flex-row gap-4 text-center ${
                formik.isValid ? "bg-purple-950" : "bg-gray-400"
              }`}
              disabled={!formik.isValid}
            >
              <span className=" text-white text-lg">Verify Email ID</span>

              {/* Replace the icon component here */}

              <img
                src={arrow_forward}
                alt=""
                className="w-6 h-6"
                style={{ background: "#58267F", borderRadius: "50%" }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
