import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CashFlowGraph = () => {
  const data = [
    { month: "Jan", thisYear: 8000, lastYear: 2000 },
    { month: "Feb", thisYear: 4000, lastYear: 9000 },
    { month: "Mar", thisYear: 10000, lastYear: 5000 },
    { month: "Apr", thisYear: 20000, lastYear: 3000 },
    { month: "May", thisYear: 24000, lastYear: 10000 },
    { month: "Jun", thisYear: 18000, lastYear: 15000 },
    { month: "Jul", thisYear: 20000, lastYear: 25000 },
  ];

  return (
    <div className="w-full max-w-4xl mb-6 ">
      <h2 className="text-2xl font-semibold text-gray-700 mb-4">
        Total Cash flow Stats
      </h2>
      <div className="h-[290px] p-3 bg-white rounded-lg shadow-sm mb-2">
        {" "}
        {/* Reduced from 400px to 280px */}
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#666" }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#666" }}
              tickFormatter={(value) => `${value / 1000}k`}
            />
            <Tooltip
              formatter={(value) => [`$${value}`, ""]}
              contentStyle={{
                backgroundColor: "white",
                border: "none",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            />
            <Legend
              align="left"
              verticalAlign="top"
              iconType="circle"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            <Line
              type="monotone"
              dataKey="thisYear"
              name="This year"
              stroke="#FF6384"
              strokeWidth={2}
              dot={{ r: 4, fill: "#FF6384" }}
              activeDot={{ r: 6 }}
            />
            <Line
              type="monotone"
              dataKey="lastYear"
              name="Last year"
              stroke="#36A2EB"
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={{ r: 4, fill: "#36A2EB" }}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CashFlowGraph;
