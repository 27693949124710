import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { isLoggedIn: false, email: "", profession:"",password:"" },
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
      state.email = action.payload.email;
      state.profession = action.payload.profession;
      state.password = action.payload.password;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.email = "";
      state.password = "";
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
