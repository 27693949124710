import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { Formik } from "formik";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import OtpScreen from "./components/OtpScreen";
import Profession from "./components/Profession";
import UserDetails from "./components/UserDetails";
import UserAddress from "./components/UserAddress";
import Sitehome from "./components/SiteHome";
import WelcomePage from "./components/Welcome";
import AddYourOrganization from "./components/AddOrganization";
import OrganizationDetailsForm from "./components/OrganizationDetails";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import OrganizationDetails from "./components/OrganizationOwner";
import SubTaskDetails from "./components/SubTaskDetails";
import TaskDetails from "./components/TaskFormDetails";

const queryClient = new QueryClient();

// Protected Route wrapper
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("access");
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route exact path="/subtask" element={<TaskDetails />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/otp-screen" element={<OtpScreen />} />
          <Route exact path="/" element={<Navigate to="/login" replace />} />
          <Route 
            exact 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route exact path="/profession" element={<Profession />} />
          <Route path="/userdetails" element={<UserDetails />} />
          <Route path="/useraddress" element={<UserAddress />} />
          <Route path="/sitehome" element={<Sitehome />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/add-organization" element={<AddYourOrganization />} />
          <Route
            path="/organization-details"
            element={<OrganizationDetailsForm />}
          />
          <Route
            path="/organization-detail"
            element={<OrganizationDetails />}
          />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
