import React from "react";
import { AlertCircle, CheckCircle2, Truck, XCircle } from "lucide-react";
import moment from "moment";

const DeliveryStatus = ({ type, message }) => {
  const statusStyles = {
    arriving: "bg-green-100 text-green-700",
    delayed: "bg-red-100 text-red-600",
    received: "bg-green-100 text-green-700",
    cancelled: "bg-red-100 text-red-600",
    fault: "bg-red-100 text-red-600",
  };

  const StatusIcon = {
    arriving: Truck,
    delayed: Truck,
    received: CheckCircle2,
    cancelled: XCircle,
    fault: AlertCircle,
  };

  const Icon = StatusIcon[type];

  return (
    <div
      className={`flex items-center p-2 px-4 ${statusStyles[type]} rounded-t-lg`}
    >
      <Icon className="w-5 h-5" />
      <span className="ml-2 font-bold text-sm">{message}</span>
    </div>
  );
};

const PaymentStatus = ({ status }) => {
  const statusMap = {
    "pre-paid": "Pre-paid",
    paid: "Paid",
    refunded: "Refunded",
  };

  return (
    <div className="px-2 py-1 bg-green-100 rounded-full">
      <span className="text-xs text-green-700 font-bold">
        {statusMap[status]}
      </span>
    </div>
  );
};

const MaterialRow = ({ material, index, transactionId, lastRow }) => {
  const getStatusBadge = () => {
    if (transactionId === 1) return null;

    const hasFault =
      material.quantity_check === "fault" ||
      material.quality_check === "fault" ||
      material.specification_check === "fault";

    if (hasFault) {
      return (
        <div className="px-2 py-1 bg-red-100 rounded-full">
          <span className="text-xs text-red-600 font-bold">Fault</span>
        </div>
      );
    }

    return (
      <div className="px-2 py-1 bg-green-100 rounded-full">
        <span className="text-xs text-green-700 font-bold">Matched</span>
      </div>
    );
  };

  return (
    <div className="px-4">
      <div
        className={`flex items-center justify-between p-1 ${
          !lastRow ? "border-b border-gray-300" : ""
        }`}
      >
        <div className="flex items-center flex-row justify-between w-full">
          <div className="flex items-center flex-row gap-2 justify-start">
            <div className="mr-4">
              <span className="text-xs text-gray-500">No.</span>
              <p className="font-bold text-base">
                {index < 9 ? `0${index + 1}` : index + 1}
              </p>
            </div>
            <div>
              <span className="text-xs text-gray-500">Material</span>
              <div className="flex items-center">
                <span className="font-bold text-base">
                  {material.material_name}
                </span>
                <span className="ml-1 text-sm text-gray-500">
                  {material.material_subcategory}
                </span>
              </div>
            </div>
          </div>
          <div>
            <span className="text-xs text-gray-500">Quantity</span>
            <p className="font-bold text-base">
              {material.quantity} {material.unit}
            </p>
          </div>
          <div>
            <span className="text-xs text-gray-500">Price/unit</span>
            <p className="font-bold text-base">₹ {material.unit_price}</p>
          </div>
          <div>
            <span className="text-xs text-gray-500">Total price</span>
            <p className="font-bold text-base">₹ {material.total_price}</p>
          </div>
        </div>
        {/* {getStatusBadge()} */}
      </div>
    </div>
  );
};

const DeliveryCard = ({ delivery, onClick }) => {
  const isToday = moment(delivery?.date)
    .startOf("day")
    .isSame(moment().startOf("day"));
  const isDelayed = moment(delivery?.date).isBefore(moment().startOf("day"));
  const delay = Math.abs(
    moment(delivery?.date).diff(moment().startOf("day"), "days")
  );

  const isUpcoming = delivery?.transaction_id === 1;
  const isDelivered = delivery?.transaction_id === 2;
  const isCancelled = delivery?.transaction_id === 3;

  const hasFaults = delivery?.all_materials?.some(
    (material) =>
      material.specification_check === "fault" ||
      material.quality_check === "fault" ||
      material.quantity_check === "fault"
  );

  const getDeliveryStatus = () => {
    if (isDelivered && !hasFaults) {
      return <DeliveryStatus type="received" message="Received and checked" />;
    }
    if (isCancelled && !hasFaults) {
      return <DeliveryStatus type="cancelled" message="Cancelled" />;
    }
    if (isDelayed && isUpcoming) {
      return (
        <DeliveryStatus type="delayed" message={`${delay} days delayed`} />
      );
    }
    if (isToday && isUpcoming) {
      return (
        <DeliveryStatus type="arriving" message="Materials arriving Today" />
      );
    }
    if (isUpcoming) {
      return (
        <DeliveryStatus type="arriving" message="Materials arriving soon" />
      );
    }
    if (hasFaults) {
      return <DeliveryStatus type="fault" message="Fault in delivery" />;
    }
    return null;
  };

  return (
    <div
      className="mb-6 bg-white rounded-lg  cursor-pointer"
      onClick={() => onClick(delivery)}
    >
      {getDeliveryStatus()}

      <div className="flex items-center justify-between p-2 m-2 border-b border-gray-300">
        <div className="flex items-center gap-2 flex-row justify-start">
          <div>
            <span className="text-xs text-gray-500">Delivery code</span>
            <p className="font-bold text-base">{`${delivery?.date}-${delivery?.id}`}</p>
          </div>
          <PaymentStatus
            status={
              isUpcoming ? "pre-paid" : !isCancelled ? "paid" : "refunded"
            }
          />
        </div>
        <div>
          <span className="text-xs text-gray-500">
            {!isUpcoming ? "Delivered on" : "Expected on"}
          </span>
          <p className="font-bold text-base">
            {moment(delivery?.date).format("DD MMM Y")}
          </p>
        </div>
      </div>

      {delivery?.all_materials?.map((material, index) => (
        <MaterialRow
          key={index}
          material={material}
          index={index}
          transactionId={delivery?.transaction_id}
          lastRow={index === delivery?.all_materials.length - 1}
        />
      ))}
    </div>
  );
};

export default DeliveryCard;
