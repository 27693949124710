import React, { useEffect, useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { GrDocumentVerified } from "react-icons/gr";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { MdDescription } from "react-icons/md";
import { MdLocalShipping } from "react-icons/md";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../utils/constant";

function Sidebar({ setTab, selected }) {
  const alert = useAlert();
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const getUserDetails = async () => {
    try {
      const accessToken = localStorage.getItem("access");

      if (accessToken) {
        const response = await fetch(`${BASE_URL}/auth/user-details-of-user/`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          }),
        });

        const data = await response.json();
        if (data.status) {
          setUser(data.data);
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      // alert.error(error.message);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="w-[14%] flex-none h-full bg-white border-r border-gray-200">
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto">
          <div className="sidebar-section top-section">
            <div
              className={`tab ${selected === "Home" ? "selected" : ""}`}
              onClick={() => setTab("Home")}
            >
              <GoHomeFill className="tab-icon" />
              <span className="title">Dashboard</span>
            </div>
            <div
              className={`tab ${selected === "Tasks" ? "selected" : ""}`}
              onClick={() => setTab("Tasks")}
            >
              <GrDocumentVerified className="tab-icon" />
              <span className="title">Tasks</span>
            </div>
            <div
              className={`tab ${selected === "Finance" ? "selected" : ""}`}
              onClick={() => setTab("Finance")}
            >
              <MdOutlineAccountBalanceWallet className="tab-icon" />
              <span className="title">Finance</span>
            </div>
            <div
              className={`tab ${selected === "Materials" ? "selected" : ""}`}
              onClick={() => setTab("Materials")}
            >
              <MdLocalShipping className="tab-icon" />
              <span className="title">Materials</span>
            </div>
            <div
              className={`tab ${selected === "Report" ? "selected" : ""}`}
              onClick={() => setTab("Report")}
            >
              <MdDescription className="tab-icon" />
              <span className="title">Daily Report</span>
            </div>
            <div
              className={`tab ${selected === "Details" ? "selected" : ""}`}
              onClick={() => setTab("Details")}
            >
              <MdLocalShipping className="tab-icon" />
              <span className="title">Project Details</span>
            </div>
          </div>
        </div>
      </div>
      
      <style jsx>{`
        .sidebar-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 0;
        }
        
        .top-section {
          flex: 1;
        }
        
        .tab {
          width: 100%;
          padding: 12px 5px 5px 7px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          font-size: 16px;
          font-weight: 500;
          color: #4f4f4f;
          transition: background 0.2s ease-in-out;
          margin-left: 20px;
        }
        
        .tab-icon {
          width: 20px;
          height: 28px;
          margin-bottom: 8px;
          margin-left: 10px;
        }
        
        .selected {
          background: #f0f0f0;
          color: #3b0069;
          width: 215px;
          margin-right: 25px;
        }
        
        .title {
          margin-top: 4px;
          margin-left: 4px;
          font-size: 14px;
        }

        @media (max-width: 1440px) {
          .tab {
            font-size: 14px;
          }
          .tab-icon {
            width: 24px;
            height: 24px;
          }
        }
        
        @media (max-width: 1024px) {
          .tab {
            font-size: 12px;
          }
          .tab-icon {
            width: 20px;
            height: 20px;
          }
        }
        
        @media (max-width: 768px) {
          .tab {
            font-size: 11px;
          }
          .tab-icon {
            width: 18px;
            height: 18px;
          }
        }
        
        @media (max-width: 480px) {
          .tab {
            font-size: 10px;
          }
          .tab-icon {
            width: 16px;
            height: 16px;
          }
        }
      `}</style>
    </div>
  );
}

export default Sidebar;