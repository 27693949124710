import React, { useState } from "react";
import { ChevronLeft, Edit, MoreVertical, Plus } from "lucide-react";
import ContractorsPayment from "./WorkerPayment";
import YourAddressForm from "./WorkerAddressForm";

const WorkerAddressDashboard = ({ contractor, onBack }) => {
  const [selectedTab, setSelectedTab] = useState("Attendance");
  const [currentMonth, setCurrentMonth] = useState("Month, 2023");
  const [showContractorsPayment, setShowContractorsPayment] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);

  const attendanceData = [
    { date: "Monday, 07 Jan", status: "Present", count: 12 },
    { date: "Monday, 07 Jan", status: "Absent", count: 0 },
    { date: "Monday, 07 Jan", status: "Unmarked", count: 0 },
    { date: "Monday, 07 Jan", status: "Present", count: 12 },
    { date: "Monday, 07 Jan", status: "Present", count: 12 },
    { date: "Monday, 07 Jan", status: "Absent", count: 0 },
  ];

  const paymentData = [
    { date: "30 Jun, 02:30 PM", type: "Schedule", amount: 2500, mode: "Cash" },
    { date: "30 Jun, 02:30 PM", type: "Advance", amount: 2500, mode: "Cash" },
    { date: "30 Jun, 02:30 PM", type: "Schedule", amount: 2500, mode: "Cash" },
  ];

  const handlePaymentsClick = () => {
    setSelectedTab("Payments");
    setShowContractorsPayment(true);
  };
  const handleCloseContractorsPayment = () => {
    setShowContractorsPayment(false);
  };
  const handleEditClick = () => {
    setShowAddressForm(true);
  };
  const handleCloseAddressForm = () => {
    setShowAddressForm(false);
  };

  if (showContractorsPayment) {
    return <ContractorsPayment onClose={handleCloseContractorsPayment} />;
  }
  if (showAddressForm) {
    return <YourAddressForm onClose={handleCloseAddressForm} />;
  }

  return (
    <div className="bg-gray-100 p-4 max-w-3xl mx-auto">
      <div className="flex items-center mb-4">
        <ChevronLeft className="mr-2 cursor-pointer" onClick={onBack} />
        <h1 className="text-xl font-semibold">Contractor Profile</h1>
        <span className="ml-auto text-sm text-gray-500">
          Site Address with pin
        </span>
        <Edit className="ml-2 text-gray-500" />
        <MoreVertical className="ml-2 text-gray-500" />
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
        <div className="flex items-center mb-4">
          <div className="w-12 h-12 bg-gray-300 rounded-full mr-4"></div>
          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold">{contractor.name}</h2>
              <button
                onClick={handleEditClick}
                className="text-blue-500 hover:text-blue-700"
              >
                <Edit size={16} />
              </button>
            </div>
            <p className="text-sm text-gray-500">{contractor.field}</p>
          </div>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-1">Contact Details</h3>
          <p className="text-sm">Contact No.: +91-9876543210</p>
          <p className="text-sm">Address: Raipur, Burdwan</p>
        </div>

        <div className="flex justify-between items-center">
          <h3 className="font-semibold">Payment Details</h3>
          <p className="font-semibold">₹1,00,000</p>
        </div>
      </div>

      <div className="flex mb-4">
        <button
          className={`flex-1 py-2 ${
            selectedTab === "Attendance"
              ? "border-b-2 border-blue-500 font-semibold"
              : ""
          }`}
          onClick={() => setSelectedTab("Attendance")}
        >
          Attendance
        </button>
        <button
          className={`flex-1 py-2 ${
            selectedTab === "Payments"
              ? "border-b-2 border-blue-500 font-semibold"
              : ""
          }`}
          onClick={() => setSelectedTab("Payments")}
        >
          Payments
        </button>
      </div>

      {selectedTab === "Attendance" && (
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="font-semibold mb-2">Info</h3>
          <div className="flex justify-between mb-4">
            <p className="text-sm">Started working from</p>
            <p className="text-sm font-semibold">02 January, 2023</p>
          </div>
          <div className="flex justify-between items-center mb-4">
            <p className="text-sm">
              Attendance Today
              <br />
              <span className="text-xs text-gray-500">
                Updated at 12:45, 28 Jun
              </span>
            </p>
            <span className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm">
              12 Present
            </span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <ChevronLeft className="text-gray-500" />
            <p className="font-semibold">{currentMonth}</p>
            <ChevronLeft className="text-gray-500 transform rotate-180" />
          </div>
          {attendanceData.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center py-2 border-t"
            >
              <div>
                <p className="text-sm font-semibold">{item.date}</p>
                <p className="text-xs text-gray-500">
                  Updated at 12:45 pm, by you
                </p>
              </div>
              <span
                className={`px-3 py-1 rounded-full text-sm ${
                  item.status === "Present"
                    ? "bg-green-100 text-green-800"
                    : item.status === "Absent"
                    ? "bg-red-100 text-red-800"
                    : "bg-gray-100 text-gray-800"
                }`}
              >
                {item.status === "Present"
                  ? `${item.count} ${item.status}`
                  : item.status}
              </span>
            </div>
          ))}
        </div>
      )}

      {selectedTab === "Payments" && (
        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold">Info</h3>
            <MoreVertical className="text-gray-500" />
          </div>

          <div className="space-y-2 mb-4">
            <div className="flex justify-between">
              <span className="text-sm">Payment till now</span>
              <span className="font-semibold">₹60,000</span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm">Remaining Payment</span>
              <span className="font-semibold">₹40,000</span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm text-gray-500">Last Payment</span>
              <span className="text-sm text-gray-500">
                04:30 PM, 30 Jun 2023
              </span>
            </div>
            <div className="flex justify-between">
              <span></span>
              <span className="font-semibold">₹10,000</span>
            </div>
          </div>

          <button
            onClick={handlePaymentsClick}
            className="w-full bg-indigo-600 text-white py-2 rounded-md flex items-center justify-center mb-4"
          >
            <Plus size={16} className="mr-2" />
            Payment
          </button>

          <div className="flex justify-between items-center mb-4">
            <ChevronLeft className="text-gray-500" />
            <p className="font-semibold">{currentMonth}</p>
            <ChevronLeft className="text-gray-500 transform rotate-180" />
          </div>

          {paymentData.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center py-2 border-t"
            >
              <div>
                <p className="text-sm font-semibold">
                  {item.date} {item.type}
                </p>
                <p className="text-xs text-gray-500">Comment: none</p>
              </div>
              <div className="text-right">
                <p className="text-sm font-semibold">₹{item.amount}</p>
                <p className="text-xs text-indigo-600">{item.mode}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkerAddressDashboard;
